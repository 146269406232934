import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, DialogActions, } from '@material-ui/core'
import logo from "../assets/logo.png"
import React,{useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { Avatar, IconButton, Divider, Button,Grid, Dialog, DialogTitle, Tooltip} from '@material-ui/core'
import { db, auth } from '../firebase'
import { GrayButton, PrimaryButton } from '../components/UIkit';
import noImage from '../assets/noImage.png'
import { Helmet } from 'react-helmet-async'
import { ClosableDrawer, MypageDetailHeader } from '../components/Header'
import HTMLReactParser from 'html-react-parser'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TwitterIcon from '@material-ui/icons/Twitter';
const returnCodeToBr = (text) => {
    if (text === ""){
      return text
    }else{
      return HTMLReactParser(text.replace(/\r?\n/g, '<br/>'))
    }
}
const UserPageDetail = () => {

    const dispatch = useDispatch()

    const [uid, setUid] = useState('')
    const [signedIn, setSignedIn] = useState(false)
    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
                const unsubscribe = db.collection('users').doc(user.uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                    const list = []
                    snapshots.forEach(snapshot => {
                        list.push(snapshot.data())
                    })
                    setNotifis(list)
                })
                return () => unsubscribe() 
            }
        })
    },[])

    const id = window.location.pathname.split('/detail/')[1]
    const BASE_URL = "https://pallet.fun/detail/" + id

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [img, setImg] = useState([])
    const [width, setWidth] = useState("")
    const [height, setHeight] = useState('')
    const [createrId, setCreaterId] = useState('')
    const [creatername, setCreatername] = useState('')
    const [createrIcon, setCreaterIcon] = useState([])

    const [requestId, setRequestId] = useState('')
    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            if(data){
                setTitle(data.title)
                setDesc(data.desc)
                setWidth(data.imgWidth)
                setHeight(data.imgHeight)
                setRequestId(data.requestId)
                setCreaterId(data.createrId)                                
                setImg(data.img[0].id)                
                db.collection('users').doc(data.createrId).get().then(snapshot => {
                    const userdata = snapshot.data()
                    setCreatername(userdata.username)
                    if(userdata.icons === ""){
                        setCreaterIcon(noImage)
                    }
                    if(userdata.icons[0].path){
                        setCreaterIcon(userdata.icons[0].path)
                    }
                })
            }
        })
    },[id])

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')    
    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                if(data){
                    setCategory(data.category)                    
                    if(data.icons === ""){
                        setIcon(noImage)
                    }
                    if(data.icons[0].path){
                        setIcon(data.icons[0].path)
                    }
                }
                
            })
        }
    },[uid])


    const [copyok, setCopyOk] = useState(false)

    const copyTextClipboard = () => {
        navigator.clipboard.writeText(BASE_URL).then(() => {
            setCopyOk(true)
        }).catch(() => {
            alert("クリップボードへのコピーが失敗しました。通信環境を確認の上再度お試しください。")
        })
    }
    const copyClose = () => {
        setCopyOk(false)
    }
    return(
        <>
        <Helmet
          title={uid === createrId ? (
              "あなたの作品/Pallet"
          ):(
              `${creatername}さんの作品/Pallet`
          )}
        />
        <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                    {signedIn === true ? (
                    <>
                        <ClosableDrawer uid={uid} category={category} icons={icon} notifis={notifis} />                    
                    </>
                    ):(
                        <>
                            <img src={logo} alt="logo" width="128px"/>
                            <div className='header_iconButton'>
                                <MypageDetailHeader id={id} key={id}/>
                            </div>
                        </>
                    )}
                    
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
        <div className="module-spacer--medium"/>
        <div className='center' style={{maxWidth:"900px"}}>                         
            <Grid container style={{margin:"auto"}}>
                <Grid item xs={12} sm={6} style={{maxWidth:"400px", margin:"0 auto auto auto "}}>
                    <div className="detail_box" >
                        {width < height && (
                            <>                                
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画面" className="detail_height" onContextMenu={(e) => e.preventDefault()}/>
                                {/* <p className='detail_watermark1'>sample</p>
                                <p className='detail_watermark2'>sample</p>
                                <p className='detail_watermark3'>sample</p>
                                <p className='detail_watermark4'>sample</p>
                                <p className='detail_watermark5'>sample</p>
                                <p className='detail_watermark6'>sample</p>
                                <p className='detail_watermark7'>sample</p> */}
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>
                        )}
                        {width > height && (
                            <>
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="detail_width" onContextMenu={(e) => e.preventDefault()}/>
                                {/* <p className='detail_watermark1'>sample</p>
                                <p className='detail_watermark2'>sample</p>
                                <p className='detail_watermark3'>sample</p>
                                <p className='detail_watermark4'>sample</p>
                                <p className='detail_watermark5'>sample</p>
                                <p className='detail_watermark6'>sample</p>
                                <p className='detail_watermark7'>sample</p> */}
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>
                        )}
                        {width === height && (
                            <>                                
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="detail_square" onContextMenu={(e) => e.preventDefault()}/>
                                {/* <p className='detail_watermark1'>sample</p>
                                <p className='detail_watermark2'>sample</p>
                                <p className='detail_watermark3'>sample</p>
                                <p className='detail_watermark4'>sample</p>
                                <p className='detail_watermark5'>sample</p>
                                <p className='detail_watermark6'>sample</p>
                                <p className='detail_watermark7'>sample</p> */}
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>
                        )}
                    </div>
                </Grid>
                <Grid  item xs={12} md={6} style={{maxWidth:"400px", margin:"0 auto auto auto"}} className="detail_desc">
                    <p style={{textAlign:"left", fontWeight:"bold"}} className="art_detail_title">{title}</p>
                    <div style={{display:"flex", margin:"0 auto 0 0 ", textAlign:"left"}}>
                        <IconButton style={{width:"60px", height:"60px", margin:"auto 0"}} onClick={() => dispatch(push("/mypage/" + createrId))}>
                            <Avatar src={createrIcon} style={{width:"50px", height:"50px"}}/>
                        </IconButton>
                        <p style={{ marginTop:"20px", marginLeft:"10px", cursor:"pointer"}} onClick={() => dispatch(push('/mypage/' + createrId))}><span style={{fontWeight:"bold"}}>{creatername}</span>さんの作品</p>             
                    </div>
                    <p style={{textAlign:"left", marginLeft:"20px"}}>{returnCodeToBr(desc)}</p>
                    <div style={{display:"flex", maxWidth:100, margin:"10px 0 auto auto"}}>
                            <Tooltip title="Twitterへシェア">
                                <a href={`http://twitter.com/share?url=${BASE_URL}&text=『${title}』｜${creatername}さんの作品&hashtags=Pallet`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                    <IconButton>
                                        <TwitterIcon style={{color:"#1DA1F2"}}/>
                                    </IconButton>
                                </a>
                            </Tooltip>
                            <Tooltip title="リンクをコピー">
                                <IconButton onClick={() => copyTextClipboard()}>
                                    <FileCopyIcon/>                            
                                </IconButton>
                            </Tooltip>
                    </div>
                    <div className='module-spacer--small'/>
                    <PrimaryButton
                        label="投稿したお題のページへ"
                        onClick={() => dispatch(push('/art_request/all/' + requestId))}
                    />
                </Grid>
            </Grid>
            <Dialog open={copyok} onClose={copyClose}>
                <DialogTitle>クリップボードにリンクをコピーしました</DialogTitle>
                <DialogActions>
                    <Button style={{backgroundColor:"#808080", color:"#fff", borderRadius:"12px"}} onClick={copyClose}>閉じる</Button>
                </DialogActions>
            </Dialog>
            <div className="module-spacer--medium"/>
            <GrayButton
              label="投稿者のページへ"
              onClick={() => dispatch(push('/mypage/' + createrId))}
            />
        </div>
        <div className="module-spacer--medium"/>
    </>
    )
}

export default UserPageDetail
