import { db, FirebaseTimestamp } from "../../firebase";
import { push } from "connected-react-router";
import reactImageSize from "react-image-size";

const headers = {'Content-Type': 'application/json'}

const BASE_URL = "https://pallet.fun"
export const saveRequest = (title, img,accountId, name) => {
    return async (dispatch, getState) => {
        const timestamp = FirebaseTimestamp.now()
        const ref = db.collection('requests').doc()
        const id = ref.id
        const uid = getState().users.uid        
        if(img.length > 0){
            const response = await fetch(BASE_URL + "/v1/createOgpImg", {
                method:"POST",
                headers:headers,
                body:JSON.stringify({
                    imgId:img[0].id,                   
                    title:title,
                    name:name,                    
                })
            })
            const requestdetail = await response.json()            
            const requestbody = requestdetail.body
            const ogpImageId = requestbody.replace("ogpImages/", "")            
            const imgData = {
                created_at:timestamp,
                title:title,
                requestUid:uid,
                id:id,                
                img:img,
                accountId:accountId,
            }

            return db.collection('requests').doc(id).set(imgData ,{merge:true}).then(() => {
                dispatch(push('/art_request/all/' + id))
            })
        }else{
            const S = "abcdefghijklmnopqrstuvwyzABCDEFGHIJLMNOPQRSTUVWXYZ1234567890"
            const N = 16
            const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n) => S[n % S.length]).join('')

            const response = await fetch(BASE_URL + "/v1/createNoImageOgp", {
                method:"POST",
                headers:headers,
                body:JSON.stringify({
                    name:name,
                    fileName:fileName,
                    title:title
                })
            })
            const requestdetail = await response.json()            

            const data = {
                created_at:timestamp,
                title:title,                
                requestUid:uid,
                id:id,
                img:[{id:fileName}],
                accountId:accountId,
            }
            return db.collection('requests').doc(id).set(data ,{merge:true}).then(() => {
                dispatch(push('/art_request/all/' + id))
            })
        }

    }
}

export const fixRequest = (id, title, img, accountId, name) => {
    return async (dispatch, getState) => {
        const timestamp = FirebaseTimestamp.now()        
        const uid = getState().users.uid        
        if(img.length > 0){
            const response = await fetch(BASE_URL + "/v1/createOgpImg", {
                method:"POST",
                headers:headers,
                body:JSON.stringify({
                    imgId:img[0].id,                   
                    title:title,
                    name:name
                })
            })
            const requestdetail = await response.json()            
            const requestbody = requestdetail.body
            const ogpImageId = requestbody.replace("ogpImages/", "")            

            const imgData = {
                created_at:timestamp,
                title:title,
                requestUid:uid,                
                img:img,                
                accountId:accountId,                  
            }
            return db.collection('requests').doc(id).set(imgData, {merge:true}).then(() => {
                dispatch(push('/art_request/all/' + id))
            })
        }else{
            const S = "abcdefghijklmnopqrstuvwyzABCDEFGHIJLMNOPQRSTUVWXYZ1234567890"
            const N = 16
            const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n) => S[n % S.length]).join('')

            const response = await fetch(BASE_URL + "/v1/createNoImageOgp", {
                method:"POST",
                headers:headers,
                body:JSON.stringify({
                    name:name,
                    fileName:fileName,
                    title:title
                })
            })

            const requestdetail = await response.json()            

            const data = {
                created_at:timestamp,
                title:title,                
                requestUid:uid,
                img:[{id:fileName}],
                accountId:accountId,
            }

            return db.collection('requests').doc(id).set(data, {merge:true}).then(() => {
                dispatch(push('/art_request/all/' + id))
            })
        }
    }
}
//イラストレーターからインフルエンサーに完成した画像を送る
export const sendImage = (img, ownerId, artId, id) => {
    return async (dispatch, getState) => {
        const uid = getState().users.uid
        const timestamp = FirebaseTimestamp.now()
        const {width, height} = await reactImageSize(img[0].path)
        //artの中に完成したimagedataを入れておく
        const roughref = db.collection('arts').doc(artId).collection('complates').doc()
        const roughId = roughref.id
        const roughdata = {
            created_at:timestamp,
            id:roughId,
            img:img,
            width:width,
            height:height,
            delivery:true,
            artId:artId
        }
        //notificationsに「届いたよ！」の通知
        const ref = db.collection("users").doc(ownerId).collection('notifications').doc()
        const refId = ref.id
        const refdata = {
            created_at:timestamp,
            id:refId,
            artId:artId,
            img:img,
            roughSend:true,
            width:width,
            height:height,
            complateId:roughId,
            look:false
        }
        //送り主が送ったことを確認しこの通知を削除できるようにする
        const notifidata = {
            send:true
        }
        db.collection('users').doc(uid).collection('notifications').doc(id).set(notifidata,{merge:true})
        db.collection('users').doc(ownerId).collection('notifications').doc(refId).set(refdata).then(() => {
            db.collection('arts').doc(artId).collection('complates').doc(roughId).set(roughdata).then(() => {
                dispatch(push('/art_delivery_complate/' + artId))
            })
        })
    }
}