import { ClosableDrawer } from '../components/Header'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Dialog, IconButton, Typography, } from '@material-ui/core'
import logo from "../assets/logo.png"
import notionPottin from '../assets/pottins_hightouch.png'
import React,{useEffect, useState} from 'react'
import { Avatar, Button, Divider, Grid,  DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import { auth, db } from '../firebase'
import { useDispatch } from 'react-redux'
import {push} from 'connected-react-router'
import LinkIcon from '@material-ui/icons/Link';
import TwitterIcon from '@material-ui/icons/Twitter';
import ShareIcon from '@material-ui/icons/Share';
import share from '../assets/shareicon.png'
import noImage from '../assets/noImage.png'
import { ArtsCard } from '../components/Arts'
import { Helmet } from 'react-helmet-async'
import HTMLReactParser from 'html-react-parser'
import { MypageHeader } from '../components/Header';

const returnCodeToBr = (text) => {
    if (text === ""){
      return text
    }else{
      return HTMLReactParser(text.replace(/\r?\n/g, '<br/>'))
    }
}

const UserPage = () => {

    const dispatch = useDispatch()

    const id = window.location.pathname.split('/mypage/')[1]
    const BASE_URL = "https://pallet.fun/mypage/" + id

    // const uid = getUserId(selector)
    // console.log(uid)
    const [uid, setUid] = useState('')
    const [signedIn, setSingedIn] = useState('')
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSingedIn(true)
                const unsubscribe = db.collection('users').doc(user.uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                    const list = []
                    snapshots.forEach(snapshot => {
                        list.push(snapshot.data())
                    })
                    setNotifis(list)
                })
                return () => unsubscribe() 
            }
        })
    },[])

    const [username, setUsername] = useState(''),
          [icons, setIcons] = useState(),
          [desc, setDesc] = useState(''),
          [twitterUrl, setTwitterUrl] = useState(''),
          [screen_name, setScreenName] = useState(''),
          [url, setUrl] = useState(''),
          [collecterCategory, setCollecterCategory] = useState('');
    const [arts, setArts] = useState([])

    useEffect(() => {
        db.collection('users').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            if(data){
                setUsername(data.username)
                setDesc(data.desc)
                setUrl(data.url)
                setTwitterUrl(data.twitterUrl)
                setScreenName(data.screen_name)
                setCollecterCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)                    
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            }
        })
    },[id])

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')
    const [loginWay, setLoginWay] = useState('')

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                setLoginWay(data.loginWay)
                if(data.icons === ""){
                    setIcon(noImage)
                }
                if(data.icons[0].path){
                    setIcon(data.icons[0].path)
                }
            })
        }
    },[uid])

    useEffect(() => {
        if(id){
            const artsList =[]
            db.collection('arts').where('createrId', "==", id).orderBy("created_at", "desc").get().then((snapshots) => {
                snapshots.forEach(snapshot => {
                    const data = snapshot.data()
                    artsList.push(data)
                })
                setArts(artsList)
            })
        }
    },[id])    

    const [notifis, setNotifis] = useState([])

    const [shareOpen, setShareOpen] = useState(false)
    const shareClick = () => {
        setShareOpen(true)
    }
    const shareClose = () => {
        setShareOpen(false)
    }
    const [copyok, setCopyOk] = useState(false)

    const copyTextClipboard = () => {
        navigator.clipboard.writeText(BASE_URL).then(() => {
            setCopyOk(true)
        }).catch(() => {
            alert("クリップボードへのコピーが失敗しました。通信環境を確認の上再度お試しください。")
        })
    }
    return(
        <>
        <Helmet
            title={`${username}さんのページ/Pallet`}
        />
        <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                    {signedIn === true ? (
                    <>
                        <ClosableDrawer uid={uid} category={category} icons={icon} notifis={notifis} />
                        
                    </>                
                    ):(
                        <>
                            <img src={logo} alt="logo" width="128px"/>
                            <div className='header_iconButton'>
                                <MypageHeader id={id} key={id}/>
                            </div>
                        </>
                    )}
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
        <div className="module-spacer--medium"/>
        <div className='center' style={{maxWidth:"800px"}}>
              <div style={{maxWidth:"800px", display:"flex", margin:"auto"}}>
                    <Avatar className="mypage_avatar" style={{backgroundColor:"#fff"}}>
                        <img className="mypage_icon" src={icons} alt="p"/>
                    </Avatar>
                    <div style={{minWidth:"250px"}}>
                        <p className='mypage_username'>{username}</p>
                    
                        {desc === "" ? (
                            <>                     
                                <p style={{textAlign:"left"}} className='mypage_desc'>Palletはじめました！よろしくお願いします！</p>                     
                            </>
                        ):(
                            <p style={{textAlign:"left"}} className="mypage_desc">{returnCodeToBr(desc)}</p>
                        )}
                        <div className="edit_user_link">
                            <div style={{display:"flex"}}>
                                <LinkIcon style={{margin:"auto 10px auto 0"}}/>
                                <p className="edit_user_link_text"><a href={url} target="_blank" rel="noreferrer noopener" style={{color:"#4ab4fc"}}>{url}</a></p>
                            </div>
                            <div style={{display:"flex", margin:"auto 0 auto auto"}}>
                                {twitterUrl !== "" && screen_name !=="" && (
                                    <a href={twitterUrl} target="_blank" rel="noreferrer" className="edit_twitter_button">
                                        <TwitterIcon style={{color:"#1da1f2", marginTop:"5px",textDecoration:"none"}}/>
                                    </a>
                                )}
                                {/* <ShareIcon style={{marginTop:"5px", marginLeft:"10px", cursor:"pointer"}} onClick={shareClick}/> */}
                                <IconButton style={{width:35, height:35, margin:"auto"}} onClick={shareClick}>
                                    <img src={share} style={{width:22, height:22}} alt="share"/>
                                </IconButton>
                                <Dialog open={shareOpen} onClose={shareClose}>
                                    <DialogTitle>SNSに共有しよう!</DialogTitle>
                                    {copyok === true && (
                                        <DialogContent>
                                            <Typography>
                                                クリップボードにリンクをコピーしました!
                                            </Typography>
                                        </DialogContent>                                        
                                    )}
                                    <DialogActions>
                                        <a href={`http://twitter.com/share?url=${BASE_URL}&text=${username}さんのページ&hashtags=Pallet&screen_name=${screen_name}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                            <Button style={{textTransform:"none", backgroundColor:"#1da1f2", color:"#fff", borderRadius:"12px"}}>Twitterへシェア</Button>
                                        </a>
                                        <Button onClick={() => copyTextClipboard()} style={{backgroundColor:"#808080", color:"#FFF", borderRadius:"12px"}}>リンクコピー</Button>
                                    </DialogActions>
                                </Dialog>
                                {id === uid && loginWay === "email" && (
                                    <div className='edit_button'>
                                        <Button variant="outlined" style={{textDecoration:"none", backgroundColor:"#fff", margin:"0 0 0 auto", borderRadius:"12px"}} onClick={() => dispatch(push('/profile_edit/' + id))}>編集</Button>
                                    </div>
                                )}                                                
                            </div>
                        </div>
                    </div>
                </div>
            <div className="module-spacer--medium"/>
            {collecterCategory === "influencer" ? (
                <div style={{display:"flex", textAlign:"center"}}>                      
                    <p className="select_collecter_page" style={{color:"#808080"}} onClick={() => dispatch(push('/art_list/all/' + id))} >お題一覧</p>                      
                    <p className='select_request_page' >作品一覧</p>
                </div>
            ):(
                <h3 style={{textAlign : "left ", marginLeft:"20px"}}>作品一覧</h3>
            )}
            <Divider  style={{maxWidth:"800px"}}/>
            {/* <ImageList className={classes.imageList}> */}
            <div className='module-spacer--medium'/>
            <div className="center">
                <Grid contain="true" style={{alignItems:"stretch", display:"flex", flexWrap:"wrap", justifyContent:"space-around"}} onContextMenu={(e) => e.preventDefault()}>
                    {arts.length > 0  ? (
                        arts.map(art => (                            
                            <div style={{margin:0}} key={art.id}>
                                <ArtsCard
                                id={art.id} key={art.id} userId={id}
                                />
                            </div>
                            
                        ))
                    ):(
                        <div className="center">
                            <img className="home_request_pottin" src={notionPottin} alt="request"/>
                            <div className='module-spacer--medium'/>                               
                            <p>作品を投稿しよう!</p>
                            <div className='module-spacer--medium'/>
                        </div>
                    )}
                </Grid>
            </div>
            <div className='module-spacer--medium'/>
        </div>
      </>
    )
}

export default UserPage