import {Route, Switch} from 'react-router'
import {Home, Login, UserPage, ProfileEdit, ArtEdit, SignIn, UserPageDetail, ArtDetail, ArtHome, ArtLogin, ArtSignIn, RequestPage, RequestEdit, Notifications, ArtDetailHome, ArtDetailLogin, ArtDetailSignIn, NotificationWelcome, CheckoutWrapper, PaymentComplate, PaymentError, Paymentbank, FavoriteList, ConnectRefresh, ConnectSuccess, RequestFix, RequestForm, ArtRequestList, PaymentForm, DownloadComplate, ArtRequestLogin, ArtRequestHome, ArtRequestSignIn, ArtDeliveryComplate, AdornmentInfluencerList, MypageDetailHome, MypageDetailLogin, MypageDetailSignIn, MypageHome, MypageLogin, MypageSignIn, IllustHome, IllustAtrDetailHome, IllustArtHome, IllustMypageDetailHome, IllustMypageHome, IllustArtRequestHome, ArtSubmissionComplate, Rules, Policy, Trade, PaymentFormCardComplate, PaymentNotifiComp, IllustArtRequestBank, StartConnectSuccess, Reset, ArtReset, ArtDetailReset, ArtRequestReset, MypageReset, MypageDetailReset, CollecterFavorites, HomeLoading, ArtLoading, ArtDetailLoading, ArtRequestLoading, MypageDetailLoading, MypageLoading} from  './templates'
import {InfluencerLogin, InfluencerSignin, ArtInfluencerLogin, ArtInfluencerSignin, ArtDetailInfluencerSignin, ArtDetailInfluencerLogin, InfluencerWelcome, ArtRequestInfluencerSignin, ArtRequestInfluencerLogin, MypageDetailInfluencerLogin, MypageDetailInfluencerSignin, MypageInfluencerLogin, MypageInfluencerSignin, InfluencerReset, ArtDetailInfluencerReset, ArtInfluencerReset, ArtRequestInfluencerReset, MypageDetailInfluencerReset, MypageInfluencerReset} from './templates/Influencer'
import { ManagementAlert, ManagementArtList, ManagementCollecterAlert, ManagementDetail, ManagementList, ManagementScreen, IllustManagementDetail, ManagementArtDetail, ManagementRequestList } from './templates/Management'
import Auth from './Auth'
import ProducerAuth from './ProducerAuth'
import {useTracking} from './components/UIkit/tracking.ts'
// import {initializeGA, useTracking} from './components/UIkit/GoogleAnalyticsUtil'

const Router = () => {
    useTracking("UA-226174698-1")
    useTracking("G-G0PPEKJS7C")
    return(
        <Switch>
            <Route exact path="(/)?" component={Home}/>
            <Route exact path="/i_home" component={IllustHome}/>
            <Route path="/art_home(/:id)?" component={ArtHome}/>
            <Route path="/i_art_home(/:id)?" component={IllustArtHome}/>
            <Route path="/art_detail_home(/:id)?" component={ArtDetailHome}/>
            <Route path="/i_art_detail_home(/:id)?" component={IllustAtrDetailHome}/>
            <Route path="/mypage_detail_home(/:id)?" component={MypageDetailHome}/>
            <Route path="/i_mypage_detail_home(/:id)?" component={IllustMypageDetailHome}/>
            <Route path="/mypage_home(/:id)?" component={MypageHome}/>
            <Route path="/i_mypage_home(/:id)?" component={IllustMypageHome}/>
            <Route path="/art_request_home(/:id)?" component={ArtRequestHome}/>
            <Route path="/i_art_request_home(/:id)?" component={IllustArtRequestHome}/>
            <Route exact path="/login" component={Login}/>
            <Route path="/art_login(/:id)?" component={ArtLogin} />
            <Route path="/art_detail_login(/:id)?" component={ArtDetailLogin} />z
            <Route path="/mypage_detail_login(/:id)?" component={MypageDetailLogin} />
            <Route path="/mypage_login(/:id)?" component={MypageLogin} />
            <Route path="/art_request_login(/:id)?" component={ArtRequestLogin} />
            <Route exact path="/signin" component={SignIn}/> 
            <Route exact path="/reset/password" component={Reset}/>
            <Route path="/art_signin(/:id)?" component={ArtSignIn}/>
            <Route path="/art_reset/password(/:id)?" component={ArtReset}/>
            <Route path="/art_detail_signin(/:id)?" component={ArtDetailSignIn}/>
            <Route path="/art_detail_reset/password(/:id)?" component={ArtDetailReset}/>
            <Route path="/mypage_detail_signin(/:id)?" component={MypageDetailSignIn}/>
            <Route path="/mypage_detail_reset/password(/:id)?" component={MypageDetailReset}/>
            <Route path="/mypage_signin(/:id)?" component={MypageSignIn}/>
            <Route path="/mypage_reset/password(/:id)?" component={MypageReset}/>
            
            <Route path="/art_request_signin(/:id)?" component={ArtRequestSignIn}/>
            <Route path="/art_request_reset/password(/:id)?" component={ArtRequestReset}/>
            <Route exact path="/influencer/login" component={InfluencerLogin}/>
            <Route exact path="/influencer/signin" component={InfluencerSignin}/>
            <Route exact path="/influencer_reset/password" component={InfluencerReset}/>
            <Route path="/art_influencer/login(/:id)?" component={ArtInfluencerLogin}/>
            <Route path="/art_influencer/signin(/:id)?" component={ArtInfluencerSignin}/>
            <Route path="/art_influencer_reset/password(/:id)?" component={ArtInfluencerReset}/>
            <Route path="/mypage_influencer/login(/:id)?" component={MypageInfluencerLogin}/>
            <Route path="/mypage_influencer/signin(/:id)?" component={MypageInfluencerSignin}/>
            <Route path="/mypage_influencer_reset/password(/:id)?" component={MypageInfluencerReset}/>
            <Route path="/art_detail_influencer/login(/:id)?" component={ArtDetailInfluencerLogin}/>
            <Route path="/art_detail_influencer/signin(/:id)?" component={ArtDetailInfluencerSignin}/>
            <Route path="/art_detail_influencer_reset/password(/:id)?" component={ArtDetailInfluencerReset}/>
            <Route path="/mypage_detail_influencer/login(/:id)?" component={MypageDetailInfluencerLogin}/>
            <Route path="/mypage_detail_influencer/signin(/:id)?" component={MypageDetailInfluencerSignin}/>
            <Route path="/mypage_detail_influencer_reset/password(/:id)?" component={MypageDetailInfluencerReset}/>            
            <Route path="/art_request_influencer/login(/:id)?" component={ArtRequestInfluencerLogin}/>
            <Route path="/art_request_influencer/signin(/:id)?" component={ArtRequestInfluencerSignin}/>
            <Route path="/art_request_influencer_reset/password(/:id)?" component={ArtRequestInfluencerReset}/>
            {/* <Route path="/art_list/all(/:id)?" component={Page} /> */}
            <Route path="/art_list/all(/:id)?" component={RequestPage} />                        
            <Route path="/art_request/all(/:id)?" component={ArtRequestList}/>
            <Route path="/art_detail(/:id)?" component={ArtDetail} />                
            <Route path="/mypage(/:id)?" component={UserPage}/>
            <Route path="/detail(/:id)?" component={UserPageDetail}/>
            <Route exact path="/rules" component={Rules}/>
            <Route exact path="/policy" component={Policy}/>
            <Route exact path="/trade" component={Trade}/>
            
            <Route exact path="/home_loading" component={HomeLoading} />
            <Route path="/art_loading(/:id)?" component={ArtLoading}/>
            <Route path="/art_detail_loading(/:id)?" component={ArtDetailLoading}/>
            <Route path="/art_request_loading(/:id)?" component={ArtRequestLoading}/>
            <Route path="/mypage_detail_loading(/:id)?" component={MypageDetailLoading}/>
            <Route path="/mypage_loading(/:id)?" component={MypageLoading}/>
            <Route exact path="/management_screen" component={ManagementScreen}/>
            <Route exact path="/collecter_list" component={AdornmentInfluencerList}/>
            <Auth>
                <Route path="/profile_edit(/:id)?" component={ProfileEdit} />                
                <Route path="/art/edit(/:id)?" component={ArtEdit}/>
                <Route path="/art_request/edit(/:id)?" component={RequestEdit} />  
                <Route exact path="/notifications" component={Notifications}/>
                <Route  path="/notification/welcome(/:id)?" component={NotificationWelcome} />
                <Route  path='/notification_influencer/welcome(/:id)?' component={InfluencerWelcome} />
                <Route path="/payment_edit(/:id)?" component={CheckoutWrapper} />
                <Route exact path="/payment_error" component={PaymentError} />
                <Route exact path="/payment_complate" component={PaymentComplate} />
                <Route  path="/payment_bank(/:id)?" component={Paymentbank} />
                <Route path='/art_like/all(/:id)?' component={FavoriteList} />
                <Route path="/art_like/collecter(/:id)?" component={CollecterFavorites}/>
                <Route path="/i_art_request_bank(/:id)?" component={IllustArtRequestBank} />
                <Route path="/connect_success(/:id)?" component={StartConnectSuccess}/>
                <Route exact path="/connect/success" component={ConnectSuccess} />
                <Route exact path="/connect/refresh" component={ConnectRefresh} />
                <Route path="/art_request/fix(/:id)?" component={RequestFix} />
                <Route path="/art_request/form(/:id)?" component={RequestForm}/>
                <Route path="/payment_form(/:id)?" component={PaymentForm}/>                
                <Route path="/download_complate(/:id)?" component={DownloadComplate}/>                
                <Route path="/art_delivery_complate(/:id)?" component={ArtDeliveryComplate}/>
                <Route path="/art_submission_complate/(:id)?" component={ArtSubmissionComplate}/>
                
                <Route path="/payment_form_card/complate(/:id)?" component={PaymentFormCardComplate}/>
                <Route path="/payment_notifi_card/complate(/:id)?" component={PaymentNotifiComp}/>
                {/* <ProducerAuth>
                    <Route exact path="/management_art_list" component={ManagementArtList} />
                    <Route exact path="/management_list" component={ManagementList}/>
                    <Route path="/management_detail(/:id)?" component={ManagementDetail}/>
                    <Route path="/i_management_detail(/:id)?" component={IllustManagementDetail}/>
                    <Route exact path="/management_alert" component={ManagementAlert}/>
                    <Route exact path="/management_collecter_alert" component={ManagementCollecterAlert}/>
                    <Route path="/management_art_detail(/:id)?" component={ManagementArtDetail}/>
                    <Route exact path="/management_request_list" component={ManagementRequestList}/>
                </ProducerAuth> */}
            </Auth>
        </Switch>
    )
}

export default Router
