import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import { db } from '../firebase'
import { AppBar, Divider } from '@material-ui/core'
import ToolBar from '@material-ui/core/ToolBar'
import { getCategory, getUserId } from '../reducks/users/selectors'
import noImage from '../assets/noImage.png'
import {makeStyles} from '@material-ui/core/styles'

import bendover from '../assets/pottin_bendover.png'
import { GrayButton, PrimaryButton } from '../components/UIkit'
import { ClosableDrawer } from '../components/Header'

const useStyle = makeStyles((theme) => ({
    img:{
        [theme.breakpoints.down('sm')]:{
            width:200,
            height:350
        },
        [theme.breakpoints.up('sm')]:{
            width:250,
            hieght:400
        }
    }
}))


const ConnectRefresh = () => {
    const classes = useStyle()
    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)
    const category = getCategory(selector)

    const [icon, seticon] = useState()

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                if(data){
                    if(data.icons === ""){
                        seticon(noImage)
                    }
                    if(data.icons[0].path){
                        seticon(data.icons[0].path)
                    }
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscibe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscibe()
    },[uid])

    return(
        <>
            <Helmet
            title="支払い情報設定/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className='center' style={{maxWidth:800}}>
                <div className='module-spacer--medium'/>
                <h3>口座登録に失敗しました。</h3>
                <p>通信環境/カード番号をご確認の上<br/>再度お試しください</p>
                <img src={bendover} alt="bendover" className={classes.img} style={{marginTop:"-80px"}}/>
                <br/>
                <PrimaryButton
                  label="カード登録画面へ"
                  onClick={() => dispatch(push('/payment_edit/' + uid))}
                />
                <br/>
                {category === "influencer" ? (
                    <GrayButton
                      label="ホームへ"
                      onClick={() => dispatch(push('/art_list/all/' + uid))}
                    />
                ):(
                    <GrayButton
                      label="ホームへ"
                      onClick={() => dispatch(push('/mypage/' + uid))}
                    />
                )}
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default ConnectRefresh