import React,{useState, useEffect} from 'react'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import {db} from '../../firebase'

const UserDownloadDetail = (props) => {
    const id = props.id
    const dispatch = useDispatch()

    const [img, setImg] = useState()

    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()

            if(data.img){
                setImg(data.img[0].id)
            }
        })
    },[id])

    // const [check, setCheck] = useState()    

    // useEffect(() => {
    //     const unsubscribe = db.collection('arts').doc(id).collection('favorites').doc("favorites").onSnapshot(doc => {
    //         const data = doc.data()
    //         if(data){
    //             setCheck(true)
    //         }else{
    //             setCheck(false)
    //         }
    //     })
    //     return () => unsubscribe()
    // },[id])

    // const [icons, setIcons] = useState()
    // const [name, setName] = useState('')
    // useEffect(() => {
    //     db.collection('arts').doc(id).get().then(snapshot => {
    //         const data = snapshot.data()
    //         db.collection('users').doc(data.artId).get().then(snapshot => {
    //             const artdata = snapshot.data()
    //             setName(artdata.username)
    //             if(artdata.icons[0].path){
    //                 setIcons(artdata.icons[0].path)
    //             }else{
    //                 setIcons(noImage)
    //             }
    //         })
    //     })
    // },[id])

    return(
        <div className="art_show_root">
            <div className="art_detail_button">
                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="p" className="art_show_img"  style={{objectFit:"cover", cursor:"pointer"}} onClick={() => dispatch(push('/art_detail/' + id))}/>
            </div>
        </div>
    )
}

export default UserDownloadDetail