import React from 'react'
import {Grid} from '@material-ui/core'
const Footer = () => {
    return(
        <>            
            <div className='center' style={{maxWidth:"1000px", backgroundColor:"#FFFFEF", padding:0}}>
                <div className='module-spacer--medium'/>
                <div className='module-spacer--medium'/>
                <div className='module-spacer--medium'/>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={4} sm={2}>
                        <a className="u-text__link-none" href="/rules" target="_blank" ><span className="footer_desc">利用規約</span></a>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <a className="u-text__link-none" href="/policy" target="_blank"><span className="footer_desc">プライバシーポリシー</span></a>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <a className='u-text__link-none' href="/trade" target="_blank"><span className="footer_desc">特定商取引法に基づく表記</span></a>
                    </Grid>
                    {/* <Grid item xs={4} sm={2}>
                        <a className='u-text__link-none' href="https://narrow-acoustic-d74.notion.site/Pallet-5c8b7d47479d4a1b956feb3241420cff" target="_blank" rel="noreferrer"><span className="footer_desc">初めてのPallet</span></a>
                    </Grid> */}
                    <Grid item xs={4} sm={2}>
                        <a className='u-text__link-none' href="https://docs.google.com/forms/d/e/1FAIpQLSfQlxb9FZwBeIiMO_gTDAszbPqSNRGXIEX2QXsNbg_QPf1rYA/viewform?usp=sf_link" target="_blank" rel="noreferrer"><span className='footer_desc'>ご意見フォーム</span></a>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <a className="u-text__link-none" href="https://potland.co.jp" target="_blank" rel="noreferrer"><span className='footer_desc'>会社概要</span></a>
                    </Grid>
                </Grid>                    
                <div className='module-spacer--medium'/>
                
                <p className='center' style={{fontSize:"10px", color:"#808080", fontWeight:"bold"}}>
                    &copy;PotLand Inc.
                </p>
                <div className='module-spacer--small'/>
            </div>
        </>
    )
}

export default Footer