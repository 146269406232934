import React,{useEffect, useState} from 'react'
import {Grid, Tooltip} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { db } from '../../firebase'
import newpost from "../../assets/new.png"
import noImage from '../../assets/noimageheader.png'

const RequestCard = (props) => {
    const id = props.id
    const requestUid = props.requestUid
    const uid = props.uid
    const dispatch = useDispatch()

    const [title, setTitle] = useState('')
    const [stop, setStop] = useState(false)
    const [img, setImg] = useState(noImage)    

    useEffect(() => {
        db.collection('requests').doc(id).get().then(snapshot => {
            const data = snapshot.data()

            setTitle(data.title)              
            if(data.stop){
                setStop(data.stop)
            }else{
                setStop(false)
            }           
            if(data.img[0].path){
                setImg(data.img[0].path)
            }
        })
    },[id])

    const [artslength, setArtslength] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('arts').where('requestId', "==", id).where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                const data = snapshot.data()
                list.push(data)
            })
            setArtslength(list)
        })
        return () => unsubscribe()
    },[id])
    return (
        <>
            {uid === requestUid ? (
                <>
                    {artslength.length > 0 ? (
                        <Grid item xs={12} sm={6} style={{position:"relative", cursor:"pointer"}} className="request_card_size" onClick={() => dispatch(push("/art_request/all/" + id))} >                            
                            <img src={img} alt="p" className='request_card_img_size' style={{background:"rgba(0, 0, 0, 0.5)"}}/>
                            <Tooltip title="新しい投稿があります">
                                <img src={newpost} alt="P"className='request_newpost'/>
                            </Tooltip>
                            {stop === true && (
                                <p style={{position:"absolute", color:"red"}} className="request_card_alert">作品の投稿期間が終了しました</p>
                            )}
                            <p style={{position:"absolute", color:"#fff"}} className="request_card_title">{title}</p>            
                        </Grid>
                    ):(
                        <Grid item xs={12} sm={6} style={{position:"relative", cursor:"pointer"}} className="request_card_size" onClick={() => dispatch(push("/art_request/all/" + id))} >
                            <img src={img} alt="p" className='request_card_img_size' style={{background:"rgba(0, 0, 0, 0.5)"}}/>
                            {stop === true && (
                                <p style={{position:"absolute", color:"red"}} className="request_card_alert">作品の投稿期間が終了しました</p>
                            )}
                            <p style={{position:"absolute", color:"#fff"}} className="request_card_title">{title}</p>            
                        </Grid>

                    )}
                </>
            ):(
                <Grid item xs={12} sm={6} style={{position:"relative", cursor:"pointer"}} className="request_card_size" onClick={() => dispatch(push("/art_request/all/" + id))} >
                    <img src={img} alt="p" className='request_card_img_size' style={{background:"rgba(0, 0, 0, 0.5)"}}/>
                    {stop === true && (
                        <p style={{position:"absolute", color:"red"}} className="request_card_alert">作品の投稿期間が終了しました</p>
                    )}
                    <p style={{position:"absolute", color:"#fff"}} className="request_card_title">{title}</p>
                </Grid>
            )}
        </>
    )
}

export default RequestCard