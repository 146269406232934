import React, {useState, useEffect, useCallback} from 'react'
import {GrayButton, SecondaryButton} from '../components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet-async'
import { getUserId } from '../reducks/users/selectors'
import { db } from '../firebase'
import noImage from '../assets/noImage.png'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Button, Dialog, DialogContent, Typography, DialogActions, DialogTitle, CircularProgress} from '@material-ui/core'
import { ArtImageArea } from '../components/UIkit'
import { saveRequest } from '../reducks/requests/operations'
import { retrieveAccount } from '../reducks/stripes/operations'
import CheckIcon from '@material-ui/icons/Check';
import { ClosableDrawer } from '../components/Header'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    title_around:{
        margin:"auto",
        maxWidth:"780px",
        backgroundColor:"#F2AA1D",
        // padding:"18.5px 14px",
        // padding:"auto",
        borderRadius:"12px",
        border:"solid"
    },
    title_field:{
        maxWidth:"760px",
        backgroundColor:"#fff",
        border:"solid",
        borderRadius:"12px",
        [theme.breakpoints.down('sm')]:{
            width:"330px",
            margin:"10px"
        },
        [theme.breakpoints.between('sm', 'md')]:{
            width:"700px",
            margin:"5px"
        },
        [theme.breakpoints.up('md')]:{
            wdith:"760px",
            margin:"5px"
        }
    }
}))
const RequestEdit = () => {    
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState("")
    const [username, setUsername] = useState('')

    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()
            setCategory(data.category)
            setUsername(data.username)
            if(data.icons === ""){
                setIcon(noImage)
            }
            if(data.icons[0].path){
                setIcon(data.icons[0].path)
            }       
        })
    })

    const [title, setTitle] = useState('')    
    const [img, setImg] = useState("")
    // const [comment, setComment] = useState('')

    const inputTitle = useCallback((e) => {
        setTitle(e.target.value)
    },[setTitle])
    // const inputComment = useCallback((e) => {
    //     setComment(e.target.value)
    // }, [setComment])
    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])

    const [accountId, setAccountId] = useState('')
    const [submit, setSubmit] = useState(false)
    const [circle, setCircle] = useState('')
    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()
            if(data.account_id){
                setAccountId(data.account_id)
            }else{
                setAccountId("")
                setCircle(false)
            }
            (async () => {
                if(data.account_id !== ""){
                    const accountData = await retrieveAccount(data.account_id)                    
                    if(accountData){
                        setCircle(true)
                        setSubmit(accountData.details_submitted)
                    }else{
                        setCircle(false)
                    }
                }
            })()
        })
    },[uid])

    const [must, setMust] = useState("")
    const [errorOpen, setErrorOpen] = useState(false)

    const handleErrorOpen = () => {
        setMust('')
        setErrorOpen(false)
    }

    const [loading, setLoading] = useState(false)
    const [clickAction, setClickAction] = useState(false)
    const saveRequestClick = useCallback((title, img) => {
        
        if(title === ""){
            setMust('mismatch')
            setErrorOpen(true)
            setClickAction(false)
            return false
        }

        //報酬が入るのに口座登録をしていない場合
        if(submit === false){
            setMust('nobank')
            setErrorOpen(true)
            setClickAction(false)
            return false
        }
        setLoading(true)
        setClickAction(true)
        dispatch(saveRequest(title, img, accountId, username)).then(() => {
            setMust('')
            setErrorOpen(false)
        })        
    })

    const [random, setRandom] = useState([])
    useEffect(() => {
        db.collection('randomText').where('random', "==", true).get().then((snapshots) => {
            const list  = []
            snapshots.forEach(snapshot => {
                const data = snapshot.data()
                list.push(data)
            })
            setRandom(list)
        })
    },[])    

    const randomTextInput = useCallback((random) => {
        const get = random[Math.floor(Math.random() * random.length)]        
        setTitle(get.text)
    })

    if(circle === ""){
        return (
            <>
                <Helmet
                    title="リクエスト作成フォーム/Pallet"
                />
                <div className="header_root">
                    <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                        <ToolBar className="header_toolBar" style={{padding:0}}>
                            <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />    
                        </ToolBar>
                    </AppBar>
                    <Divider variant="middle"/>
                    <div className='center'>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                        <CircularProgress/>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                        <div className='module-spacer--medium'/>
                    </div>
                </div>
            </>
        )
    }else{

        return (
            <>
                <Helmet
                  title="リクエスト作成フォーム/Pallet"
                />
                <div className="header_root">
                    <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                        <ToolBar className="header_toolBar" style={{padding:0}}>
                            <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />    
                        </ToolBar>
                    </AppBar>
                    <Divider variant="middle"/>
                </div>
                <div className="module-spacer--medium"/>
                <div className='center' >
                    <div style={{maxWidth:780}} className="center">
                        <h2>お題を決めよう!</h2>
                        {submit === false && (
                            <div style={{textAlign:"left", marginLeft:"20px"}}>
                            <p style={{color:"red", fontWeight:"bold"}}>口座登録が完了後、お題を投稿できます</p>
                            <p style={{fontSize:"12px", }}>お題に集まった作品が購入されると、販売価格の15%が著作権使用料としてあなたに入ります</p>
                            <SecondaryButton
                                label="口座登録へ"
                                onClick={() => dispatch(push('/payment_bank/' + uid))}
                            />
                            </div>
                        )}
                        {submit === true && (
                            <div style={{display:"flex"}}>
                                <CheckIcon style={{color:"#26cd18", margin:"auto 0"}}/>
                                <p style={{fontWeight:"bold", textAlign:"left", marginLeft:"0px"}}>口座の登録が確認できました</p>
                            </div>
                        )}              
                        <p className="menuTitle">お題<span style={{fontWeight:"bold", fontSize:"16px", marginLeft:5}}>(30文字以内で)</span><span className="title_required">※必須</span></p>
                        <div className={classes.title_around}>
                            <div style={{maxWidth:"760px",background:"none",  display:"inline-flex", padding:0, position:"relative", flexDirection:"column", verticalAlign:"top", width:"99%", margin:"5px 0"}}>                            
                                <textarea value={title} onChange={inputTitle} type="text" style={{margin:"auto",border:"solid", maxWidth:"760px", borderRadius:"12px", position:"relative",background:"none", backgroundColor:"#fff", font:"inherit", boxSizing:"content-box", resize:"none", fontSize:"24px", fontWeight:"bold"}} area-invalid={false} rows={5} className="request_title_field"></textarea>                            
                            </div>
                        </div>
                        {title.length > 30 &&(
                            <p style={{textAlign:"left"}} className="title_required"><span style={{fontWeight:"bold"}}>[注意]</span>規定文字数を超えています!</p>
                        )}
                        <div style={{textAlign:"right", maxWidth:"780px", margin:"auto"}}>
                            <Button style={{backgroundColor:"#F2AA1D", color:"#fff", marginTop:10, borderRadius:"12px"}} onClick={() => randomTextInput(random)}>
                                お題おまかせ
                            </Button>
                        </div>
                        <p className="menuTitle">イメージ画像<span style={{fontWeight:"bold", marginLeft:"5px", fontSize:"16px"}}>(Twitterシェアの背景画像)</span></p>
                    </div>
                    <div style={{maxWidth:780}} className="center">
                      <ArtImageArea image={img} setImage={setImg} title={title} username={username}/>
                    </div>
                    <div className='module-spacer--small'/>
                    {/* <div className='center' style={{maxWidth:"780px"}}>
                        <p className="menuTitle">投稿者へのコメント<span style={{fontWeight:"bold", marginLeft:"5px", fontSize:"16px"}}>(作品を投稿した際にアイコンと共に表示されます)</span></p>
                        <div className={classes.title_around} style={{marginTop:30}}>
                            <div style={{maxWidth:"760px",background:"none",  display:"inline-flex", padding:0, position:"relative", flexDirection:"column", verticalAlign:"top", width:"99%", margin:"5px 0"}}>                            
                                <textarea value={comment} onChange={inputComment} type="text" style={{margin:"auto",border:"solid", maxWidth:"760px", borderRadius:"12px", position:"relative",background:"none", backgroundColor:"#fff", font:"inherit", boxSizing:"content-box", resize:"none", fontSize:"24px", fontWeight:"bold"}} area-invalid={false} rows={5} className="request_title_field"></textarea>                            
                            </div>
                        </div>
                    </div> */}
                    <div className='module-spacer--medium'/>
                    <Button disabled={clickAction} onClick={() => saveRequestClick(title, img)} style={{backgroundColor:"#ff7c21", color:"#fff", fontSize:"16px",height:"48px", marginBottom:"16px", marginTop:"16px", width:"256px", borderRadius:"12px"}}>投稿する</Button>
                    {loading === true && (
                        <p>投稿中です.....</p>
                    )}
                    {must === "mismatch" && (
                        <Dialog open={errorOpen} onClose={handleErrorOpen}>
                            <DialogTitle>必須項目が未入力です</DialogTitle>
                        </Dialog>
                    )}
                    {must === "nobank" && (
                        <Dialog open={errorOpen} onClose={handleErrorOpen} aria-labelledby="alert-dialog-title">
                            <DialogTitle id="alert-dialog-title">受け取りの口座が登録されていません</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    口座設定後リクエストが可能になります
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button style={{backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px"}} onClick={() => dispatch(push('/payment_bank/' + uid))}>口座登録をする</Button>
                            </DialogActions>
                        </Dialog>
                    )}                
                    <div className='module-spacer--small'/>
                    <GrayButton
                      label="戻る"
                      onClick={() => dispatch(push('/art_list/all/' + uid))}
                    />
                </div>
                <div className='module-spacer--medium'/>
                <div className='module-spacer--medium'/>
            </>
        )
    }

}

export default RequestEdit