import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, CircularProgress, } from '@material-ui/core'
import { getUserId, getCategory } from '../../reducks/users/selectors'
import noImage from '../../assets/noImage.png'

import React, {useCallback, useState, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import { db } from '../../firebase'
import {Helmet} from 'react-helmet-async'
import {Divider} from '@material-ui/core'
import {registerCard, retrievePaymentMethod} from '../../reducks/stripes/operations'
import {getCustomerId, getPaymentMethodId} from '../../reducks/users/selectors'
import { PrimaryButton, TextDetail } from '../UIkit'
import { ClosableDrawer } from '../Header'

const PaymentEdit = () => {

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)
    const category = getCategory(selector)
    const stripe = useStripe()
    const elements = useElements()
    const customerId = getCustomerId(selector)
    const paymentMethodId = getPaymentMethodId(selector)

    const [icon, seticon] = useState()
    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                if(data){
                    if(data.icons === ""){
                        seticon(noImage)
                    }
                    if(data.icons[0].path){
                        seticon(data.icons[0].path)
                    }
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscibe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscibe()
    },[uid])

    //stripe
    const [clickAction, setClickAction] = useState(false)
    const [loading, setLoading] = useState(false)
    const register = useCallback(() => {
        setLoading(true)
        setClickAction(true)
        dispatch(registerCard(stripe, elements, customerId))
    },[stripe,elements, customerId]) 

    const [card, setCard] = useState({})
    const [circle, setCircle] = useState('')
    useEffect(() => {        
        (async () => {
            if(paymentMethodId !== ""){
                const cardData = await retrievePaymentMethod(paymentMethodId)
                if(cardData){
                    setCircle(true)
                    setCard(cardData)
                }else{
                    setCircle(false)
                }
            }else{
                setCircle(false)
            }
        })()
    },[paymentMethodId])

    const cardNumber = useMemo(() => {
        if(card.last4){
            return "**** **** **** " +  card.last4
        }else {
            return "未登録"
        }
    },[card])

    if(circle === ""){
        return(
            <>
                <Helmet
                title="支払い情報設定/Pallet"
                />
                <div className="header_root">
                    <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                        <ToolBar className="header_toolBar" style={{padding:0}}>
                        <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />          
                        </ToolBar>
                    </AppBar>
                    <Divider variant="middle"/>
                </div>
                <div className='center'>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <CircularProgress/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                </div>
            </>
        )
    }else {        
        return (
            <>
            <Helmet
            title="支払い情報設定/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                    <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />          
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="center" style={{maxWidth:"800px", margin:"auto"}}>
                <div className='module-spacer--medium'/>
                {card.last4 ? (
                    <>                    
                        <h3>支払いカードの管理</h3>
                        <div className="module-spacer--medium"/>
                        <div style={{maxWidth:"400px", margin:"0 auto"}}>
                            <p>現在登録しているカード情報</p>
                            <div className="center" style={{maxWidth:"200px"}}>
                                <TextDetail label={card.brand} value={cardNumber}/>
                            </div>
                        </div>
                        <div className='module-spacer--medium'/>
                        <p>変更するカード情報の入力</p>
                        <div style={{maxWidth:500, margin:"0 auto"}}>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                            />
                        </div>
                    <div className="module-spacer--medium"/>
                        <PrimaryButton
                            disabled={clickAction}
                            label="カード情報を変更する"
                            onClick={() => register()}
                        />
                        {loading === true && (
                            <p>処理中です.....</p>
                        )}
                    </>
                ):(
                    <>
                        <h3>カード情報の登録</h3>
                        <div className='module-spacer--medium'/>
                        <div style={{maxWidth:500, margin:"0 auto"}}>
                            <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}                        
                        />
                        </div>
                    <div className="module-spacer--medium"/>
                    <PrimaryButton
                        disabled={clickAction}
                        label="カード情報を登録する"
                        onClick={() => register()}
                    />
                    {loading === true && (
                        <p>処理中です.....</p>
                    )}
                    </>
                )}
            </div>
            <div className="module-spacer--medium"/>
            <div style={{backgroundColor:"#fef8f5", maxWidth:"500px", margin:"0 auto", borderRadius:"8px", border:"solid 1px #E4E8ED"}}>
                <p style={{padding:10}}>※クレジットカード情報は当社では保持せず、オンライン決済サービスStripeにて安全に管理されます。Stripeについては<a href="https://stripe.com/jp" target="_blank" rel="noreferrer" ><span>こちら</span></a>から確認できます。</p>
                {/* <div className="center">
                    <p style={{paddingBottom:10}}>詳細は<a href="https://stripe.com/jp/ssa" target="_blank" rel="noreferrer" ><span>こちら</span></a></p>
                </div> */}
            </div>
            <div className="module-spacer--medium"/>
            </>
        )
    }

}

export default PaymentEdit