import React,{useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {push} from 'connected-react-router'
import { db } from '../../firebase'
import noImage from '../../assets/noimageheader.png'

const RequestAllList = (props) => {
    const dispatch = useDispatch()
    const id = props.id

    const [title, setTitle] = useState('')
    const [img, setImg] = useState(noImage)

    useEffect(() => {
        db.collection('requests').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setTitle(data.title)
            if(data.img[0].path){
                setImg(data.img[0].path)
            }
        })
    },[id])

    return (
        <div style={{position:"relative", cursor:"pointer"}} className="request_card_size" onClick={() => dispatch(push('/art_request/all/' + id))}>
            <img src={img} alt="p" className='request_card_img_size' style={{background:"rgba(0, 0, 0, 0.5)"}}/>
            <p style={{position:"absolute", color:"#fff"}} className="request_card_title">{title}</p>
        </div>
    )
}

export default RequestAllList