import { CardElement } from "@stripe/react-stripe-js"
import {db, FirebaseTimestamp} from '../../firebase/index' 
import {push} from 'connected-react-router'
import { updateUserStateAction, updateUserAccountAction, updateUserLoginUrl} from "../users/actions"

const headers = {'Content-Type': 'application/json'}

const BASE_URL = "https://pallet.fun"


//クレカ登録

const createCustomer = async (username, paymentMethodId, uid) => {    
    const response = await fetch(BASE_URL + '/v1/customer', {
    // const response = await fetch(TEST_URL + "/stripeCustomer", {
        method:'POST',
        headers:headers,
        body:JSON.stringify({
            username:username,
            paymentMethod:paymentMethodId,
            userId: uid,
        })
    })
    const customerResponse = await response.json();
    return JSON.parse(customerResponse.body);
}

export const retrievePaymentMethod = async (paymentMethodId) => {
    try {
      const response = await fetch(BASE_URL + "/v1/paymentMethod",{
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
              paymentMethodId: paymentMethodId
          })
        })
        
      const paymentMethodResponse = await response.json();
      const paymentMethod = JSON.parse(paymentMethodResponse.body);
      return paymentMethod.card
    } catch (e) {
      console.error(e)
    }
}

const updatePaymentMethod = async (customerId, prevPaymentMethodId, nextPaymentMethodId) => {
    const response = await fetch(BASE_URL + "/v1/updatePaymentMethod", {
        method: "POST",
        headers:headers,
        body:JSON.stringify({
            customerId: customerId,
            prevPaymentMethodId : prevPaymentMethodId,
            nextPaymentMethodId: nextPaymentMethodId
        })
    })

    const paymentMethodResponse = await response.json()
    const paymentMethod = JSON.parse(paymentMethodResponse.body)
    return paymentMethod.card
} 

export const registerCard = (stripe, elements, customerId, page, id) => {
    return async (dispatch, getState) => {
        const user = getState().users
        const username = user.username
        const uid = user.uid

        if (!stripe || !elements) {
            return;
        }
          const cardElement = elements.getElement(CardElement);
          
          const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
      
        if (error) {
            console.log('[error]', error);
            return
        } 

        const paymentMethodId = paymentMethod.id
        
        if (customerId === "") {                        
            const customerData = await createCustomer(username, paymentMethodId, uid)

            if(customerData.id === ""){
                dispatch(push('/payment_error'))
                return
            }else{
                const updateUserState= {
                    customer_id: customerData.id,
                    payment_method_id: paymentMethodId
                }
                db.collection('users').doc(uid)
                .update(updateUserState)
                .then(() => {
                    dispatch(updateUserStateAction(updateUserState))
                    if(page === "paymentform"){
                        return dispatch(push('/payment_form_card/complate/' + id))
                    }
                    if(page === "notifications"){
                        return dispatch(push('/payment_notifi_card/complate/' + id))
                    }
                    dispatch(push('/payment_complate'))
                }).catch((error) => {
                    //Delete stripe customer
                    dispatch(push('/payment_error'))
                })
            }
        }else {
            const prevPaymentMethodId = getState().users.payment_method_id
            const updatedPaymentMethod = await updatePaymentMethod(customerId, prevPaymentMethodId, paymentMethodId)

            if(!updatedPaymentMethod) {
                dispatch(push('/payment_error'))
            }else{
                const userState ={
                  payment_method_id: paymentMethodId
                }
                db.collection('users').doc(uid).update(userState)
                  .then(() => {
                      dispatch(updateUserStateAction(userState))
                      if(page === "paymentform"){
                          return dispatch(push('/payment_form_card/complate/' + id))
                      }
                      if(page === "notifications"){
                          return dispatch(push('/payment_notifi_card/complate/' + id))
                      }
                      dispatch(push("/payment_complate"))
                  }).catch(() => {
                      dispatch(push('/payment_error'))
                  })
            }
        }
    }
}

//connect

//口座登録のURLの発行
const createLink = async (uid, category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber) => {

    const profileData = {
        state:state,
        city:city,
        town:town,
        line1:line1,
        line2:line2,
        postalcode:postalcode,
        firstkana:firstkana,
        secondkana:secondkana,
        firstkanji:firstkanji,
        secondkanji:secondkanji,
        year:year,
        month:month,
        day:day,
        phonenumber:phonenumber
    }
    db.collection('users').doc(uid).collection("addresses").doc('addresses').set(profileData, {merge:true})
    const response = await fetch(BASE_URL + "/v1/accounts", {
    // const response = await fetch(TEST_URL + "/createAccount", {
        
        method:"POST",
        headers:headers,
        body:JSON.stringify({
            userId : uid,
            category:category,
            state:state,
            city:city,
            town:town,
            line1:line1,
            line2:line2,
            postalcode:postalcode,
            firstkana:firstkana,
            secondkana:secondkana,
            firstkanji:firstkanji,
            secondkanji:secondkanji,
            year:year,
            month:month,
            day:day,
            phonenumber:phonenumber
        })
    })
    
    const accountResponse = await response.json()    
    return JSON.parse(accountResponse.body)
}
// ↓
export const createAccount = (category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber) => {
    return async (dispatch, getState) => {
        const user = getState().users
        const uid = user.uid        
        const accountData = await createLink(uid, category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber)
        const url = accountData.url

        const setAccount = {
            account_id:accountData.accountId,
            account_url: url
        }

        if(accountData){
            db.collection('users').doc(uid)
              .update(setAccount)
              .then(() => {
                dispatch(updateUserAccountAction(
                    // account_id:accountData.accountId
                    setAccount
                ))                
                return
            }).catch((error) => {
                alert('お客様情報の登録に失敗しました')
                return 
            })
        }
        // return window.open(url, "_blank")
        // return url
    }
}

const startCreateLink = async (uid, category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber, id) => {
    const profileData = {
        state:state,
        city:city,
        town:town,
        line1:line1,
        line2:line2,
        postalcode:postalcode,
        firstkana:firstkana,
        secondkana:secondkana,
        firstkanji:firstkanji,
        secondkanji:secondkanji,
        year:year,
        month:month,
        day:day,
        phonenumber:phonenumber
    }
    db.collection('users').doc(uid).collection("addresses").doc('addresses').set(profileData, {merge:true})
    const response = await fetch(BASE_URL + "/v1/start_accounts", {
    // const response = await fetch(TEST_URL + "/createAccount", {
        
        method:"POST",
        headers:headers,
        body:JSON.stringify({
            userId : uid,
            category:category,
            state:state,
            city:city,
            town:town,
            line1:line1,
            line2:line2,
            postalcode:postalcode,
            firstkana:firstkana,
            secondkana:secondkana,
            firstkanji:firstkanji,
            secondkanji:secondkanji,
            year:year,
            month:month,
            day:day,
            phonenumber:phonenumber,
            id:id
        })
    })
    
    const accountResponse = await response.json()
    return JSON.parse(accountResponse.body)
}
// ↓
export const startCreateAccount = (category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber, id) => {
    return async (dispatch, getState) => {
        const user = getState().users
        const uid = user.uid        
        const accountData = await startCreateLink(uid, category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber, id)
        const url = accountData.url

        const setAccount = {
            account_id:accountData.accountId,
            account_url: url
        }        
        if(accountData){
            db.collection('users').doc(uid)
              .update(setAccount)
              .then(() => {
                dispatch(updateUserAccountAction(                    
                    setAccount
                ))                
                return
            }).catch((error) => {
                alert('お客様情報の登録に失敗しました')
                return 
            })
        }
    }
}

//urlに紐づくaccount_idが正しいかどうかの確認
export const retrieveAccount = async (accountId) => {
    if(accountId !== "" && accountId !== undefined){
        const response  = await fetch(BASE_URL + "/v1/accountRetrieve", {
            method: "POST",
            headers:headers,
            body: JSON.stringify({
                accountId: accountId
            })
        })
        const accountResponse = await response.json()
        const account = JSON.parse(accountResponse.body)
        return account  
    }
}

//登録されているstripeAccount情報を見るためのURLの発行

export const loginLink =  (account_id, uid) => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL + "/v1/loginLink", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                account_id: account_id,
            })
        })
        const createLink = await response.json()
        const link = JSON.parse(createLink.body)
        if(link){
            db.collection('users').doc(uid).set({login_url:link}, {merge:true}).then(() => {
                dispatch(updateUserLoginUrl({login_url:link}))
                return 
            }).catch((error) => {
                alert("通信に失敗しました。通信環境をご確認の上再度お試しください")
                return 
            })
            // dispatch(push('/mypage'))
        }else{
            alert('お客様情報の登録に失敗しました')
            return 
        }
    }
}
//決済処理 完成品orラフ/購入者:influencer
//運営側の手数料しかとらない
export const orderFinishedProduct = (price, artId, createrId, influencerId, accountId, paymentMethodId, customerId, uid, rough, roughId) => {
    return async (dispatch) => {
        //運営に入る手数料
        const tax = Math.round(price * 0.086)
        const response = await fetch(BASE_URL + "/v1/paymentIntent/finished_influencer", {
        
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                amount: price,
                customerId: customerId,
                paymentMethodId: paymentMethodId,
                application_fee_amount:tax,
                accountId: accountId,
                influencerId:influencerId,
                createrId:createrId
            })
        })

        const clientSecret = await response.json();
        const secret = JSON.parse(clientSecret.body)
        const timestamp = FirebaseTimestamp.now()
        const favoriteData = {
            created_at:timestamp,
            artId:artId,
            uid: uid,
            createrId: createrId,
            owner:true
        }
        if(secret.client_secret){
            db.collection("arts").doc(artId).collection('favorites').doc('favorites').set(favoriteData).then(() => {
                const ref = db.collection('users').doc(createrId).collection('notifications').doc()
                const refId = ref.id
                const refData = {
                    created_at:timestamp,
                    download:true,
                    artId:artId,
                    id:refId,
                    downloaderId:uid,
                    look:false               
                }
                const downloaddata = {
                    created_at:timestamp,
                    pay:true,
                    artId:artId,
                    createrId:createrId
                }
                db.collection('users').doc(uid).collection('downloads').doc(artId).set(downloaddata)
                db.collection('favorites').doc(artId).set(favoriteData)
                db.collection('users').doc(createrId).collection('notifications').doc(refId).set(refData).then(() => {
                    if(rough === true){
                        dispatch(push('/rough_download_complate/' + roughId))
                    }else{
                        dispatch(push('/download_complate/' + artId))
                    }
                })
            })
        }
    }
}
//決済処理 完成品/購入者:user
//手数料を運営側とインフルエンサーがもらい引いた価格がイラストレーターに入る
export const orderFinishedProductUser = (price, artId, createrId, influencerId, accountId, paymentMethodId, customerId, uid, ownerAccountId) => {
    return async (dispatch) => {
        //インフルエンサーに入る手数料
        // const influencerProfit = Math.round(price * 0.15)
        const influencerProfit = Math.round(price * 0.25)
        //ストライプ込みで運営に入る手数料
        // const platform_fee = Math.round(price * 0.1)
        //イラストレーターがもらう金額(price - price*0.15 - price*0.05 - stripe手数料)
        // const application_fee_amount = Math.round(price - influencerProfit - platform_fee)
        const application_fee_amount = Math.round(price - influencerProfit)
        // console.log(influencerProfit)
        // console.log(price)
        // console.log(application_fee_amount)
        const response = await fetch(BASE_URL + "/v1/paymentIntent/finished_user", {
        
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                amount: price,
                customerId: customerId,
                paymentMethodId: paymentMethodId,
                //イラストレーターがもらうお金
                application_fee_amount: application_fee_amount,
                //イラストレーターの口座
                accountId: accountId,
                //インフルエンサーの手数料
                influencerProfit:influencerProfit,
                //インフルエンサーのお金が入る口座
                ownerAccountId:ownerAccountId,
                createrId:createrId,
                influencerId:influencerId,
                uid:uid
            })
        })

        const charge = await response.json();
        const secret = JSON.parse(charge.body)
        if(secret.client_secret){
            const timestamp = FirebaseTimestamp.now()
            const ref =  db.collection('users').doc(uid).collection('notifications').doc()
            const refId = ref.id
            const refdata = {
                created_at:timestamp,
                id:refId,
                uid:uid,
                artId:artId,
                createrId:createrId,
                owner:false,
                download:true,
                look:false
            }
            const downloaddata = {
                created_at:timestamp,
                pay:true,
                artId:artId,
                createrId:createrId
            }
            const subfavoriteData = {
                created_at:timestamp,
                artId:artId,
                createrId:createrId,
                owner:false
            }
            const artDownloaData = {
                created_at:timestamp,
                artId:artId,
                downloaderId:uid,
                createrId:createrId,
                owner:false
            }
            const collecterRef = db.collection('users').doc(influencerId).collection('notifications').doc()
            const collecterRefId = collecterRef.id
            const collecterData = {
                created_at:timestamp,
                artId:artId,
                downloaderId:uid,
                id:collecterRefId,
                getPaid:true,
                look:false
            }
            //二次創作がどれくらい購入されているか
            db.collection('users').doc(uid).collection('downloads').doc(artId).set(downloaddata)
            //作品がユーザーにどれくらいダウンロードされたか見る
            db.collection('arts').doc(artId).collection('downloads').doc(uid).set(artDownloaData, {merge:true})
            db.collection('users').doc(uid).collection('favorites').doc(artId).set(subfavoriteData)
            //お題投稿したコレクターにお金が入りましたよ！の通知を送る
            db.collection('users').doc(influencerId).collection('notifications').doc(collecterRefId).set(collecterData)
            db.collection('users').doc(createrId).collection('notifications').doc(refId).set(refdata).then(() => {
                dispatch(push('/download_complate/' + artId))
            })
        }
    }    
    
}

//registerCardでリダイレクトURLを別のところにする