import React from "react"

const ArtImagePreview = (props) => {    

    return(
        <div style={{position:"relative"}} className="u-text-center art_header">
            <img alt="file" src={props.path} style={{background:"rgba(0, 0, 0, 0.5)"}} className='art_list_header' key={props.index} onClick={() => props.onClick()}/>
            <p style={{position:"absolute", color:"#fff"}} className="art_list_title">{props.title}</p>
            <p style={{position:"absolute", color:"#fff"}} className="art_list_username">{props.username}</p>
        </div>
    )
}

export default ArtImagePreview