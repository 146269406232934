import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider} from '@material-ui/core'
import logo from "../assets/logo.png"
import { SignedHeaderMenus } from '../components/Header'
import React, {useCallback, useState} from 'react'
import { useDispatch } from 'react-redux'
import { GrayButton, PrimaryButton, TextInput } from '../components/UIkit'
import {resetPassword} from '../reducks/users/operations'
import { Helmet } from 'react-helmet-async'
import {push} from 'connected-react-router'

const Reset = () => {
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    },[setEmail])

    const id = "normal"

    return (
        <>
            <Helmet
                title="ログインフォーム/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        <img src={logo} alt="logo" width="128px" style={{cursor:"pointer"}} onClick={() => dispatch(push('/'))} />
                        <div className="header_iconButton">
                            <SignedHeaderMenus />
                        </div>                    
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className='module-spacer--medium'/>
            <div className='center' style={{maxWidth:"360px"}}>
                <h2>パスワードをリセットする</h2>
                <div className='module-spacer--small'/>
                <TextInput
                    fullWidth={true}                    
                    label="メールアドレス"
                    multiline={false}
                    required={false}
                    rows={1}
                    value={email}
                    onChange={inputEmail}
                    type="email"
                />
                <br/>
                <div className='module-spacer--medium'/>
                <PrimaryButton
                    label="パスワードをリセットする"
                    onClick={() => dispatch(resetPassword(email, id))}
                />
                <div className='module-spacer--medium'/>
                <GrayButton
                    label="ログインフォームへ"
                    onClick={() => dispatch(push('/signin'))}
                />
            </div>
            <div className='module-spacer--medoum'/>
            <div className='module-spacer--medium'/>
        </>
    )

}

export default Reset