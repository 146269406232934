import React, {useEffect, useState, useCallback} from 'react'
import { Divider, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, IconButton, Avatar, Typography, } from '@material-ui/core'
import { db } from '../../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { getCategory } from '../../reducks/users/selectors'
import DeleteIcon from '@material-ui/icons/Delete';
import noImage from '../../assets/noImage.png'
import high from '../../assets/pottins_hightouch.png'
import sendreq from '../../assets/pottin_shomen.png'
import receivereq from '../../assets/pottins_icon1.png'
import welcome from '../../assets/pottin_notion.png'
import megaphone from '../../assets/megaphone.png'

const Notificard = (props) => {
    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const category = getCategory(selector)

    const id = props.id
    const uid = props.uid
    const time = props.time.toDate().toLocaleString()
    const artId = props.artId
    const look = props.look
    
//通知の種類
    const [download, setDownload] = useState(false)
    const [signUp, setSignUp] = useState(false)
    const [request, setRequest] = useState(false)
    const [roughSend, setRoughSend] = useState(false)
    const [getPaid, setGetPaid] = useState(false)
    
    //警報した人/された人にメッセージを飛ばす
    const [alerts, setAlerts] = useState(false)
    const [report, setReport] = useState(false)
    const [comment, setComment] = useState('')

    //requestが届き、完成品を納品したらsend:trueになるようにし、この通知を削除できるようにする
    const [send, setSend] = useState(false)
    //roughの完成版がOwnerに届きそれをダウンロードしたかのcheck trueだったら削除できる
    const [roughdownload, setRoughdownload] = useState(false)

    const [title, setTitle] = useState('')
    const [price, setPrice] = useState('')
    //DLしたのがお題主かどうかの判別
    const [owner, setOwner] = useState(false)
    const [icons, setIcons] = useState("")
    //DL時
    const [Dusername, setDUsername] = useState('')
    //RS時
    const [Rusername, setRUsername] = useState('')
    //rougSend時
    const [username, setUsername] = useState('')
    //signUp時どちらでログインしたのか
    const [loginWay, setLoginWay] = useState('')
    useEffect(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            //お題主にダウンロードされた(ユーザー側)
            if(data.download === true){
                setDownload(true)
                db.collection('arts').doc(data.artId).get().then(snapshot => {
                    const artdata = snapshot.data()                    
                    setTitle(artdata.title)
                    setPrice(artdata.price)
                    if(data.owner === false){
                        db.collection('users').doc(data.uid).get().then(snapshot => {
                            const userdata = snapshot.data()
                            setDUsername(userdata.username)
                        })
                    }else{
                        setOwner(true)
                        db.collection('users').doc(artdata.artId).get().then(snapshot => {
                            const userdata = snapshot.data()
                            setDUsername(userdata.username)
                            if(userdata.icons === ""){
                                setIcons(noImage)
                            }
                            if(userdata.icons[0].path){
                                setIcons(userdata.icons[0].path)
                            }
                        })
                    }
                })
            }
            
            //新規会員登録(双方)
            if(data.signUp === true){
                setSignUp(true)
                db.collection('users').doc(uid).get().then(snapshot => {
                    const userdata = snapshot.data()
                    setLoginWay(userdata.loginWay)
                })
            }
            //リクエストを受けた(user側)
            if(data.request === true){
                setRequest(true)
                if(data.send){
                    setSend(true)
                }else {
                    setSend(false)
                }
                db.collection("arts").doc(data.artId).get().then(snapshot => {
                    const requestdata = snapshot.data()
                    setTitle(requestdata.title)
                    db.collection('users').doc(requestdata.artId).get().then(snapshot => {
                        const userdata = snapshot.data()
                        setRUsername(userdata.username)
                    })
                })
            }
            //ラフ画の完成版が送られてきた(youtuber側)
            if(data.roughSend === true){
                setRoughSend(true)
                if(data.roughdownload === true){
                    setRoughdownload(true)
                }
                db.collection('arts').doc(data.artId).get().then(snapshot => {
                    const senddata = snapshot.data()
                    setTitle(senddata.title)
                    //作った人の名前
                    db.collection('users').doc(senddata.createrId).get().then(snapshot => {
                        const userdata = snapshot.data()
                        setUsername(userdata.username)
                    })
                })
            }
            //collectionのalertで通報を受けたユーザーに警鐘を鳴らす
            if(data.alert === true){
                setAlerts(data.alert)
                setComment(data.comment)
            }
            //collectionのalertをしてくれたユーザーにお礼のメッセージ
            if(data.report === true){
                setReport(data.report)
                setComment(data.comment)
            }

            if(data.getPaid === true){
                setGetPaid(true)      
                db.collection('arts').doc(data.artId).get().then(snapshot => {
                    const arts = snapshot.data()
                    setTitle(arts.title)
                    db.collection('users').doc(arts.createrId).get().then(snapshot => {
                        const user = snapshot.data()
                        setUsername(user.username)
                    })
                    // db.collection('requests').doc(arts.requestId).get().then(snapshot => {
                    //     const request = snapshot.data()
                    //     setTitle(request.title)
                    // })
                })
            }
        })
    },[uid, id])

    //完成品がダウンロードされたとき
    const [downloadOpen, setDownloadOpen] = useState(false)
    const downloadClick = useCallback(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).set({look:true}, {merge:true}).then(() => {
            setDownloadOpen(true)
        })
    },[id])
    const downloadClose = () => {
        setDownloadOpen(false)
    }

    const [getPaidOpen, setGetPaidOpen] = useState(false)
    const clickGetPaid = useCallback(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).set({look:true}, {merge:true}).then(() => {
            setGetPaidOpen(true)
        })
    })
    const getPaidClose = () => {
        setGetPaidOpen(false)
    }

    //signUpのページにいくときにlookをtrueにする

    const openSignUpPage = useCallback((category) => {
        db.collection('users').doc(uid).collection('notifications').doc(id).set({look:true},{merge:true}).then(() => {
            if(category === "influencer"){
                dispatch(push("/notification_influencer/welcome/" + id))
            }else{
                dispatch(push('notification/welcome/' + id))
            }
        })
    },[id])

    //requestが届き完成品を送るときにlookをtrueにする

    const openRequestPage = useCallback(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).set({look:true}, {merge:true}).then(() => {
            dispatch(push('/notification_request/' + id))
        })
    },[id])

    const openRoughSendOpen = useCallback(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).set({look:true}, {merge:true}).then(() => {
            dispatch(push('/notification_receive/' + id))
        })
    },[id])

    const [alertOpen, setAlertOpen] = useState(false)

    const clickAlert = useCallback(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).set({look:true}, {merge:true}).then(() => {
            setAlertOpen(true)
        })
    })
    const alertClose = () => {
        setAlertOpen(false)
    }

    const [reportOpen, setReportOpen] = useState(false)
    const clickReport = useCallback(() => {
        db.collection('users').doc(uid).collection("notificatons").doc(id).set({look:true}, {merge:true}).then(() => {
            setReportOpen(true)
        })
    })
    const reportClose = () => {
        setReportOpen(false)
    }
    const [downloadDeleteOpen, setDownloadDeleteOpen] = useState(false)
    const [welcomeDeleteOpen, setWelcomeDeleteOpen] = useState(false)
    const [requestOpen, setRequestOpen] = useState(false)
    const [roughSendOpen, setRoughSendOpen] = useState(false)
    const [alertDeleteOpen, setAlertDeleteOpen] = useState(false)
    const [reportDeleteOpen, setReportDeleteOpen] = useState(false)
    const [getPaidDeleteOpen, setGetPaidDeleteOpen] = useState(false)

    const downloadDeleteOpenClick = () => {
        setDownloadDeleteOpen(true)
    }
    const welcomeDeleteOpenClick = () => {
        setWelcomeDeleteOpen(true)
    }
    const requestDeleteClick = () => {
        setRequestOpen(true)
    }
    const roughSendDeleteClick = () => {
        setRoughSendOpen(true)
    }
    const alertDeleteClick = () => {
        setAlertDeleteOpen(true)
    }
    const reportDeleteClick = () => {
        setReportDeleteOpen(true)
    }
    const getPaidDeleteClick = () => {
        setGetPaidDeleteOpen(true)
    }

    
    const downloadDeleteOpenClose = () => {
        setDownloadDeleteOpen(false)
    }
    const welcomeDeleteOpenClose = () => {
        setWelcomeDeleteOpen(false)
    }
    const requestClose = () => {
        setRequestOpen(false)
    }
    const roughSendClose = () => {
        setRoughSendOpen(false)
    }
    const alertDeleteClose = () => {
        setAlertDeleteOpen(false)
    }
    const reportDeleteClose = () => {
        setReportDeleteOpen(false)
    }
    const getPaidDeteleClose = () => {
        setGetPaidDeleteOpen(false)
    }

    const deleteData = useCallback(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).delete().then(() => {
            setDownloadDeleteOpen(false)
            setWelcomeDeleteOpen(false)
            setRequestOpen(false)
            setRoughSendOpen(false)
            setAlertDeleteOpen(false)
            setReportDeleteOpen(false)
            setGetPaidDeleteOpen(false)
        })
    })

    
    return (
        <div >
            {/* インフルエンサーかユーザーに完成品がダウンロードされた */}
            {download === true && (
                <>
                   <div style={{display:"flex",textAlign:"center", margin:"10px"}}>
                       <p style={{fontSize:"10px", margin:"auto", width:"10%"}}>{time}</p>
                        <Button style={{ width:"90%", textTransform:"none",justifyContent:"start"}} onClick={() => downloadClick()}>
                            {owner === true ? (
                                <>
                                 <Avatar src={icons} style={{backgroundColor:"#eee8aa",marginRight:"5px"}}/>
                                 <p  style={ look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}>コレクターにあなたの作品がダウンロードされました!</p>
                                 {/* {price === 0 ? (
                                 ):(
                                     <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}>お題主にあなたの作品が購入されました!</p>
                                 )} */}
                                </>
                            ):(
                                <>
                                    <Avatar src={high} style={{backgroundColor:"#eee8aa",marginRight:"5px"}}/>
                                    {price === 0 ? (
                                        <p span style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}>{Dusername}さんにあなたの作品がダウンロードされました!</p>
                                    ):(
                                        <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}>{Dusername}さんにあなたの作品が購入されました!</p>
                                    )}
                                </>
                            )}
                        </Button>
                        <Dialog open={downloadOpen} onClose={downloadClose} aria-labelledby="alert-dialog-title">
                            {owner === true ? (
                                <DialogTitle id="alert-dialog-title">ダウンロードされました</DialogTitle>
                            ):(
                                <>
                                    {price === 0 ? (
                                        <DialogTitle id="alert-dialog-title">ダウンロードされました</DialogTitle>
                                    ):(
                                        <DialogTitle id="alert-dialog-title">購入されました</DialogTitle>
                                    )}
                                </>
                            )}
                            <DialogContent>
                                {owner === true ? (
                                    <DialogContentText>
                                        <p>{Dusername}さんに『{title}』がダウンロードされました！</p>
                                    </DialogContentText>
                                ):(
                                    <>
                                        {price !== 0 ? (
                                            <DialogContentText>
                                                <p>{Dusername}さんに<span style={{fontWeight:"bold"}}>{title}</span>が購入されました!</p>
                                                <p>入金口座より入金状況を確認できます</p>
                                            </DialogContentText>
                                        ):(
                                            <DialogContentText>
                                                {Dusername}さんに<span style={{fontWeight:"bold"}}>{title}</span>がダウンロードされました！                                
                                            </DialogContentText>

                                        )}
                                    </>
                                )}
                            </DialogContent>
                            {owner === true ? (
                                <DialogActions>
                                    <Button onClick={() => dispatch(push('/art_detail/' + artId))}>イラスト詳細へ</Button>
                                    <Button onClick={downloadClose}>戻る</Button>
                                </DialogActions>
                            ):(
                                <>
                                    {price !== 0 ? (
                                        <DialogActions>
                                            <Button onClick={() => dispatch(push('/payment_bank/' + uid))} style={{backgroundColor:"#26cd18", color:"#fff"}}>口座情報へ</Button>
                                            <Button onClick={() => dispatch(push("/art_detail/" + artId))}>イラスト詳細へ</Button>
                                        </DialogActions>
                                    ):(
                                        <DialogActions>
                                            <Button onClick={() => dispatch(push('/art_detail/' + artId))}>イラスト詳細へ</Button>
                                            <Button onClick={downloadClose}>戻る</Button>
                                        </DialogActions>
                                    )}
                                </>
                            )}
                        </Dialog>
                        <IconButton style={{width:"10%"}} onClick={() => downloadDeleteOpenClick()}>
                            <DeleteIcon/>
                        </IconButton>
                        <Dialog open={downloadDeleteOpen} onClose={downloadDeleteOpenClose} aria-labelledby="alert-dialog-title">
                            <DialogTitle id="alert-dialog-title">この通知を削除しますか？</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => deleteData()}>はい</Button>
                                <Button onClick={() => downloadDeleteOpenClose()}>いいえ</Button>
                            </DialogActions>
                        </Dialog>
                   </div>
                </>
            )}
            {signUp === true && (
                <div style={{display:"flex", margin:"10px"}}>
                    <p style={{fontSize:"10px", margin:"auto", width:"10%"}}>{time}</p>
                    <Button style={{width:"90%" , justifyContent:"start"}}
                      onClick={() => openSignUpPage(category)}
                    >
                        <Avatar src={welcome} style={{backgroundColor:"#ffa500", marginRight:"5px"}}/>
                        {loginWay === "twitter" ? (
                            <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}>ログインありがとうございます。</p>
                        ):(
                            <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}>登録ありがとうございます。</p>
                        )}
                    </Button>
                    <IconButton style={{width:"10%"}} onClick={() => welcomeDeleteOpenClick()}>
                        <DeleteIcon/>
                    </IconButton>
                    <Dialog open={welcomeDeleteOpen} onClose={welcomeDeleteOpenClose} aria-labelledby="alert-dialog-title">
                        <DialogTitle id="alert-dialog-title">
                            この通知を削除しますか？
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={() => deleteData()}>はい</Button>
                            <Button onClick={() => welcomeDeleteOpenClose()}>いいえ</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
            {/* お題主からラフがの正式リクエストが来た */}
            {request === true && (
                <div style={{display:"flex", margin:"10px"}}>
                    <p style={{fontSize:"10px", margin:"auto",width:"10%"}}>{time}</p>
                    <Button style={{width:"90%", textTransform:"none",justifyContent:"start"}} onClick={() => openRequestPage()}>
                        <Avatar src={sendreq} style={{backgroundColor:"#ffc0cb",marginRight:"5px"}}/>
                       <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}> {Rusername}さんから<span style={{color:"red"}}>正式リクエスト</span>が届きました!</p>
                    </Button>
                    {send === true &&(
                        <>
                            <IconButton style={{width:"10%"}} onClick={() => requestDeleteClick()}>
                                <DeleteIcon/>
                            </IconButton>
                            <Dialog open={requestOpen} onClose={requestClose}>
                                <DialogTitle>この通知を削除しますか？</DialogTitle>
                                <DialogActions>
                                    <Button onClick={() => deleteData()}>はい</Button>
                                    <Button onClick={() => requestClose()}>いいえ</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )}
                </div>
            )}
            {/* イラストレーターから完成品が届いた */}
            {roughSend === true && (
                <div style={{display:"flex", margin:"10px",justifyContent:"start"}}>
                    <p style={{fontSize:"10px", margin:"auto", width:"10%"}}>{time}</p>
                    <Button style={{width:"90%",textTransform:"none", justifyContent:"start"}} onClick={() => openRoughSendOpen()}>
                        <Avatar src={receivereq} style={{backgroundColor:"#ffc0cb",marginRight:"5px"}}/>
                        <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})} className="notifi_receive">{username}さんから依頼した作品が届きました!</p>
                    </Button>
                    {roughdownload === true && (
                        <>
                          <IconButton style={{width:"10%"}} onClick={() => roughSendDeleteClick()}>
                              <DeleteIcon/>
                          </IconButton>
                          <Dialog open={roughSendOpen} onClose={roughSendClose}>
                              <DialogTitle>この通知を削除しますか?</DialogTitle>
                              <DialogActions>
                                <Button onClick={() => deleteData()}>はい</Button>
                                <Button onClick={() => roughSendClose()}>いいえ</Button>
                              </DialogActions>
                          </Dialog>
                        </>
                    )}
                </div>
            )}
            {/* 警報されら人(加害者) */}
            {alerts === true && (
                <div style={{display:"flex", margin:"10px", justifyContent:"start"}}>
                    <p style={{fontSize:"10px",margin:"auto", width:"10%"}}>{time}</p>
                    <Button style={{width:"90%", textTransform:"none", justifyContent:"start"}} onClick={() => clickAlert()}>
                        <Avatar src={megaphone} style={{backgroundColor:"#ff0000", marginRight:"5px"}}/>
                        <p style={look === true ? ({fontWeight:"normal"}): ({fontWeight:"bild"})}>事務局よりメッセージが届いています。</p>
                    </Button>
                    <Dialog opne={alertOpen} onClose={alertClose}>
                        <DialogTitle>事務局よりメッセージが届いています。</DialogTitle>
                        <DialogContent>
                            {comment}
                        </DialogContent>
                        <DialogActions>
                            <Button style={{backgroundColor:"#808080", color:"#fff"}} onClick={alertClose}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                    <IconButton style={{width:"10%"}} onClick={() => alertDeleteClick()}>
                        <DeleteIcon/>
                    </IconButton>
                    <Dialog open={alertDeleteOpen} onClose={alertDeleteClose}>
                        <DialogTitle>この通知を削除しますか?</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => deleteData()}>はい</Button>
                            <Button onClick={() => alertDeleteClose()}>いいえ</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
            {/* 警報をした人(ありがとうございます的な内容) */}
            {report === true && (
                <div style={{display:"flex", margin:"10px", justifyContent:"start"}}>
                    <p style={{fontSize:"10px", margin:"auto", width:"10%"}}>{time}</p>
                    <Button style={{width:"90%",textTransform:"none",justifyContent:"start"}} onClick={() => clickReport()}>
                        <Avatar src={megaphone} style={{backgroundColor:"#ff0000", marginRight:"5px"}} />
                        <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})}>事務局よりメッセージが届いています。</p>                        
                    </Button>
                    <Dialog open={reportOpen} onClose={reportClose}>
                        <DialogTitle>事務局よりメッセージが届いています。</DialogTitle>
                        <DialogContent>
                            {comment}
                        </DialogContent>
                        <DialogActions>
                            <Button style={{backgroundColor:"#808080", color:"#fff"}} onClick={reportClose}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                    <IconButton style={{width:"10%"}} onClick={() => reportDeleteClick()}>
                        <DeleteIcon/>
                    </IconButton>
                    <Dialog open={reportDeleteOpen} onClose={reportDeleteClose}>
                        <DialogTitle>この通知を削除しますか?</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => deleteData()}>はい</Button>
                            <Button onClick={reportDeleteClose}>いいえ</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
            {getPaid === true && (
                <div style={{display:"flex", margin:"10px", justifyContent:"start"}}>
                    <p style={{fontSize:"10px", margin:"auto", width:"10%"}}>{time}</p>
                    <Button style={{width:"90%", textTransform:"none", justifyContent:"start"}} onClick={() => clickGetPaid()}>
                        <Avatar src={sendreq} style={{backgroundColor:"#ffc0cb", mnarginRight:"5px"}}/>
                        <p style={look === true ? ({fontWeight:"normal"}):({fontWeight:"bold"})} className="notifi_receive">ファンアートが購入されました！</p>
                    </Button>
                    <Dialog open={getPaidOpen} onClose={getPaidClose}>
                        <DialogTitle>ファンアートが購入されました</DialogTitle>
                        <DialogContent>
                            <DialogContent>
                                <Typography>
                                    <span style={{fontWeight:"bold"}}>{username}</span>さんの作品、『{title}』が購入されました。
                                </Typography>
                                <br/>
                                <Typography>
                                    販売価格の25%が著作権利用料としてあなたに入ります。
                                </Typography>
                            </DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => dispatch(push('/payment_bank/' + uid))} style={{backgroundColor:"#26cd18", color:"#fff"}}>口座情報へ</Button>
                            <Button onClick={() => dispatch(push("/art_detail/" + artId))}>イラスト詳細へ</Button>
                        </DialogActions>
                    </Dialog>
                    <IconButton style={{width:"10%"}} onClick={() => getPaidDeleteClick()}>
                        <DeleteIcon/>
                    </IconButton>
                    <Dialog open={getPaidDeleteOpen} onClose={getPaidDeteleClose}>
                        <DialogTitle>この通知を削除しますか？</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => deleteData()}>はい</Button>
                            <Button onClick={getPaidDeteleClose}>いいえ</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
            <Divider/>
        </div>
    )
}

export default Notificard