import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import { db } from '../firebase'
import { AppBar, Button, IconButton, Badge, Menu, MenuItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core'
import ToolBar from '@material-ui/core/ToolBar'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import SendIcon from '@material-ui/icons/Send';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import {signOut} from '../reducks/users/operations'
import { getCategory, getUserId } from '../reducks/users/selectors'
import noImage from '../assets/noImage.png'
import logo from "../assets/logo.png"
import {makeStyles} from '@material-ui/core/styles'

import hightouch from '../assets/pottins_hightouch.png'
import { GrayButton } from '../components/UIkit'

import GetAppIcon from '@material-ui/icons/GetApp';

const useStyle = makeStyles((theme) => ({
    img:{
        [theme.breakpoints.down('sm')]:{
            width:200,
            height:350
        },
        [theme.breakpoints.up('sm')]:{
            width:250,
            hieght:400
        }
    }
}))


const PaymentNotifiComp = () => {

    const id = window.location.pathname.split('/payment_notifi_card/complate/')[1]    
    const classes = useStyle()
    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)
    const category = getCategory(selector)

    const [icon, seticon] = useState()

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                if(data){
                    if(data.icons === ""){
                        seticon(noImage)
                    }
                    if(data.icons[0].path){
                        seticon(data.icons[0].path)
                    }
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscibe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscibe()
    },[uid])

    const [anchorEl, setAnchorEl] = useState(null)
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    } 
    const handleClose = () => {
        setAnchorEl(null)
    }
    return(
        <>
            <Helmet
            title="支払い情報設定/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                    {category === "influencer" ? (
                        <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/art_list/all/' + uid))} style={{cursor:"pointer"}}/>
                    ):(                            
                        <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/mypage/' + uid))} style={{cursor:"pointer"}}/>
                    )}
                        <div className="header_iconButton">
                            {category === "influencer" && (
                                <Button style={{color:"white",backgroundColor:"#ff7c21", marginRight:"5px", borderRadius:"12px"}} onClick={() => dispatch(push('/art_request/edit/' + uid))}>
                                    <SendIcon style={{marginRight: "6px"}}/>お題を投稿
                                </Button>
                            )}
                            <IconButton onClick={() => dispatch(push('/notifications'))} style={{paddingLeft:0}}>
                                <Badge badgeContent={notifis.length} color="error">
                                    <NotificationsNoneIcon className="header_iconImage"/>
                                </Badge>
                            </IconButton>
                        {category === "influencer" ? (
                            <>
                            <IconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true" style={{padding:0}}>
                                <img src={icon} alt="P" className="header_iconImage"/>
                            </IconButton>
                            <Menu 
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            >
                                <MenuItem onClick={() => dispatch(push('/art_list/all/' + uid))}>
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ホーム"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                <ListItemIcon>
                                    <AccountBalanceIcon/>
                                </ListItemIcon>
                                <ListItemText primary="入金口座の管理"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                <ListItemIcon>
                                    <PaymentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="支払いカードの管理"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                <ListItemIcon>
                                    <GetAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ダウンロードした作品一覧"/>
                            </MenuItem>
                                <MenuItem onClick={() => dispatch(signOut())}>
                                <ListItemIcon>
                                    <ExitToAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="サインアウト"/>
                                </MenuItem>
                            </Menu>
                        </>
                    ):(
                        <>
                            <IconButton onClick={handleClick} style={{padding:0}}>
                                <img src={icon} alt="P" className="header_iconImage"/>
                            </IconButton>
                            <Menu 
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => dispatch(push('/mypage/' + uid))}>
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ホーム"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                <ListItemIcon>
                                    <GetAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ダウンロードした作品一覧"/>
                            </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                <ListItemIcon>
                                    <AccountBalanceIcon/>
                                </ListItemIcon>
                                <ListItemText primary="入金口座の管理"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                <ListItemIcon>
                                    <PaymentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="支払いカードの管理"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(signOut())}>
                                <ListItemIcon>
                                    <ExitToAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="サインアウト"/>
                                </MenuItem>
                            </Menu>
                        </>
                        )}
                        </div>                    
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className='center' style={{maxWidth:800}}>
                <div className='module-spacer--medium'/>
                <h3>カード情報の登録に成功しました!</h3>
                <img src={hightouch} alt="hightouch" className={classes.img} style={{marginTop:"-30px"}}/>
                <div className="module-spacer--medium"/>
                <GrayButton
                  label="購入画面に戻る"
                  onClick={() => dispatch(push('/notification_receive/' + id))}
                />
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default PaymentNotifiComp