import { push } from "connected-react-router";
import {signUpAction, signInAction, signOutAction, saveProfileAction, artSignInAction,artSignUpAction} from './actions'
import { auth, db, FirebaseTimestamp, twitter} from "../../firebase/index";
import axios from "axios"
export const twitterSignUp = (category) => {
    return async (dispatch, getState) => {   
        // dispatch(push('/home_loading'))     
        // auth.signInWithRedirect(twitter).then(result => {
        //     console.log(result.user)
        // })
        return auth.signInWithPopup(twitter).then(result => {  
        // return auth.getRedirectResult().then((result) => {            
            const uid = result.user.uid
            const isNewUser = result.additionalUserInfo.isNewUser
            if(result){
                const profile = result.additionalUserInfo.profile
                const screen_name = profile.screen_name
                const preImage = profile.profile_image_url
                const image = preImage.replace('_normal.', ".")
                const email = result.user.email
                const timestamp = FirebaseTimestamp.now()
                const icondata = [{id:"1", path:image}]
                const data = {
                    uid:uid,
                    created_at:timestamp,
                    desc:profile.description,
                    username:profile.name,
                    category:category,
                    email:email,
                    url:profile.url,
                    icons:icondata,
                    loginWay:"twitter",
                    twitterUrl:`https://twitter.com/${profile.screen_name}`,
                    screen_name:screen_name,
                    isNewUser:isNewUser,
                    // account_id:account_id,
                    // payment_method_id:payment_method_id,
                    // customer_id:customer_id

                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                if(isNewUser === true){
                    db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                }
                
                db.collection('users').doc(uid).set(data, {merge:true}).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/art_list/all/' + uid))
                    }else{
                        dispatch(push('/mypage/' + uid))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(signUpAction({
                        email:data.email,
                        uid:uid,
                        isSignedIn:true,
                        username:data.username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",
                    }))
                })
            }        
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/cancelled-popup-request"){
                alert('通信が途中で遮断されました。再度お試しください')
            }
            if(errorCode === "auth/popup-blocked"){
                alert('TwitterのPupUpがブロックされています。お使いの端末"設定"よりPopUpをOnにしてください')
            }
        })
    }
}

export const signUp = (username, email, password, confirmPassword, icons, category) => {
    return async (dispatch) => {

        if(password !== confirmPassword){
            alert('パスワードが不一致です。')
            return false
        }
        if(email === "" || password === ""){
            alert('必須項目が未入力です')
            return false
        }
        return auth.createUserWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            
            
            if(user){
                const uid = user.uid
                const timestamp = FirebaseTimestamp.now()                
                const data = {
                    created_at:timestamp,
                    email:email,
                    uid:uid,
                    username:username,
                    icons:icons,
                    category:category,
                    desc:"",
                    url:"",
                    loginWay:"email",
                    twitterUrl:"",
                    account_id:"",
                    payment_method_id:"",
                    customer_id:"",
                    screen_name:"",
                    isNewUser:true
                }

                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                db.collection('users').doc(uid).set(data).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/art_list/all/' + uid))
                    }else{
                        dispatch(push("/mypage/" + uid))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(signUpAction({
                        email:data.email,
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.errorCode
            if(errorCode === "auth/email-already-in-use"){
                alert('このメールアドレスは既に使用されています。別のメールアドレスでお試しください')
                return false
            }
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認し再度お試しください')
                return false
            }
            if(errorCode === "auth/weak-password"){
                alert(`脆弱なパスワードです。6文字以上でお願いします。`)
                return false
            }
        })
    }
}

export const listenAuthState = () => {
    return async (dispatch) => {
        return auth.onAuthStateChanged( user => {
            if(user){
                const uid = user.uid

                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()

                    dispatch(signInAction({
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:data.category,
                        email:data.email,
                        desc:data.desc,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))
                    
                })
            }else{
                dispatch(push('/'))
                
            }
        })
    }
}

export const signIn = (email, password) => {
    return async (dispatch) => {
        auth.signInWithEmailAndPassword(email, password).then(result => {
            const user = result.user            
            if(user.uid === "K8R2WM0RJTXP8u4ev1uJ7J7DbSH3"){
                return dispatch(push('/management_list'))
            }
            if(user){
                const uid = user.uid
                db.collection('users').doc(uid).set({isNewUser:false}, {merge:true})
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()

                    dispatch(signInAction({
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:data.category,
                        email:data.email,
                        desc:data.desc,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))

                    if(data.category === "normal"){
                        dispatch(push('/mypage/' + uid))
                    }else{
                        dispatch(push('/art_list/all/' + uid))
                    }
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認の上再度お試しください')
                return false
            }
            if(errorCode === "auth/user-not-found"){
                alert('登録されていないメールアドレスです。')
                return false
            }
            if(errorCode === "auth/wrong-password"){
                alert('パスワードが違います。再度お試しください')
                return false
            }
        })
    }
}

export const resetPassword = (email, id) => {
    return async (dispatch) => {
        auth.sendPasswordResetEmail(email).then(() => {
            alert('入力されたメールアドレスにパスワードリセット用のメールをお送りしました')
            if(id === "normal"){
                dispatch(push('/signin'))
            }else{
                dispatch(push('/influencer/signin'))
            }
        }).catch(() => {
            alert('パスワードのリセットに失敗しました。通信完了を確認の上再度お試しください')
        })
    }
}

export const signOut = () => {
    return async(dispatch) => {
        auth.signOut().then(() => {
            dispatch(signOutAction({
            }))
            dispatch(push('/'))
        })
    }
}

export const saveProfile = (id, icons, username, desc, url, twitterUrl, email, category, loginWay) => {
    return async(dispatch, getState) => {
        const uid = getState().users.uid
        const icon = icons
        if(icon.length === 0){

            if(loginWay === "twitter"){
                const twitterData = {
                    desc:desc,
                    icons:"",
                    username:username,
                    url:url,
                    twitterUrl:twitterUrl
                }
        
                return db.collection('users').doc(uid).set(twitterData, {merge:true}).then(() => {
                    dispatch(saveProfileAction({
                        desc:twitterData.desc,
                        icons:twitterData.icons,
                        username:twitterData.username,
                        url:twitterData.url,
                        twitterUrl:twitterData.url
                    }))
                    if(category === "influencer"){
                        dispatch(push('/art_list/all/' + uid))
                    }else{
                        dispatch(push('/mypage/' + uid))
                    }
                })
            }else{
                const emailData = {
                    desc:desc,
                    icons:"",
                    username:username,
                    url:url,
                    email:email,
                    twitterUrl:`https://twitter.com/${twitterUrl}`,
                    screen_name:twitterUrl
                }
    
                return db.collection('users').doc(uid).set(emailData, {merge:true}).then(() => {
                    dispatch(saveProfileAction({
                        desc:emailData.desc,
                        icons:emailData.icons,
                        username:emailData.username,
                        url:emailData.url,
                        twitterUrl:emailData.twitterUrl
                    }))
    
                    if(category === "influencer"){
                        dispatch(push('/art_list/all/' + uid))
                    }else{
                        dispatch(push('/mypage/' + uid))
                    }
                })
            }
        }else{
            if(loginWay === "twitter"){
                
                const twitterData = {
                    desc:desc,
                    icons:icons,
                    username:username,
                    url:url,
                    twitterUrl:twitterUrl
                }
        
                return db.collection('users').doc(uid).set(twitterData, {merge:true}).then(() => {
                    dispatch(saveProfileAction({
                        desc:twitterData.desc,
                        icons:twitterData.icons,
                        username:twitterData.username,
                        url:twitterData.url,
                        twitterUrl:twitterData.url
                    }))
                    if(category === "influencer"){
                        dispatch(push('/art_list/all/' + uid))
                    }else{
                        dispatch(push('/mypage/' + uid))
                    }
                })
            }else{
                const emailData = {
                    desc:desc,
                    icons:icons,
                    username:username,
                    url:url,
                    email:email,
                    twitterUrl:`https://twitter.com/${twitterUrl}`,
                    screen_name:twitterUrl
                }

                return db.collection('users').doc(uid).set(emailData, {merge:true}).then(() => {
                    dispatch(saveProfileAction({
                        desc:emailData.desc,
                        icons:emailData.icons,
                        username:emailData.username,
                        url:emailData.url,
                        twitterUrl:emailData.twitterUrl
                    }))

                    if(category === "influencer"){
                        dispatch(push('/art_list/all/' + uid))
                    }else{
                        dispatch(push('/mypage/' + uid))
                    }
                })
            }
        } 
    }
}

export const searchAddress = (postalcode) => {
    return async() => {
        const res = await axios.get("https://zipcloud.ibsnet.co.jp/api/search", {params:{zipcode:postalcode}})
        const result = res.data.results[0]        
        return result
    }
}

export const saveAddress = (category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber) => {
    return async (dispatch, getState) => {
        const uid = getState().users.uid
        const saveAddress = {
            state:state,
            city:city,
            town:town,
            line1:line1,
            line2:line2,
            postalcode:postalcode,
            firstkana:firstkana,
            secondkana:secondkana,
            firstkanji:firstkanji,
            secondkanji:secondkanji,
            year:year,
            month:month,
            day:day,
            phonenumber:phonenumber
        }
        db.collection('users').doc(uid).collection('addresses').doc('addresses').set(saveAddress, {merge:true}).then(() => {
            if(category === "influencer"){
                dispatch(push('/art_list/all/' + uid))
            }else{
                dispatch(push('/mypage/' + uid))
            }
        })
    }
}

//ログインしてないユーザーが登録した際に今いたページに戻るためのoperation

export const artTwitterSignUp = (category, id) => {
    return async (dispatch, getState) => {
        // dispatch(push('/art_loading/' + id))
        return auth.signInWithPopup(twitter).then(result => {
            if(result){                
                const profile = result.additionalUserInfo.profile
                const screen_name = profile.screen_name
                const preImage = profile.profile_image_url
                const image = preImage.replace('_normal.', ".")
                const email = result.user.email
                const timestamp = FirebaseTimestamp.now()
                const uid = result.user.uid
                const isNewUser = result.additionalUserInfo.isNewUser
                const icondata = [{id:"1", path:image}]
                const data = {
                    uid:uid,
                    created_at:timestamp,
                    desc:profile.description,
                    username:profile.name,
                    category:category,
                    email:email,
                    url:profile.url,
                    icons:icondata,
                    loginWay:"twitter",
                    twitterUrl:`https://twitter.com/${profile.screen_name}`, 
                    screen_name:screen_name,
                    isNewUser:isNewUser
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                
                
                if(isNewUser === true){
                    db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                }
                
                db.collection('users').doc(uid).set(data, {merge:true}).then(() => {
                    dispatch(push('/art_list/all/' + id))                    
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        uid:uid,
                        isSignedIn:true,
                        username:data.username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",
                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/cancelled-popup-request"){
                alert('通信が途中で遮断されました。再度お試しください')
            }
            if(errorCode === "auth/popup-blocked"){
                alert('TwitterのPupUpがブロックされています。お使いの端末"設定"よりPopUpをOnにしてください')
            }
        })
    }
}

export const artSignUp = (username, email, password, confirmPassword, icons, category, id) => {
    return async (dispatch) => {

        if(password !== confirmPassword){
            alert("パスワードが不一致です。")
            return false
        }
        if(email === "" || password === ""){
            alert('必須項目が未入力です')
            return false
        }
        return auth.createUserWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            
            
            if(user){
                const uid = user.uid
                const timestamp = FirebaseTimestamp.now()
                
                
                const data = {
                    created_at:timestamp,
                    email:email,
                    uid:uid,
                    username:username,
                    icons:icons,
                    category:category,
                    desc:"",
                    url:"",
                    loginWay:"email",
                    twitterUrl:"",
                    account_id:"",
                    payment_method_id:"",
                    customer_id:"",
                    screen_name:"",
                    isNewUser:true,
                }

                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                db.collection('users').doc(uid).set(data).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/art_list/all/' + id))
                    }else{
                        dispatch(push("/art_list/all/" + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        uid:uid,
                        isSignedIn:true,
                        username:username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/email-already-in-use"){
                alert('このメールアドレスはすでに使用されています。別のメールアドレスでお試しください')
                return false
            }
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式を確認し再度お試しください')
                return false
            }
            if(errorCode === "auth/weak-password"){
                alert('脆弱なパスワードです。6文字以上でお願いします。')
                return false
            }
        })
    }
}

export const artSignIn = (email, password, id) => {
    return async (dispatch) => {
        auth.signInWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            if(user){
                const uid = user.uid
                db.collection('users').doc(uid).set({isNewUser:false}, {merge:true})
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()

                    dispatch(artSignInAction({
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:data.category,
                        email:data.email,
                        desc:data.desc,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))

                    if(data.category === "noraml"){
                        dispatch(push('/art_list/all/' + id))
                    }else{
                        dispatch(push('/art_list/all/' + id))
                    }
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式を確認し再度お試しください')
                return false
            }
            if(errorCode === "auth/user-not-found"){
                alert('登録されていないメールアドレスです。')
                return false
            }
            if(errorCode === "auth/wrong-password"){
                alert('パスワードが違います。再度お試しください')
                return false
            }
        })
    }
}

export const artResetPassword = (email, category, id) => {
    return async (dispatch) => {
        auth.sendPasswordResetEmail(email).then(() => {
            alert('入力されたメールアドレスにパスワードリセット用のメールをお送りしました')
            if(category === "normal"){
                dispatch(push('/art_signin/' + id))
            }else{
                dispatch(push('/art_influencer/signin/' + id))
            }
        }).catch(() => {
            alert('パスワードのリセットに失敗しました。通信完了を確認の上再度お試しください')
        })
    }
}

//art_detailからログインするとき

export const artDetailTwitterSignUp = (category, id) => {
    return async (dispatch, getState) => {
        // dispatch(push('/art_detail_loading/' + id))
        return auth.signInWithPopup(twitter).then(result => {
            if(result){
                const isNewUser = result.additionalUserInfo.isNewUser
                const profile = result.additionalUserInfo.profile
                const screen_name = profile.screen_name
                const preImage = profile.profile_image_url
                const image = preImage.replace('_normal.', ".")
                const email = result.user.email
                const timestamp = FirebaseTimestamp.now()
                const uid = result.user.uid
                const icondata = [{id:"1", path:image}]
                const data = {
                    uid:uid,
                    created_at:timestamp,
                    desc:profile.description,
                    username:profile.name,
                    category:category,
                    email:email,
                    url:profile.url,
                    icons:icondata,
                    loginWay:"twitter",
                    twitterUrl:`https://twitter.com/${profile.screen_name}`,
                    screen_name:screen_name,
                    isNewUser:isNewUser
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                if(isNewUser === true){
                    db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                }
                db.collection('users').doc(uid).set(data, {merge:true}).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/art_detail/' + id))
                    }else{
                        dispatch(push('/art_detail/' + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        uid:uid,
                        isSignedIn:true,
                        username:data.username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/cancelled-popup-request"){
                alert('通信が途中で遮断されました。再度お試しください')
            }
            if(errorCode === "auth/popup-blocked"){
                alert('TwitterのPupUpがブロックされています。お使いの端末"設定"よりPopUpをOnにしてください')
            }
        })
    }
}

export const artDetailSignUp = (username, email, password, confirmPassword, icons, category, id) => {
    return async (dispatch) => {
        return auth.createUserWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            
            
            if(user){
                const uid = user.uid
                const timestamp = FirebaseTimestamp.now()
                
                
                const data = {
                    created_at:timestamp,
                    email:email,
                    uid:uid,
                    username:username,
                    icons:icons,
                    category:category,
                    desc:"",
                    url:"",
                    loginWay:"email",
                    twitterUrl:"",
                    account_id:"",
                    payment_method_id:"",
                    customer_id:"",
                    screen_name:"",
                    isNewUser:true
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)

                db.collection('users').doc(uid).set(data).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/art_detail/' + id))
                    }else{
                        dispatch(push("/art_detail/" + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        isSignedIn:true,
                        uid:uid,
                        username:username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",
                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.errorCode
            if(errorCode === "auth/email-already-in-use"){
                alert('このメールアドレスは既に使用されています。別のメールアドレスでお試しください')
                return false
            }
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認し再度お試しください')
                return false
            }
            if(errorCode === "auth/weak-password"){
                alert(`脆弱なパスワードです。6文字以上でお願いします。`)
                return false
            }
        })
    }
}

export const artDetailSignIn = (email, password, id) => {
    return async (dispatch) => {
        auth.signInWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            if(user){
                const uid = user.uid
                db.collection('users').doc(uid).set({isNewUser:false}, {merge:true})
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()

                    dispatch(artSignInAction({
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:data.category,
                        email:data.email,
                        desc:data.desc,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                    }))

                    if(data.category === "noraml"){
                        dispatch(push('/art_detail/' + id))
                    }else{
                        dispatch(push('/art_detail/' + id))
                    }
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認の上再度お試しください')
                return false
            }
            if(errorCode === "auth/user-not-found"){
                alert('登録されていないメールアドレスです。')
                return false
            }
            if(errorCode === "auth/wrong-password"){
                alert('パスワードが違います。再度お試しください')
                return false
            }
        })
    }
}

export const artDetailResetPassword = (email, category, id) => {
    return async (dispatch) => {
        auth.sendPasswordResetEmail(email).then(() => {
            alert('入力されたメールアドレスにパスワードリセット用のメールをお送りしました')
            if(category === "normal"){
                dispatch(push('/art_detail_signin/' + id))
            }else{
                dispatch(push('/art_detail_influencer/signin/' + id))
            }
        }).catch(() => {
            alert('パスワードのリセットに失敗しました。通信完了を確認の上再度お試しください')
        })
    }
}

//art_requestの一覧を見ているときに登録する際のフォーム

export const artRequestTwitterSignUp = (category, id) => {
    return async (dispatch, getState) => {
        // dispatch(push('/art_request_loading/' + id))
        return auth.signInWithPopup(twitter).then(result => {
            if(result){
                const isNewUser = result.additionalUserInfo.isNewUser

                const profile = result.additionalUserInfo.profile
                const screen_name = profile.screen_name
                const preImage = profile.profile_image_url
                const image = preImage.replace('_normal.', ".")
                const email = result.user.email
                const timestamp = FirebaseTimestamp.now()
                const uid = result.user.uid
                const icondata = [{id:"1", path:image}]
                const data = {
                    uid:uid,
                    created_at:timestamp,
                    desc:profile.description,
                    username:profile.name,
                    category:category,
                    email:email,
                    url:profile.url,
                    icons:icondata,
                    loginWay:"twitter",
                    twitterUrl:`https://twitter.com/${profile.screen_name}`,
                    screen_name:screen_name,
                    isNewUser:isNewUser
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                if(isNewUser === true){
                    db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                }
                db.collection('users').doc(uid).set(data, {merge:true}).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/i_art_request_bank/' + id))
                    }else{
                        dispatch(push('/i_art_request_bank/' + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        uid:uid,
                        isSignedIn:true,
                        username:data.username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/cancelled-popup-request"){
                alert('通信が途中で遮断されました。再度お試しください')
            }
            if(errorCode === "auth/popup-blocked"){
                alert('TwitterのPupUpがブロックされています。お使いの端末"設定"よりPopUpをOnにしてください')
            }
        })
    }
}

export const artRequestSignUp = (username, email, password, confirmPassword, icons, category, id) => {
    return async (dispatch) => {
        return auth.createUserWithEmailAndPassword(email, password).then(result => {
            const user = result.user                        
            if(user){
                const uid = user.uid
                const timestamp = FirebaseTimestamp.now()
                
                
                const data = {
                    created_at:timestamp,
                    email:email,
                    uid:uid,
                    username:username,
                    icons:icons,
                    category:category,
                    desc:"",
                    url:"",
                    loginWay:"email",
                    twitterUrl:"",
                    account_id:"",
                    payment_method_id:"",
                    customer_id:"",
                    screen_name:"",
                    isNewUser:true
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)

                db.collection('users').doc(uid).set(data).then(() => {                    
                    dispatch(push('/i_art_request_bank/' + id))
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        isSignedIn:true,
                        uid:uid,
                        username:username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",
                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.errorCode
            if(errorCode === "auth/email-already-in-use"){
                alert('このメールアドレスは既に使用されています。別のメールアドレスでお試しください')
                return false
            }
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認し再度お試しください')
                return false
            }
            if(errorCode === "auth/weak-password"){
                alert(`脆弱なパスワードです。6文字以上でお願いします。`)
                return false
            }
        })
    }
}

export const artRequestSignIn = (email, password, id) => {
    return async (dispatch) => {
        auth.signInWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            if(user){
                const uid = user.uid
                db.collection('users').doc(uid).set({isNewUser:false}, {merge:true})
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()

                    dispatch(artSignInAction({
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:data.category,
                        email:data.email,
                        desc:data.desc,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                    }))                   
                    dispatch(push('/i_art_request_bank/' + id))
                    
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認の上再度お試しください')
                return false
            }
            if(errorCode === "auth/user-not-found"){
                alert('登録されていないメールアドレスです。')
                return false
            }
            if(errorCode === "auth/wrong-password"){
                alert('パスワードが違います。再度お試しください')
                return false
            }
        })
    }
}

export const artRequestResetPassword = (email, category, id) => {
    return async (dispatch) => {
        auth.sendPasswordResetEmail(email).then(() => {
            alert('入力されたメールアドレスにパスワードリセット用のメールをお送りしました')
            if(category === "normal"){
                dispatch(push('/art_request_signin/' + id))
            }else{
                dispatch(push('/art_request_influencer/signin/' + id))
            }
        }).catch(() => {
            alert('パスワードのリセットに失敗しました。通信完了を確認の上再度お試しください')
        })
    }
}
//mypageに来た人が登録するページ

export const mypageTwitterSignUp = (category, id) => {
    return async (dispatch, getState) => {
        // dispatch(push('/mypage_loading/' + id))
        return auth.signInWithPopup(twitter).then(result => {
            if(result){
                const isNewUser = result.additionalUserInfo.isNewUser
                const profile = result.additionalUserInfo.profile
                const screen_name = profile.screen_name
                const preImage = profile.profile_image_url
                const image = preImage.replace('_normal.', ".")
                const email = result.user.email
                const timestamp = FirebaseTimestamp.now()
                const uid = result.user.uid
                const icondata = [{id:"1", path:image}]
                const data = {
                    uid:uid,
                    created_at:timestamp,
                    desc:profile.description,
                    username:profile.name,
                    category:category,
                    email:email,
                    url:profile.url,
                    icons:icondata,
                    loginWay:"twitter",
                    twitterUrl:`https://twitter.com/${profile.screen_name}`,
                    screen_name:screen_name,
                    isNewUser:isNewUser
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                if(isNewUser === true){
                    db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                }
                db.collection('users').doc(uid).set(data, {merge:true}).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/mypage/' + id))
                    }else{
                        dispatch(push('/mypage/' + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        uid:uid,
                        isSignedIn:true,
                        username:data.username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/cancelled-popup-request"){
                alert('通信が途中で遮断されました。再度お試しください')
            }
            if(errorCode === "auth/popup-blocked"){
                alert('TwitterのPupUpがブロックされています。お使いの端末"設定"よりPopUpをOnにしてください')
            }
        })
    }
}

export const mypageSignUp = (username, email, password, confirmPassword, icons, category, id) => {
    return async (dispatch) => {
        return auth.createUserWithEmailAndPassword(email, password).then(result => {
            const user = result.user                        
            if(user){
                const uid = user.uid
                const timestamp = FirebaseTimestamp.now()
                
                
                const data = {
                    created_at:timestamp,
                    email:email,
                    uid:uid,
                    username:username,
                    icons:icons,
                    category:category,
                    desc:"",
                    url:"",
                    loginWay:"email",
                    twitterUrl:"",
                    account_id:"",
                    payment_method_id:"",
                    customer_id:"",
                    screen_name:"",
                    isNewUser:true
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)

                db.collection('users').doc(uid).set(data).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/mypage/' + id))
                    }else{
                        dispatch(push("/mypage/" + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        isSignedIn:true,
                        uid:uid,
                        username:username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",
                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.errorCode
            if(errorCode === "auth/email-already-in-use"){
                alert('このメールアドレスは既に使用されています。別のメールアドレスでお試しください')
                return false
            }
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認し再度お試しください')
                return false
            }
            if(errorCode === "auth/weak-password"){
                alert(`脆弱なパスワードです。6文字以上でお願いします。`)
                return false
            }
        })
    }
}

export const mypageSignIn = (email, password, id) => {
    return async (dispatch) => {
        auth.signInWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            if(user){
                const uid = user.uid
                db.collection('users').doc(uid).set({isNewUser:false}, {merge:true})
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()

                    dispatch(artSignInAction({
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:data.category,
                        email:data.email,
                        desc:data.desc,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                    }))

                    if(data.category === "noraml"){
                        dispatch(push('/mypage/' + id))
                    }else{
                        dispatch(push('/mypage/' + id))
                    }
                })
            }
        })
    }
}

export const mypageResetPassword = (email, category, id) => {
    return async (dispatch) => {
        auth.sendPasswordResetEmail(email).then(() => {
            alert('入力されたメールアドレスにパスワードリセット用のメールをお送りしました')
            if(category === "normal"){
                dispatch(push('/mypage_signin/' + id))
            }else{
                dispatch(push('/mypage_influencer/signin/' + id))
            }
        }).catch(() => {
            alert('パスワードのリセットに失敗しました。通信完了を確認の上再度お試しください')
        })
    }
}
//mypageのdetailに来た人が登録するページ

export const mypageDetailTwitterSignUp = (category, id) => {
    return async (dispatch, getState) => {
        // dispatch(push('/mypage_detail_loading/' + id))
        return auth.signInWithPopup(twitter).then(result => {
            if(result){                
                const isNewUser = result.additionalUserInfo.isNewUser
                const profile = result.additionalUserInfo.profile  
                const screen_name = profile.screen_name              
                const preImage = profile.profile_image_url
                const image = preImage.replace('_normal.', ".")
                const email = result.user.email
                const timestamp = FirebaseTimestamp.now()
                const uid = result.user.uid
                const icondata = [{id:"1", path:image}]
                const data = {
                    uid:uid,
                    created_at:timestamp,
                    desc:profile.description,
                    username:profile.name,
                    category:category,
                    email:email,
                    url:profile.url,
                    icons:icondata,
                    loginWay:"twitter",
                    twitterUrl:`https://twitter.com/${profile.screen_name}`,
                    screen_name:screen_name,
                    isNewUser:isNewUser
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                if(isNewUser === true){
                    db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)
                }
                db.collection('users').doc(uid).set(data, {merge:true}).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/detail/' + id))
                    }else{
                        dispatch(push('/detail/' + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        uid:uid,
                        isSignedIn:true,
                        username:data.username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",

                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/cancelled-popup-request"){
                alert('通信が途中で遮断されました。再度お試しください')
            }
            if(errorCode === "auth/popup-blocked"){
                alert('TwitterのPupUpがブロックされています。お使いの端末"設定"よりPopUpをOnにしてください')
            }
        })
    }
}

export const mypageDetailSignUp = (username, email, password, confirmPassword, icons, category, id) => {
    return async (dispatch) => {
        return auth.createUserWithEmailAndPassword(email, password).then(result => {
            const user = result.user                        
            if(user){
                const uid = user.uid
                const timestamp = FirebaseTimestamp.now()                                
                const data = {
                    created_at:timestamp,
                    email:email,
                    uid:uid,
                    username:username,
                    icons:icons,
                    category:category,
                    desc:"",
                    url:"",
                    loginWay:"email",
                    twitterUrl:"",
                    account_id:"",
                    payment_method_id:"",
                    customer_id:"",
                    screen_name:"",
                    isNewUser:true
                }
                const notifiref = db.collection('users').doc(uid).collection("notifications").doc()
                const refId = notifiref.id
                const notifiData = {
                    created_at:timestamp,
                    signUp:true,
                    id:refId,
                    look:false
                }
                db.collection('users').doc(uid).collection('notifications').doc(refId).set(notifiData)

                db.collection('users').doc(uid).set(data).then(() => {
                    if(category === "influencer"){
                        dispatch(push('/detail/' + id))
                    }else{
                        dispatch(push("/detail/" + id))
                    }
                })
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()
                    dispatch(artSignUpAction({
                        email:data.email,
                        isSignedIn:true,
                        uid:uid,
                        username:username,
                        icons:data.icons,
                        category:category,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                        account_id:(data.account_id) ? data.account_id : "",
                    }))
                })
            }
        }).catch((error) => {
            const errorCode = error.errorCode
            if(errorCode === "auth/email-already-in-use"){
                alert('このメールアドレスは既に使用されています。別のメールアドレスでお試しください')
                return false
            }
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認し再度お試しください')
                return false
            }
            if(errorCode === "auth/weak-password"){
                alert(`脆弱なパスワードです。6文字以上でお願いします。`)
                return false
            }
        })
    }
}

export const mypageDetailSignIn = (email, password, id) => {
    return async (dispatch) => {
        auth.signInWithEmailAndPassword(email, password).then(result => {
            const user = result.user
            if(user){
                const uid = user.uid
                db.collection('users').doc(uid).set({isNewUser:false}, {merge:true})
                db.collection('users').doc(uid).get().then(snapshot => {
                    const data = snapshot.data()

                    dispatch(artSignInAction({
                        isSignedIn:true,
                        uid:uid,
                        username:data.username,
                        icons:data.icons,
                        category:data.category,
                        email:data.email,
                        desc:data.desc,
                        customer_id: (data.customer_id) ? data.customer_id : "",
                        payment_method_id : (data.payment_method_id) ? data.payment_method_id : "",
                        connectLink:(data.connectLink) ? data.connectLink : "",
                    }))

                    if(data.category === "noraml"){
                        dispatch(push('/detail/' + id))
                    }else{
                        dispatch(push('/detail/' + id))
                    }
                })
            }
        }).catch((error) => {
            const errorCode = error.code
            if(errorCode === "auth/invalid-email"){
                alert('無効なメールアドレスです。形式をご確認の上再度お試しください')
                return false
            }
            if(errorCode === "auth/user-not-found"){
                alert('登録されていないメールアドレスです。')
                return false
            }
            if(errorCode === "auth/wrong-password"){
                alert('パスワードが違います。再度お試しください')
                return false
            }
        })
    }
}

export const mypageDetailResetPassword = (email, category, id) => {
    return async (dispatch) => {
        auth.sendPasswordResetEmail(email).then(() => {
            alert('入力されたメールアドレスにパスワードリセット用のメールをお送りしました')
            if(category === "normal"){
                dispatch(push('/mypage_detail_signin/' + id))
            }else{
                dispatch(push('/mypage_detail_influencer/signin/' + id))
            }
        }).catch(() => {
            alert('パスワードのリセットに失敗しました。通信完了を確認の上再度お試しください')
        })
    }
}