import React from 'react'
import Router from './Router'
import "./assets/style.css"
import {Footer} from "./components/Footer"


const App = () => {
    return (
        <>          
          <Router/>
          <Footer/>
        </>
    )
}

export default App