const initialState = {
    users:{
        isSignedIn: false,
        uid:"",
        username:"",
        email:"",
        icons:"",
        desc:"",
        category:"",
        customer_id:"",
        payment_method_id:"",
        account_id:"",        
    }
}

export default initialState