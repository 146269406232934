import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import { db } from '../firebase'
import { AppBar, Button, IconButton, Badge, Menu, MenuItem, ListItemText, ListItemIcon, Divider, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core'
import ToolBar from '@material-ui/core/ToolBar'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import SendIcon from '@material-ui/icons/Send';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import {signOut} from '../reducks/users/operations'
import { getUserId } from '../reducks/users/selectors'
import noImage from '../assets/noImage.png'
import logo from "../assets/logo.png"
import {makeStyles} from '@material-ui/core/styles'
import Pottin from '../assets/pottins_icon1.png'
import { TwitterButton } from '../components/UIkit'
import GetAppIcon from '@material-ui/icons/GetApp';
// import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const useStyle = makeStyles((theme) => ({
    img:{
        [theme.breakpoints.down('sm')]:{
            width:300,
            height:300
        },
        [theme.breakpoints.up('sm')]:{
            width:400,
            hieght:400
        }
    }
}))

const ArtDeliveryComplate = () => {

    const id = window.location.pathname.split('/art_delivery_complate/')[1]
    const shareURL = "https://pallet.fun/art_detail/" + id
    const classes = useStyle()
    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const [icon, seticon] = useState()
    const [category, setCategory] = useState('')
    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data){
                    if(data.icons === ""){
                        seticon(noImage)
                    }
                    if(data.icons[0].path){
                        seticon(data.icons[0].path)
                    }
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscibe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscibe()
    },[uid])

    const [anchorEl, setAnchorEl] = useState(null)
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    } 
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [title, setTitle] = useState('')
    const [username, setUsername] = useState('')
    const [screen_name, setScreenName] = useState('')
    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const d = snapshot.data()
            setTitle(d.title)
            db.collection('users').doc(d.artId).get().then(snapshot => {
                const data = snapshot.data()
                setUsername(data.username)
                setScreenName(data.screen_name)
            })
        })
    },[id])

    const [shareOpen, setShareOpen] = useState(false)
    const shareClick = () => {
        setShareOpen(true)
    }
    const shareClose = () => {
        setShareOpen(false)
    }
    const [copyok, setCopyOk] = useState(false)

    const copyTextClipboard = () => {
        navigator.clipboard.writeText(shareURL).then(() => {
            setCopyOk(true)
        }).catch(() => {
            alert("クリップボードへのコピーが失敗しました。通信環境を確認の上再度お試しください。")
        })
    }
    return (
        <>
            <Helmet
            title="リクエスト完了/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                    {category === "influencer" ? (
                        <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/art_list/all/' + uid))} style={{cursor:"pointer"}}/>
                    ):(                            
                        <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/mypage/' + uid))} style={{cursor:"pointer"}}/>
                    )}
                        <div className="header_iconButton">
                            {category === "influencer" && (
                                <Button style={{color:"white",backgroundColor:"#ff7c21", marginRight:"5px"}} onClick={() => dispatch(push('/art_request/edit/' + uid))}>
                                    <SendIcon style={{marginRight: "6px"}}/>リクエスト
                                </Button>
                            )}
                            <IconButton onClick={() => dispatch(push('/notifications'))} style={{paddingLeft:0}}>
                                <Badge badgeContent={notifis.length} color="error">
                                    <NotificationsNoneIcon className="header_iconImage"/>
                                </Badge>
                            </IconButton>
                        {category === "influencer" ? (
                            <>
                            <IconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true" style={{padding:0}}>
                                <img src={icon} alt="P" className="header_iconImage"/>
                            </IconButton>
                            <Menu 
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            >
                                <MenuItem onClick={() => dispatch(push('/art_list/all/' + uid))}>
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ホーム"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                <ListItemIcon>
                                    <AccountBalanceIcon/>
                                </ListItemIcon>
                                <ListItemText primary="入金口座の管理"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                <ListItemIcon>
                                    <PaymentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="支払いカードの管理"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                <ListItemIcon>
                                    <GetAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ダウンロードした作品一覧"/>
                            </MenuItem>
                                <MenuItem onClick={() => dispatch(signOut())}>
                                <ListItemIcon>
                                    <ExitToAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="サインアウト"/>
                                </MenuItem>
                            </Menu>
                            </>
                            ):(
                            <>
                            <IconButton onClick={handleClick} style={{padding:0}}>
                                <img src={icon} alt="P" className="header_iconImage"/>
                            </IconButton>
                            <Menu 
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                                                {/* <MenuItem onClick={() => dispatch(push("/influencer_list"))}>
                                    <ListItemIcon>
                                        <SupervisedUserCircleIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="登録インフルエンサー一覧"/>
                                </MenuItem> */}
                                <MenuItem onClick={() => dispatch(push('/mypage/' + uid))}>
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ホーム"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                <ListItemIcon>
                                    <GetAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ダウンロードした作品一覧"/>
                            </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                <ListItemIcon>
                                    <AccountBalanceIcon/>
                                </ListItemIcon>
                                <ListItemText primary="入金口座登録"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                <ListItemIcon>
                                    <PaymentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="支払いカード登録"/>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(signOut())}>
                                <ListItemIcon>
                                    <ExitToAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="サインアウト"/>
                                </MenuItem>
                            </Menu>
                            </>
                        )}
                        </div>                    
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div className="center" style={{maxWidth:800}}>
                <h3>作品制作お疲れ様でした!</h3>
                <img src={Pottin} alt="送信完了" className={classes.img}/>
                <div className="module-spacer--small"/>
                <h3>完成品をお題主にお送りしました</h3>
                <div className='module-spacer--small'/>
                
                    <TwitterButton
                        label="共有する"
                        onClick={shareClick}
                    />
                    <Dialog open={shareOpen} onClose={shareClose}>
                        <DialogTitle>SNSに共有しよう!</DialogTitle>
                        {copyok === true && (
                            <DialogContent>
                                <Typography>
                                    クリップボードにリンクをコピーしました!
                                </Typography>
                            </DialogContent>
                        )}
                        <DialogContent>
                            ※作品詳細ページのリンクを共有/コピーできます。
                        </DialogContent>
                        <DialogActions>
                        <a href={`http://twitter.com/share?url=${shareURL}&text=${username}さんへの作品完成しました!&hashtags=Pallet,${title}&screen_name=${screen_name}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                            <Button style={{backgroundColor:"#1da1f2", color:"#fff", textTransform:"none"}}>Twitterへシェア</Button>
                        </a>
                        <Button style={{backgroundColor:"#808080", color:"#fff"}} onClick={() => copyTextClipboard()}>リンクコピー</Button>
                        </DialogActions>
                    </Dialog>
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default ArtDeliveryComplate