import React, {useState, useEffect, useCallback} from 'react'
import { Helmet } from 'react-helmet-async'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'
import noImage from '../assets/noImage.png'
import { getUserId, getAccountUrl } from '../reducks/users/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '../firebase'
import { push } from 'connected-react-router'
import {PrimaryButton, GrayButton} from '../components/UIkit';
import notion from '../assets/pottin_shomen.png'

import { retrieveAccount, startCreateAccount } from '../reducks/stripes/operations';
import headphone from '../assets/headphone.png'
import { ClosableDrawer } from '../components/Header';
import {makeStyles} from '@material-ui/core/styles'
import axios from 'axios'
import {CircularProgress} from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    flex:{
        display:"flex",
        // marginTop:"-10px",
        // marginBottom:"10px"
    },
    left:{    
        textAlign:"left",
        
    },
    rightSide:{
        marginLeft:"5px"
    },
    editPraceholder: {
        fontSize:"12px",
        color:"#808080",
        textAlign:"left",
        [theme.breakpoints.down("sm")]:{
            fontSize:"8px"
        }
    },
    edit_address_state:{        
        display:"flex"        
    },
    edit_address_city:{
        [theme.breakpoints.down("sm")]:{
            marginLeft:"0px"
        },
        [theme.breakpoints.up("sm")]:{
            marginLeft:"5px"
        }
    }
}))
const IllustArtRequestbank = () => {        
    const classes = useStyles()
    const id = window.location.pathname.split('/i_art_request_bank/')[1]    

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)    

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')
    const [isNewUser, setIsNewUser] = useState(true)
    const [submit, setSubmit] = useState(false)
    const [circle, setCircle] = useState('') 
    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()            
            setCategory(data.category)
            setIsNewUser(data.isNewUser)

            if(data.account_id && data.account_id !== ""){
                (async () => {
                    const accountData = await retrieveAccount(data.account_id)                    
                    if(accountData){
                        setSubmit(accountData.details_submitted)
                        setCircle(true)
                    }else{
                        setCircle(false)
                    }
                })()
            }else{
                setCircle(false)
            }
            if(data.icons === ""){
                setIcon(noImage)
            }
            if(data.icons[0].path){
                setIcon(data.icons[0].path)
            }
        })
    },[uid])
    

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])
    //個人情報登録
    const [state, setState] = useState('')
    const [city, setCity] = useState("")
    const [town, setTown] = useState('')
    const [line1, setLine1] = useState('')
    const [line2, setLine2] = useState('')
    const[postalcode, setPostalcode] = useState('')
    const [firstkana, setFirstkana] = useState('')
    const [secondkana, setSecondkana] = useState('')
    const [firstkanji, setFirstkanji] = useState('')
    const [secondkanji, setSecondKanji] = useState('')
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [day, setDay] = useState('')
    const [phonenumber, setPhonenumber] = useState('')

    
    const inputState = useCallback((e) => {
        setState(e.target.value)
    },[setState])
    const inputCity = useCallback((e) => {
        setCity(e.target.value)
    },[setCity])
    const inputTown = useCallback((e) => {
        setTown(e.target.value)
    },[setTown])
    const inputLine1 = useCallback((e) => {
        setLine1(e.target.value)
    },[setLine1])
    const inputLine2 = useCallback((e) => {
        setLine2(e.target.value)
    },[setLine2])
    const inputPostalcode = useCallback((e) => {
        setPostalcode(e.target.value)
    },[setPostalcode])
    const inputFirstkana = useCallback((e) => {
        setFirstkana(e.target.value)
    },[setFirstkana])
    const inputFirstkanji = useCallback((e) => {
        setFirstkanji(e.target.value)
    },[setFirstkanji])
    const inputSecondkana = useCallback((e) => {
        setSecondkana(e.target.value)
    },[setFirstkana])
    const inputSecondkanji = useCallback((e) => {
        setSecondKanji(e.target.value)
    },[setSecondKanji])
    const inputYear = useCallback((e) => {
        setYear(e.target.value)
    },[setYear])
    const inputMonth = useCallback((e) => {
        setMonth(e.target.value)
    },[setMonth])
    const inputDay = useCallback((e) => {
        setDay(e.target.value)
    },[setDay])
    const inputPhonenubmer = useCallback((e) => {
        setPhonenumber(e.target.value)
    },[setPhonenumber])

    const [must, setMust] = useState('')
    const [open, setOpen] = useState(false)
    const dialogClose = () => {
        setOpen(false)
    }
    const [loading, setLoading] = useState(false)
    const [clickAction, setClickAction] = useState(false)
    const clickLink = useCallback((category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber, id) => {
        if(state === "" || city === "" || town === "" || line1 === ""){
            setMust('noaddress')
            setOpen(true)
            return false
        }
        if(firstkana === "" || firstkanji === "" || secondkana === "" || secondkanji === ""){
            setMust('noname')
            setOpen(true)
            return false
        }
        if(year >= 2022 || year <= 1950  || month <= 1 || month >= 12 || day <= 1 || day >= 31 || year === "" || month === "" || day === ""){
            setMust('noage')
            setOpen(true)
            return false
        }
        if(phonenumber === ""){
            setMust('nophone')
            setOpen(true)
            return false
        }
        setLoading(true)
        setClickAction(true)
        dispatch(startCreateAccount(category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber, id)).then(() => {
            setMust('ok')
            setOpen(true)
        })
    },[dispatch])

    const [url, setUrl] = useState(getAccountUrl(selector))
    useEffect(() => {
        setUrl(getAccountUrl(selector))
    })

    const searchPostalcode = async (postalcodeA) => {
        const res = await axios.get("https://zipcloud.ibsnet.co.jp/api/search", {params:{zipcode:postalcodeA}})                
        const result = res.data.results[0]        
        setState(result.address1)
        setCity(result.address2)
        setTown(result.address3)
    }

    if(circle === ""){
        return (
            <>
                <Helmet
                    title="投稿完了/Pallet"
                />
                <div className="header_root">
                    <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                        <ToolBar className="header_toolBar" style={{padding:0}}>
                            <ClosableDrawer icons={icon} uid={uid} notifis={notifis} category={category} />
                        </ToolBar>
                    </AppBar>
                    <Divider variant="middle"/>
                </div>
                <div className='center'>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <div className="module-spacer--medium"/>
                    <CircularProgress/>
                    <div className='module-spacer--medium'/>
                    <div className='module-spacer--medium'/>
                    <div className='module-spacer--medium'/>
                    <div className='module-spacer--medium'/>
                    <div className='module-spacer--medium'/>
                </div>
            </>
        )
    }else{

        return (
            <>
                <Helmet
                title="投稿完了/Pallet"
                />
                <div className="header_root">
                    <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                        <ToolBar className="header_toolBar" style={{padding:0}}>
                            <ClosableDrawer icons={icon} uid={uid} notifis={notifis} category={category} />
                        </ToolBar>
                    </AppBar>
                    <Divider variant="middle"/>
                </div>
                <div className='module-spacer--medium'/>
                    {isNewUser === true ? (
                        <div className="center" style={{maxWidth:600, margin:"auto"}}>                        
                            <h3>会員登録ありがとうございます。</h3>
                            <img src={notion} className="art_request_list_notion" alt="P"/>
                            <p>お題を回答するには報酬の受け取り口座が必要です。</p>
                            <div className="module-spacer--small"/>
                            <h3>基本情報の登録</h3>
                            <p style={{textDecoration:"underline"}}>基本情報は一般には公開されません</p>
                            <div style={{backgroundColor:"#fef8f5", maxWidth:"600px", margin:"0 auto", padding:"10px", borderRadius:"8px", border:"solid 1px #E4E8ED"}}>
                                <p style={{padding:10, textAlign:"left"}}>
                                    {submit === false && (
                                        <span>
                                            基本情報の入力後、口座登録画面に移ります
                                        </span>

                                    )}
                                    <br/>※銀行口座情報は当社では保持せず、オンライン決済サービスStripeにて安全に管理されます。Stripeについては<a href="https://stripe.com/jp" target="_blank" rel="noreferrer" ><span>こちら</span></a>から確認できます。
                                </p>                            
                            </div>
                            <div className="module-spacer--small"/>
                        <p style={{fontWeight:"bold", textAlign:"left"}}>氏名</p>
                        <div className={classes.flex}>
                            <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                <input value={secondkanji} onChange={inputSecondkanji} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="姓"/>
                            </div>
                            <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                <input value={firstkanji} onChange={inputFirstkanji} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="名"/>
                            </div>
                        </div>
                        <p style={{fontWeight:"bold", textAlign:"left"}}>氏名(カタカナ)</p>
                        <div className={classes.flex}>
                            <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                <input value={secondkana} onChange={inputSecondkana} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="姓(カタカナ)"/>
                            </div>
                            <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                <input value={firstkana} onChange={inputFirstkana} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="名(カタカナ)"/>
                            </div>
                        </div>
                        <p style={{fontWeight:"bold", textAlign:"left"}}>生年月日</p>
                        <div className={classes.flex}>
                            <div style={{display:"flex"}}>
                                <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:120, height:40, display:"inline-flex"}}>
                                    <input value={year} onChange={inputYear} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                </div>
                                <p>年</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:120, height:40, display:"inline-flex"}}>
                                    <input value={month} onChange={inputMonth} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                </div>
                                <p>月</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:120, height:40, display:"inline-flex"}}>
                                    <input  value={day} onChange={inputDay} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                </div>
                                <p>日</p>
                            </div>
                        </div>
                        <p style={{fontWeight:"bold", textAlign:"left"}}>電話番号</p>
                        <div style={{textAlign:"left"}}>
                            <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                <input value={phonenumber} onChange={inputPhonenubmer} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="電話番号"/>
                            </div>
                            <p className={classes.editPraceholder}>※電話番号のハイフンは無しでお願いします。</p>
                        </div>
                        <p style={{fontWeight:"bold", textAlign:"left"}}>自宅住所</p>
                        <div style={{textAlign:"left"}}>
                            <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                <input value={postalcode} onChange={inputPostalcode} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="郵便番号"/>
                            </div>
                            <Button style={{marginLeft:5, padding:"0px"}} variant="outlined" onClick={() => searchPostalcode(postalcode)}>検索</Button>
                            <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>※郵便番号のハイフンは無しでお願いします。</p>
                        </div>
                        <div className={classes.edit_address_state}>
                            <div>
                                <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                    <input value={state} onChange={inputState} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                </div>
                                <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>都道府県 (例)東京都</p>
                            </div>
                            <div>
                                <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                    <input value={city} onChange={inputCity} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                </div>
                                <p style={{fontSize:"10px", color:"#808080", textAlign:"left", marginLeft:5}}>市区町村 (例) Pallet市パレット区</p>
                            </div>
                        </div>
                        <div className={classes.flex}>
                            <div>
                                <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                    <input value={town} onChange={inputTown} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false}/>
                                </div>
                                <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>丁目 (例) 南ぱれっと8丁目</p>
                            </div>
                            <div>
                                <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                    <input value={line1} onChange={inputLine1} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                </div>
                                <p style={{fontSize:"10px", color:"#808080", textAlign:"left", marginLeft:5}}>番地、号 (例) 18-9</p>
                            </div>
                        </div>
                        <div className={classes.flex}>
                            <div>
                                <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                    <input value={line2} onChange={inputLine2} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                </div>
                                <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>建物名/部屋番号(任意)</p>
                            </div>
                        </div>
                        <div className='module-spacer--small'/>
                            <PrimaryButton
                                disabled={clickAction}
                                label="口座登録フォームへ"
                                onClick={() => clickLink(category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber, id)}
                            />
                            {loading === true && (
                                <p>処理中です.....</p>
                            )}
                            <div className="module-spacer--medium"/>                        
                            {must === "noaddress" && (
                                <Dialog open={open} onClose={dialogClose}>
                                    <DialogTitle>住所欄に未記入箇所があります</DialogTitle>
                                </Dialog>
                            )}
                            {must === "noname" && (
                                <Dialog open={open} onClose={dialogClose}>
                                    <DialogTitle>氏名欄に未記入箇所があります。</DialogTitle>
                                </Dialog>
                            )}
                            {must === "noage" && (
                                <Dialog open={open} onClose={dialogClose}>
                                    <DialogTitle>生年月日欄が空白、または正しくありません。</DialogTitle>
                                </Dialog>
                            )}
                            {must === "nophone" && (
                                <Dialog open={open} onClose={dialogClose}>
                                    <DialogTitle>電話番号欄が未記入です。</DialogTitle>
                                </Dialog>
                            )}
                            {must === "ok" && (
                                <Dialog open={open} onClose={dialogClose}>
                                    <DialogTitle>口座登録画面へ移動します</DialogTitle>
                                    <DialogContent>
                                        <Typography>
                                        オンライン決済サービスStripeへ移動し、口座情報の登録に進みます<br/>個人情報は開示されることはなく、Stripeで安全に保管されます
                                        </Typography>                                
                                    </DialogContent>
                                    <DialogActions>
                                        <a href={url} target="_blank" className="u-text__link-none" rel="noreferrer">
                                            <Button style={{backgroundColor:"#ff7c21", color:"#fff",textTransform:"none", borderRadius:"12px"}}><Typography>Stripeへ</Typography></Button>
                                        </a>
                                    </DialogActions>
                                </Dialog>
                            )}
                            <GrayButton
                                label="後で設定する"
                                onClick={() => dispatch(push('/art_request/all/' + id))}
                            />
                            <div className='module-spacer--medium'/>                
                        </div>
                    ):(
                        <div className="center">
                            <h3>お帰りなさい</h3>
                            <img src={headphone} className="art_request_list_notion" alt="P"/>
                            {submit === true ? (
                                <div>
                                    <PrimaryButton
                                        label="投稿ページへ戻る"
                                        onClick={() => dispatch(push('/art_request/all/' + id))}
                                    />
                                </div>
                            ):(
                                <div style={{maxWidth:600, margin:"auto"}}>
                                    <p>お題を回答するには報酬の受け取り口座が必要です。</p>
                                    <div className='module-spacer--small'/>
                                    <h3>基本情報の登録</h3>
                                    <p style={{textDecoration:"underline"}}>基本情報は一般には公開されません</p>
                                    <div style={{backgroundColor:"#fef8f5", maxWidth:"600px", margin:"0 auto", padding:"10px", borderRadius:"8px", border:"solid 1px #E4E8ED"}}>
                                        <p style={{padding:10, textAlign:"left"}}>
                                            {submit === false && (
                                                <span>
                                                    基本情報の入力後、口座登録画面に移ります
                                                </span>

                                            )}
                                            <br/>※銀行口座情報は当社では保持せず、オンライン決済サービスStripeにて安全に管理されます。Stripeについては<a href="https://stripe.com/jp" target="_blank" rel="noreferrer" ><span>こちら</span></a>から確認できます。
                                        </p>                                    
                                    </div>
                                    <div className="module-spacer--small"/>
                                <p style={{fontWeight:"bold", textAlign:"left"}}>氏名</p>
                                <div className={classes.flex}>
                                    <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                        <input value={secondkanji} onChange={inputSecondkanji} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="姓"/>
                                    </div>
                                    <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                        <input value={firstkanji} onChange={inputFirstkanji} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="名"/>
                                    </div>
                                </div>
                                <p style={{fontWeight:"bold", textAlign:"left"}}>氏名(カタカナ)</p>
                                <div className={classes.flex}>
                                    <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                        <input value={secondkana} onChange={inputSecondkana} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="姓(カタカナ)"/>
                                    </div>
                                    <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                        <input value={firstkana} onChange={inputFirstkana} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="名(カタカナ)"/>
                                    </div>
                                </div>
                                <p style={{fontWeight:"bold", textAlign:"left"}}>生年月日</p>
                                <div className={classes.flex}>
                                    <div style={{display:"flex"}}>
                                        <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:120, height:40, display:"inline-flex"}}>
                                            <input value={year} onChange={inputYear} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                        </div>
                                        <p>年</p>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:120, height:40, display:"inline-flex"}}>
                                            <input value={month} onChange={inputMonth} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                        </div>
                                        <p>月</p>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:120, height:40, display:"inline-flex"}}>
                                            <input  value={day} onChange={inputDay} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                        </div>
                                        <p>日</p>
                                    </div>
                                </div>
                                <p style={{fontWeight:"bold", textAlign:"left"}}>電話番号</p>
                                <div style={{textAlign:"left"}}>
                                    <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                        <input value={phonenumber} onChange={inputPhonenubmer} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="電話番号"/>
                                    </div>
                                    <p className={classes.editPraceholder}>※電話番号のハイフンは無しでお願いします。</p>
                                </div>
                                <p style={{fontWeight:"bold", textAlign:"left"}}>自宅住所</p>
                                <div style={{textAlign:"left"}}>
                                    <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                        <input value={postalcode} onChange={inputPostalcode} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="郵便番号"/>
                                    </div>
                                    <Button style={{marginLeft:5, padding:"0px"}} variant="outlined" onClick={() => searchPostalcode(postalcode)}>検索</Button>
                                    <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>※郵便番号のハイフンは無しでお願いします。</p>
                                </div>
                                <div className={classes.edit_address_state}>
                                    <div>
                                        <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                            <input value={state} onChange={inputState} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                        </div>
                                        <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>都道府県 (例)東京都</p>
                                    </div>
                                    <div>
                                        <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                            <input value={city} onChange={inputCity} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                        </div>
                                        <p style={{fontSize:"10px", color:"#808080", textAlign:"left", marginLeft:5}}>市区町村 (例) Pallet市パレット区</p>
                                    </div>
                                </div>
                                <div className={classes.flex}>
                                    <div>
                                        <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                            <input value={town} onChange={inputTown} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false}/>
                                        </div>
                                        <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>丁目 (例) 南ぱれっと8丁目</p>
                                    </div>
                                    <div>
                                        <div style={{marginLeft:5,backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                            <input value={line1} onChange={inputLine1} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                        </div>
                                        <p style={{fontSize:"10px", color:"#808080", textAlign:"left", marginLeft:5}}>番地、号 (例) 18-9</p>
                                    </div>
                                </div>
                                <div className={classes.flex}>
                                    <div>
                                        <div style={{backgroundColor:"#f0f0f0", borderRadius:"4px", width:195, height:40, display:"inline-flex"}}>
                                            <input value={line2} onChange={inputLine2} type="text" style={{paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} />
                                        </div>
                                        <p style={{fontSize:"10px", color:"#808080", textAlign:"left"}}>建物名/部屋番号(任意)</p>
                                    </div>
                                </div>
                                <div className='module-spacer--small'/>
                                    <PrimaryButton
                                        disabled={clickAction}
                                        label="口座登録フォームへ"
                                        onClick={() => clickLink(category, state, city, town, line1, line2, postalcode, firstkana, secondkana, firstkanji, secondkanji, year, month, day, phonenumber, id)}
                                    />
                                    {loading === true && (
                                        <p>処理中です....</p>
                                    )}
                                    <div className="module-spacer--medium"/>
                                    
                                    <div className='module-spacer--small'/>
                                    {must === "noaddress" && (
                                        <Dialog open={open} onClose={dialogClose}>
                                            <DialogTitle>住所欄に未記入箇所があります</DialogTitle>
                                        </Dialog>
                                    )}
                                    {must === "noname" && (
                                        <Dialog open={open} onClose={dialogClose}>
                                            <DialogTitle>氏名欄に未記入箇所があります。</DialogTitle>
                                        </Dialog>
                                    )}
                                    {must === "noage" && (
                                        <Dialog open={open} onClose={dialogClose}>
                                            <DialogTitle>生年月日欄が空白、または正しくありません。</DialogTitle>
                                        </Dialog>
                                    )}
                                    {must === "nophone" && (
                                        <Dialog open={open} onClose={dialogClose}>
                                            <DialogTitle>電話番号欄が未記入です。</DialogTitle>
                                        </Dialog>
                                    )}
                                    {must === "ok" && (
                                        <Dialog open={open} onClose={dialogClose}>
                                            <DialogTitle>口座登録画面へ移動します</DialogTitle>
                                            <DialogContent>
                                                <Typography>
                                                オンライン決済サービスStripeへ移動し、口座情報の登録に進みます<br/>個人情報は開示されることはなく、Stripeで安全に保管されます
                                                </Typography>                                
                                            </DialogContent>
                                            <DialogActions>
                                                <a href={url} target="_blank" className="u-text__link-none" rel="noreferrer">
                                                    <Button style={{backgroundColor:"#ff7c21", color:"#fff",textTransform:"none", borderRadius:"12px"}}><Typography>Stripeへ</Typography></Button>
                                                </a>
                                            </DialogActions>
                                        </Dialog>
                                    )}
                                    <GrayButton
                                        label="後で設定する"
                                        onClick={() => dispatch(push('/art_request/all/' + id))}
                                    />           
                                </div>
                            )}
                            <div className="module-spacer--medium"/>
                        </div>
                    )}
                <div className='module-spacer--small'/>
            </>
        )
    }
}

export default IllustArtRequestbank