import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, DialogActions, IconButton, Tooltip} from '@material-ui/core'
import logo from "../assets/logo.png"
import { ArtRequestHeaderMenu, ClosableDrawer } from '../components/Header'
import { Helmet } from 'react-helmet-async'
import React, {useEffect, useState} from 'react'
import {Button, Divider, Menu, MenuItem, ListItemIcon, ListItemText, Grid, Dialog, DialogTitle, DialogContent, Typography} from '@material-ui/core'
import { auth, db } from '../firebase'
import { useDispatch } from 'react-redux'
import {push} from 'connected-react-router'
import noImage from '../assets/noImage.png'
import noImageHeader from '../assets/noimageheader.png'
import {ArtsShowDetail} from '../components/Arts'
import {PrimaryButton } from '../components/UIkit'
import { retrieveAccount } from '../reducks/stripes/operations'
import high from '../assets/pottins_hightouch.png'
import notion from '../assets/pottin_notion.png'
import TwitterIcon from '@material-ui/icons/Twitter'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const ArtRequestList = () => {
    const id = window.location.pathname.split('/art_request/all/')[1]    
    const shareURL = "https://pallet.fun/art_request/all/" + id
    const dispatch = useDispatch()

    const [uid, setUid] = useState('')
    const [signedIn, setSignedIn] = useState(false)

    const [submit, setSubmit] = useState(false)
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
                db.collection('users').doc(user.uid).get().then(snapshot => {
                    const data = snapshot.data()
                    if(data.account_id !== ""){
                        (async () => {
                            const accountData = await retrieveAccount(data.account_id)                            
                            if(accountData){
                                setSubmit(accountData.details_submitted)
                            }
                        })()
                    }
                })
            }
        })
    },[])
    
    //headerのアイコン
    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')
    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            })
        }
    },[uid])
    //headerの通知
    const [notifis, setNotifis] = useState([])
    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])
    //作品の投稿者情報
    const [title, setTitle] = useState('')
    const [requestUid, setRequestUid] = useState('')
    const [stop, setStop] = useState(false)
    const [img, setImg] = useState(noImageHeader)
    const [username, setUsername] = useState('')
    const [screenName, setScreenName] = useState('')

    useEffect(() => {
        db.collection('requests').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setTitle(data.title)
            db.collection('users').doc(data.requestUid).get().then(snapshot => {
                const userData = snapshot.data()
                setUsername(userData.username)
                if(data.screen_name){
                    setScreenName(data.screen_name)
                }
            })
            setRequestUid(data.requestUid)
            if(data.stop){
                setStop(data.stop)
            }else{
                setStop(false)
            }
            if(data.img[0].path){
                setImg(data.img[0].path)
            }

        })
    })

    //作品一覧を取得
    const [arts, setArts] = useState([])
    useEffect(() => {
        if(id){
            const unsubscribe = db.collection('arts').where('requestId', "==", id).orderBy('created_at', "desc").onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setArts(list)
            })
            return () => unsubscribe()        
        }
    },[id])

    const [stripeOpen, setStripeOpen] = useState(false)

    const handleStripe = () => {
        setStripeOpen(true)
    }
    const handleStripeClose = () => {
        setStripeOpen(false)
    }

    const [shareOpen, setShareOpen] = useState(false)
    const shareClose = () => {
        setShareOpen(false)
        setCopyOk(false)
    }

    const [copyOk, setCopyOk] = useState(false)

    const copyTextClipboard = () => {
        navigator.clipboard.writeText(shareURL).then(() => {
            setCopyOk(true)
            setShareOpen(true)
        }).catch(() => {
            alert("クリップボードへのコピーが失敗しました。通信環境を確認の上再度お試しください。")
        })
    }
    const [loginOpen, setLoginOpen] = useState(false)
    const handleLogin = () => {
        setLoginOpen(true)
    }
    const LoginClose = () => {
        setLoginOpen(false)
    }
    const [editAnchorEl, setEditAnchorEl] = useState(null)
    const hadnleEditMenu = (e) => {
        setEditAnchorEl(e.currentTarget)
    }
    const handleEditClose = () => {
        setEditAnchorEl(null)
    }
    return (
        <>
           <Helmet
                title={`${username}さんのページ/Pallet`}
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        {signedIn === true ? (
                            <>
                                <ClosableDrawer uid={uid} category={category} icons={icons} notifis={notifis} />                            
                            </>
                        ):(
                            <>
                                <img src={logo} alt="logo" width="128px"/>
                                <div className="header_iconButton">
                                <ArtRequestHeaderMenu id={id} key={id}/>
                                </div>
                            </>
                        )}
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className='module-spacer--small'/>            
            <div className='center' style={{maxWidth:"800px"}}>
                <div style={{textAlign:"left"}}>
                    <Button onClick={() => dispatch(push('/art_list/all/' + requestUid))} startIcon={(<ArrowBackIosIcon/>)}>コレクターのページへ</Button>
                </div>
                <br/>
                {stop === true &&(
                    <p style={{fontSize:"20px", fontWeight:"bold", color:"red"}}>※作品の投稿期間が終了しました</p>
                )}
                <div style={{position:"relative"}} className="art_header">
                    <img src={img} alt="p" className='art_list_header'/>
                    {uid === requestUid && (
                        <IconButton style={{position:"absolute"}} className="art_list_edit" onClick={hadnleEditMenu}>
                            <MoreHorizIcon style={{color:"black"}}/>
                        </IconButton>                        
                    )}
                    <p style={{position:"absolute", color:"#fff"}} className="art_list_title">{title}</p>
                    <p style={{position:"absolute", color:"#fff"}} className="art_list_username">{username}</p>

                </div>
                <Menu
                  id="edit-menu"
                  anchorEl={editAnchorEl}
                  keepMounted
                  open={Boolean(editAnchorEl)}
                  onClose={handleEditClose}
                >
                    <MenuItem onClick={() => dispatch(push('/art_request/fix/' + id))}>
                        <ListItemIcon>
                            <EditIcon/>
                        </ListItemIcon>
                        <ListItemText primary="編集する"/>
                    </MenuItem>
                </Menu>
                {uid === requestUid ? (
                    <div style={{display:"flex"}}>
                        <h3 style={{marginLeft:"10px"}}>投稿一覧</h3>
                        {uid === requestUid && (
                            <div style={{display:"flex", maxWidth:100, margin:"auto 0 auto auto"}}>                    
                                <Tooltip title="Twitterへシェア">
                                    <a href={`http://twitter.com/share?url=${shareURL}&text=『${title}』｜お題に投稿した絵が販売できるPalletで皆さんの作品をお待ちしています&hashtags=Pallet`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                        <IconButton>
                                            <TwitterIcon style={{color:"#1DA1F2"}}/>
                                        </IconButton>
                                    </a>
                                </Tooltip>
                                <Tooltip title="リンクをコピー">
                                    <IconButton onClick={() => copyTextClipboard()}>
                                        <FileCopyIcon/>
                                    </IconButton>
                                </Tooltip>
                                {copyOk === true && (
                                    <Dialog open={shareOpen} onClose={shareClose}>
                                        <DialogTitle>クリップボードにリンクをコピーしました!</DialogTitle>
                                    </Dialog>
                                )}
                            </div>                                              
                        )}
                    </div>
                ):(
                  <>
                    <div style={{textAlign:"right"}}>
                        <div style={{display:"flex", maxWidth:"100px",margin:"auto 0 auto auto"}}> 
                            <Tooltip title="Twitterへシェア">
                                <a href={`http://twitter.com/share?url=${shareURL}&text=${username}さんのお題&hashtags=Pallet&screen_name=${screenName}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                    <IconButton>
                                        <TwitterIcon style={{color:"#1DA1F2"}}/>
                                    </IconButton>
                                </a>
                            </Tooltip>
                            <Tooltip title="リンクをコピー">
                                <IconButton onClick={() => copyTextClipboard()}>
                                    <FileCopyIcon/>
                                </IconButton>
                            </Tooltip>
                            {copyOk === true && (
                                <Dialog open={shareOpen} onClose={shareClose}>
                                    <DialogTitle>クリップボードにリンクをコピーしました!</DialogTitle>
                                </Dialog>
                            )}                                                    
                        </div>
                    </div>
                    {stop === false && (
                        <>
                                {signedIn === true ? (
                                    <>
                                        {submit === true ? (
                                            <PrimaryButton
                                            label="お題に回答する"
                                            onClick={() => dispatch(push('/art/edit/' + id))}
                                            className="center"
                                            />
                                        ):(
                                            <>
                                                <PrimaryButton
                                                    label="お題に回答する"
                                                    onClick={() => handleStripe()}
                                                />
                                                <Dialog open={stripeOpen} onClose={handleStripeClose} aria-labelledby="alert-dialog-title">
                                                    <DialogTitle id="alert-dialog-title">購入された時のために振り込み口座を登録しましょう。</DialogTitle>
                                                    <DialogContent>
                                                        <Typography>
                                                            口座を登録後、作品を投稿することができます。
                                                        </Typography>
                                                        <Typography>
                                                            口座情報はStripeで安全に保護されます。
                                                        </Typography>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => dispatch(push('/payment_bank/' + uid))} style={{backgroundColor:"#ff7c21", color:"#fff"}}>口座登録をする</Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </>
                                        )}
                                    </>
                                ):(
                                    <>
                                        <PrimaryButton
                                            label="お題に回答する"
                                            onClick={() => handleLogin()}
                                        />
                                        <Dialog open={loginOpen} onClose={LoginClose}>
                                            <DialogTitle>会員登録がまだされていません</DialogTitle>
                                            <DialogContent>
                                                <Typography>
                                                    会員登録・受け取り口座の登録後、投稿が可能になります。
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button style={{color:"white",backgroundColor:"#ff7c21", borderRadius:"12px"}} onClick={() => dispatch(push('/i_art_request_home/' + id))}>会員登録する</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )}
                                <h3 style={{textAlign:"left", marginLeft:10}}>投稿一覧</h3>
                            </>
                            )}
                        </>
                    )}
                <Divider/>
                <div className="center">
                    <Grid style={{alignItems:"stretch", display:"flex", flexWrap:"wrap", justifyContent:"space-around"}} onContextMenu={(e) => e.preventDefault()} >
                        {arts.length > 0 ? (
                            arts.map(art => (
                                <div style={{margin:0}} key={art.id}>
                                    <ArtsShowDetail
                                        id={art.id}  userId={art.artId} createrÎd={art.createrId} uid={uid}
                                    />
                                </div>
                            ))
                        ):(
                            <>
                                <div className='center'>    
                                    <div className='module-spacer--medium'/>
                                    {requestUid === uid ? (
                                        <>
                                            <img src={high} alt="p" className='art_request_list_high' />
                                            <br/>
                                            <p style={{marginTop:0}}>SNSにシェアして<br/>イラストを集めよう</p>
                                        </>
                                    ):(
                                        <div className="center">
                                           <img src={notion} alt="p" className="art_request_list_notion"/>
                                           <br/>                                           
                                           <p style={{marginTop:"0px"}}>お題に回答して<br/>最初の投稿者になろう</p>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </Grid>
                </div>
                <div className='module-spacer--medium'/>
            </div>
        </>
    )
}

export default ArtRequestList