import React,{useState, useCallback} from 'react'
import {IconButton, Avatar, Dialog, DialogTitle, DialogActions, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import IconPreview from './IconPreview'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {storage} from '../../firebase/index'


const useStyles = makeStyles((theme) => ({
    icon: {
        margin:"auto",
        [theme.breakpoints.down('sm')]:{
            height: 130,
            width: 130,
        },
        [theme.breakpoints.up(('sm'))]:{
            height:160,
            width:160
        }
    },
    size: {
        margin:"auto",
        [theme.breakpoints.down('sm')]:{
            height: 120,
            width: 120,
        },
        [theme.breakpoints.up('sm')]:{
            width:150,
            height:150
        }
    },
    addPhoto:{
        position: "absolute",
        zIndex: 2,
        display:"flex"
    },
    addPhoto1:{
        [theme.breakpoints.down('sm')]:{
            position: "absolute",
            zIndex: 2,
            display:"flex",
            top:280,
            left:230,
            width:30,
            height: 30
            
        },
        [theme.breakpoints.up('sm')]:{
            position: "absolute",
            zIndex: 2,
            display:"flex",
            top:320,
            left:670,
            width:30,
            height:30
        }
    },
    detail:{
        fontSize:"10px",
        color:"#808080"
    }
}))

const IconArea = (props) => {
    const classes = useStyles()

    const setIcons = props.setIcons
    const icons = props.icons

    const [open, setOpen] = useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const uploadImage = useCallback((event) => {
        const file = event.target.files;

        let blob = new Blob(file, { type: "image/jpeg" });

        const S = "abcdefghijklmnopqrstuvwyzABCDEFGHIJLMNOPQRSTUVWXYZ1234567890"
        const N = 16
        const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n) => S[n % S.length]).join('')

        const uploadRef = storage.ref('icons').child(fileName)
        const uploadTask = uploadRef.put(blob)

        uploadTask.then(() => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                const newIcon = { id: fileName, path: downloadURL }
                setIcons((prevState => [...prevState, newIcon]))
            })
        })
    }, [setIcons])

    const deleteIcon = useCallback(async (id) => {
        const newIcon = icons.filter(icons => icons.id !== id)
        setIcons(newIcon);
        return storage.ref('icons').child(id).delete().then(() => {
            handleClose()
        })
    }, [icons, setIcons])

    return(
        <div className={classes.icon}>
            {icons.length === 0 ? (
                <IconButton className={classes.icon}>
                    <label>
                        <Avatar className={classes.size}>
                            <PersonAddIcon className={classes.size} style={{backgroundColor:"#808080"}}/>
                            <input
                                type='file'
                                className='u-display-none center'
                                id='image'
                                onChange={(event) => uploadImage(event)}
                            />
                        </Avatar>
                    </label>
                </IconButton>
            ) : (
                props.icons.map((icon, index) => 
                    <div className='center' key={index}>
                        <IconPreview  id={icon.id} path={icon.path} className={classes.size} onClick={(e) => handleClickOpen(e)}/>
                        <p className={classes.detail}>※アイコンタップで<br/>画像を変更できます</p>
                        
                        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
                            <DialogTitle id="alert-dialog-title">{"画像を変更しますか？"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => deleteIcon(icon.id)}>はい</Button>
                                <Button onClick={handleClose}>いいえ</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )
            )}
        </div>
    )
}
            
export default IconArea
