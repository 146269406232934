import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, DialogActions, DialogTitle,Dialog, Tooltip, DialogContent, TextField, DialogContentText } from '@material-ui/core'
import logo from "../assets/logo.png"
import noImage from '../assets/noImage.png'
import { ArtDetailHeader, ClosableDrawer } from '../components/Header'
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Helmet } from 'react-helmet-async'
import React,{useState, useEffect, useCallback} from 'react'
import { useDispatch } from 'react-redux'
import {push} from 'connected-react-router'
import { Avatar, IconButton, Divider, Button, Grid } from '@material-ui/core'
import { auth, db, FirebaseTimestamp } from '../firebase'
import GetAppIcon from '@material-ui/icons/GetApp';
import { TwitterButton, GrayButton } from '../components/UIkit'
import HTMLReactParser from 'html-react-parser'
import { ArtsShowDetail } from '../components/Arts'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {withStyles} from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const LightTooltip = withStyles({
    tooltip: {
      backgroundColor:"#f0f0f0",
      color:"black",
      fontSize: 11,
    },
})(Tooltip);

const returnCodeToBr = (text) => {
    if (text === ""){
      return text
    }else{
      return HTMLReactParser(text.replace(/\r?\n/g, '<br/>'))
    }
}

const ArtDetail = () => {
    // const classes = useStyles()
    const dispatch = useDispatch()

    const [uid, setUid] = useState('') 
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
            }
        })
    })

    const id = window.location.pathname.split('/art_detail/')[1]
    const BASE_URL = "https://pallet.fun/art_detail/" + id

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [img, setImg] = useState([])
    const [downloadImg, setDownloadImg] = useState([])  
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [userId, setUserId] = useState('')
    const [createrId, setCreaterId] = useState('')    
    const [price, setPrice] = useState('')
    const [useCase, setUseCase] = useState(true)

    const [influencername, setInfluencername] = useState('')    

    const [username, setUsername] = useState('')
    const [icon, setIcon] = useState('')
    const [screen_name, setScreenName] = useState('')

    const [requestId, setRequestId] = useState('')

    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            if(data){
                setTitle(data.title)
                setDesc(data.desc)
                setWidth(data.imgWidth)
                setHeight(data.imgHeight)
                setCreaterId(data.createrId)
                setUserId(data.artId)                
                setRequestId(data.requestId)
                setPrice(parseInt(data.price, 10))
                // setImg(data.img[0].path)
                setImg(data.img[0].id)
                setDownloadImg(data.img[0].path)
                setUseCase(data.useCase)
                db.collection('users').doc(data.artId).get().then(snapshot => {
                    const userdata = snapshot.data()
                    setInfluencername(userdata.username)
                })                
                
                db.collection('users').doc(data.createrId).get().then(snapshot => {
                    const createrData = snapshot.data()                    
                    setUsername(createrData.username)
                    if(createrData.screen_name){
                        setScreenName(createrData.screen_name)
                    }
                    if(createrData.icons === ""){
                        setIcon(noImage)
                    }
                    if(createrData.icons[0].path){
                        setIcon(createrData.icons[0].path)
                    }                    
                })
            }
            
        })
    },[id])    

    const [icons, setIcons] = useState()
    const [category, setCategory] = useState()

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data){
                    if(data.icons === ""){
                        setIcons(noImage)
                    }
                    if(data.icons[0].path){
                        setIcons(data.icons[0].path)
                    }
                }
                
            })
        }
    },[uid])

    const timestamp = FirebaseTimestamp.now()    

    const [downloadOpen, setDownloadOpen] = useState(false)

    const freeDLOpen = () => {
        setDownloadOpen(true)
    }
    const downloadClose = () => {
        setDownloadOpen(false)
    }

    const finishedProductDownload = useCallback(() => {        
        //製作者の通知にメッセを飛ばす
        const ref = db.collection('users').doc(createrId).collection('notifications').doc()
        const refId = ref.id

        if(userId === uid){
            const refData = {
                created_at:timestamp,
                download:true,
                artId:id,
                id:refId,
                downloaderId:uid,
                owner:true,
                look:false
            }
            //ダウンロードしたインフルエンサーが投稿に対してダウンロードしたことを知らせるようなバッジをつけるために入れる
            const favoriteData = {
                created_at:timestamp,
                artId:id,
                uid: userId,
                createrId: createrId,
                owner:true
            }
            db.collection("arts").doc(id).collection('favorites').doc("favorites").set(favoriteData)
            //favoriteコレクションに入れてダウンロードした作品一覧を出す
            db.collection('favorites').doc(id).set(favoriteData)
            db.collection('users').doc(createrId).collection('notifications').doc(refId).set(refData).then(() => {                
                const xhr = new XMLHttpRequest()
                xhr.open("GET", downloadImg, true)
                xhr.responseType = 'blob'
                xhr.onload = () => {
                    const blob = xhr.response
                    var objectURL = window.URL.createObjectURL(blob)
                    var link = document.createElement("a")
                    document.body.appendChild(link)
                    link.href = objectURL
                    link.download = title
                    link.click();
                    document.body.removeChild(link)
                }
                xhr.send();
                setDownloadOpen(false)                                
            })
        }else{
            //一般のユーザー又は他のインフルエンサーがダウンロードした時
            const refData = {
                created_at:timestamp,
                download:true,
                artId:id,
                id:refId,
                uid:uid,
                owner:false,
                look:false
            }
            //二次創作がダウンロードされた数を出す
            const favoriteUserData = {
                created_at:timestamp,
                artId:id,
                uid:uid,
                createrId:createrId,
                owner:false,
            }
            const subfavorteData = {
                created_at:timestamp,
                artId:id,
                createtId:createrId,
                owner:false,
            }
            db.collection('arts').doc(id).collection('downloads').doc(uid).set(favoriteUserData)
            //ダウンロード作品一覧を表示するときに使う
            db.collection('users').doc(uid).collection('favorites').doc(id).set(subfavorteData)
            //制作者にダウンロードされたことを告げる
            db.collection('users').doc(createrId).collection('notifications').doc(refId).set(refData).then(() => {                
                const xhr = new XMLHttpRequest()
                xhr.open("GET", downloadImg, true)
                xhr.responseType = 'blob'
                xhr.onload = () => {
                    const blob = xhr.response
                    var objectURL = window.URL.createObjectURL(blob)
                    var link = document.createElement("a")
                    document.body.appendChild(link)
                    link.href = objectURL
                    link.download = title
                    link.click();
                    document.body.removeChild(link)
                }
                xhr.send();
                setDownloadOpen(false)
            })
        }
    })
    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])


    //通報
    const [alert, setAlert] = useState('')
    const inputAlert = useCallback((e) => {
        setAlert(e.target.value)
    },[setAlert])

    const [collecterAlert, setCollecterAlert] = useState('')
    const inputCollecterAlert = useCallback((e) => {
        setCollecterAlert(e.target.value)
    },[setCollecterAlert])

    const [alertOpen, setAlertOpen] = useState(false)
    const handleAlert = () => {
        setAlertOpen(true)
    }
    const alertClose = () => {
        setAlertOpen(false)
    }

    const sendAlert = useCallback((alert) => {
        if(alert === ""){
            alert('報告理由が未記入のため送信できません。')
            return false
        }
        const ref = db.collection('alerts').doc()
        const refid = ref.id
        const alertdata = {
            created_at:timestamp,
            artId:id,
            createrId:createrId,
            alertReason:alert,
            user:uid,
            id:refid
        }
        db.collection('alerts').doc(refid).set(alertdata).then(() => {
            setAlertOpen(false)
        })
    })

    const sendCollecterAlert = useCallback((alert) => {
        if(alert === ""){
            alert('報告理由が未記入なため送信できません。')
        }
        const ref = db.collection('collecterAlerts').doc()
        const refid = ref.id
        const alertdata = {
            created_at:timestamp,
            artId:id,
            createrId:createrId,
            alertReason:alert,
            user:uid,
            id:refid
        }
        db.collection('collecterAlerts').doc(refid).set(alertdata).then(() => {
            setAlertOpen(false)
        })
    })

    const [copyok, setCopyok] = useState(false)    
    
    const copyClose = () => {
        setCopyok(false)
    }

    const copyTextClipboard = () => {
        navigator.clipboard.writeText(BASE_URL).then(() => {
            setCopyok(true)
        }).catch(() => {
            alert('クリップボードへのコピーが失敗しました。通信環境を確認の上再度お試しください')
        })
    }

    //関連作品created_at　limit3

    const [arts, setArts] = useState([])
    useEffect(() => {
        db.collection('arts').where('requestId', "==", requestId).orderBy('created_at', "desc").limit(3).get().then((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setArts(list)      
        })
    },[requestId])

    const [loginOpen, setLoginOpen] = useState(false)
    const loginClick = () => {
        setLoginOpen(true)
    }
    const loginClose = () => {
        setLoginOpen(false)
    }

    return(
        <>
        <Helmet
            title={`${username}さんの作品/Pallet`}
        />
        <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                 {signedIn === true ? (
                     <>
                        <ClosableDrawer uid={uid} category={category} icons={icons} notifis={notifis} />                    
                      </>
                    ):(                        
                        <>
                            <img src={logo} alt="logo" width="128px"/>
                            <div className='header_iconButton'>
                                <ArtDetailHeader id={id} key={id}/>
                            </div>
                        </>
                    )}         
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
        <div className="module-spacer--small"/>
        <div className='center' style={{maxWidth:"900px"}}>
            <div style={{textAlign:"left"}}>
                    <Button onClick={() => dispatch(push('/art_request/all/' + requestId))} startIcon={(<ArrowBackIosIcon/>)}>お題の詳細ページに戻る</Button>
            </div>
            <div className='module-spacer--small'/>
            <Grid container style={{margin:"auto"}}>
                <Grid item xs={12} sm={6} style={{maxWidth:"400px", margin:"0 auto auto auto "}}>
                    <div className="detail_box" >
                       
                        {width < height && (
                            <>       
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画面" className="detail_height" onContextMenu={(e) => e.preventDefault()}/>
                               
                                {/* <p className='detail_watermark1'>sample</p>
                                <p className='detail_watermark2'>sample</p>
                                <p className='detail_watermark3'>sample</p>
                                <p className='detail_watermark4'>sample</p>
                                <p className='detail_watermark5'>sample</p>
                                <p className='detail_watermark6'>sample</p>
                                <p className='detail_watermark7'>sample</p> */}
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>
                        )}
                        {width > height && (
                            <>
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="detail_width" onContextMenu={(e) => e.preventDefault()}/>
                                {/* <p className='detail_watermark1'>sample</p>
                                <p className='detail_watermark2'>sample</p>
                                <p className='detail_watermark3'>sample</p>
                                <p className='detail_watermark4'>sample</p>
                                <p className='detail_watermark5'>sample</p>
                                <p className='detail_watermark6'>sample</p>
                                <p className='detail_watermark7'>sample</p> */}
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>
                        )}
                        {width === height && (
                            <>                                
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="detail_square" onContextMenu={(e) => e.preventDefault()}/>
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>
                        )}
                    </div>
                    <div className="detail_alert_width">

                        {uid === userId && (
                            <div style={{margin:"0 0 0 auto"}}>
                                <Tooltip title="この作品を報告する">
                                    <IconButton style={{margin:"0 0 0 auto"}} onClick={() => handleAlert()}>
                                        <NewReleasesIcon style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                                <Dialog open={alertOpen} onClose={alertClose}>
                                    <DialogTitle>この作品を報告する</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            報告理由を教えてください
                                        </DialogContentText>
                                        <TextField
                                            value={collecterAlert}
                                            onChange={inputCollecterAlert}
                                            label="報告理由"
                                            margin="dense"
                                            fullWidth={true}
                                            multiline={true}
                                            rows={4}
                                            variant="outlined"
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => sendCollecterAlert(collecterAlert)} style={{backgroundColor:"red", color:"#fff", borderRadius:"12px"}}>報告する</Button>
                                        <Button onClick={alertClose}>閉じる</Button>
                                    </DialogActions>                                    
                                </Dialog>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid  item xs={12} md={6} style={{maxWidth:"400px", margin:"0 auto auto auto"}} className="detail_desc">
                    <p style={{textAlign:"left", fontWeight:"bold"}} className="art_detail_title">{title}</p>
                    <div style={{display:"flex", margin:"0 auto 0 0 ", textAlign:"left"}}>
                        <IconButton style={{width:"60px", height:"60px", margin:"auto 10px", padding:"0px"}} onClick={() => dispatch(push("/mypage/" + createrId))}>
                            <Avatar src={icon} style={{width:"50px", height:"50px"}}/>
                        </IconButton>
                        <p style={{ marginTop:"20px", marginLeft:"10px", cursor:"pointer"}} onClick={() => dispatch(push('/mypage/' + createrId))}><span style={{fontWeight:"bold"}}>{username}</span>さんの作品</p>             
                    </div>
                    {price === 0 ? (
                        <p style={{fontWeight:"bold", textAlign:"left", marginLeft:"20px"}}>無料</p>
                    ):(
                        <p style={{ textAlign:"left", color:"#E53C31", marginLeft:20}} >¥<span style={{fontWeight:"bold", fontSize:"24px",}}>{price.toLocaleString()}</span></p>
                    )}
                    <p style={{textAlign:"left", marginLeft:"20px"}}>{returnCodeToBr(desc)}</p>                                      
                    <div className="module-spacer--small"/>
                    {signedIn === true ? (
                        <>
                            {uid === userId ? (
                                <>
                                    <Button onClick={() => freeDLOpen()} style={{backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px", width:300, height:48}} startIcon={(<GetAppIcon/>)}>ダウンロードする</Button>
                                    <p style={{fontSize:"14px"}}>※お題を投稿したあなたは無料でダウンロードできます</p>
                                </>
                            ):(
                                <>
                                    {uid === createrId ? (
                                        <>
                                            <a href={`http://twitter.com/share?url=${BASE_URL}&text=イラスト投稿しました｜『${title}』&hashtags=Pallet,${influencername}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                                <TwitterButton
                                                label="共有する"                                          
                                                />
                                            </a>
                                            <GrayButton
                                              label="リンクをコピーする"
                                              onClick={() => copyTextClipboard()}
                                            />
                                        </>
                                    ):(
                                        <>
                                            {price === 0 ? (
                                                <>
                                                    <Button onClick={() => freeDLOpen()} style={{backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px", width:172, height:52}} startIcon={(<GetAppIcon/>)}>ダウンロードする</Button>
                                                </>
                                            ):(     
                                                <div style={{display:"flex"}}>
                                                    <LightTooltip  title={`購入すると${influencername}さんに15%入ります`}>
                                                        <Button onClick={() => dispatch(push('/payment_form/' + id))} style={{backgroundColor:"#ff7c21", color:"#fff", margin:"auto", width:172, height:52, borderRadius:"12px"}}>購入手続きへ</Button>
                                                    </LightTooltip>
                                                    {uid !== createrId && (
                                                        <div style={{display:"flex", maxWidth:100, margin:"10px auto auto 0"}}>
                                                            <Tooltip title="Twitterへシェア">
                                                                <a href={`http://twitter.com/share?url=${BASE_URL}&text=${username}さんの作品｜『${title}』&hashtags=Pallet&screen_name=${screen_name}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                                                    <IconButton>
                                                                        <TwitterIcon style={{color:"#1DA1F2"}}/>
                                                                    </IconButton>
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip title="リンクをコピー">
                                                                <IconButton onClick={() => copyTextClipboard()}>
                                                                    <FileCopyIcon/>                            
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}           
                        </>
                    ):(
                        <>
                            {price === 0 ? (
                                <>
                                    <Button style={{backgroundColor:"#ff7c21", color:"#fff",  borderRadius:"12px"}} startIcon={(<GetAppIcon/>)} onClick={() => loginClick()}>ダウンロードする</Button>
                                </>
                            ):(
                                <div style={{display:"flex"}}>
                                    <LightTooltip  title={`購入すると${influencername}さんに15%入ります`}>
                                        <Button onClick={() => loginClick()} style={{backgroundColor:"#ff7c21", color:"#fff", margin:"auto", width:172, height:52, borderRadius:"12px"}}>購入手続きへ</Button>
                                    </LightTooltip>
                                    {uid !== createrId && (
                                        <div style={{display:"flex", maxWidth:100, margin:"10px auto auto 0"}}>
                                            <Tooltip title="Twitterへシェア">
                                                <a href={`http://twitter.com/share?url=${BASE_URL}&text=${username}さんの作品｜『${title}』&hashtags=Pallet&screen_name=${screen_name}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                                    <IconButton>
                                                        <TwitterIcon style={{color:"#1DA1F2"}}/>
                                                    </IconButton>
                                                </a>
                                            </Tooltip>
                                            <Tooltip title="リンクをコピー">
                                                <IconButton onClick={() => copyTextClipboard()}>
                                                    <FileCopyIcon/>                            
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                            )}
                            <Dialog open={loginOpen} onClose={loginClose}>
                                <DialogTitle>会員登録後ダウンロードが可能になります。</DialogTitle>
                                <DialogActions>
                                    <Button onClick={() => dispatch(push('/i_art_detail_home/' + id))} style={{backgroundColor:"#ff7c21", color:"#fff",  borderRadius:"12px"}}>会員登録する</Button>
                                </DialogActions>
                            </Dialog>
                        </>              
                    )}
                    {uid !== createrId && price === 0 &&  (
                        <div style={{display:"flex", maxWidth:100, margin:"10px 0 auto auto"}}>
                            <Tooltip title="Twitterへシェア">
                                <a href={`http://twitter.com/share?url=${BASE_URL}&text=${username}さんの作品｜『${title}』&hashtags=Pallet&screen_name=${screen_name}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                    <IconButton>
                                        <TwitterIcon style={{color:"#1DA1F2"}}/>
                                    </IconButton>
                                </a>
                            </Tooltip>
                            <Tooltip title="リンクをコピー">
                                <IconButton onClick={() => copyTextClipboard()}>
                                    <FileCopyIcon/>                            
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                    <div className='module-spacer--small'/>
                    <div style={{backgroundColor:"#FEF8F5", borderRadius:"12px", border:"solid 1px #fafafa", textAlign:"left"}}>
                        <p style={{marginLeft:"20px", fontSize:12}}>壁紙や待ち受け画面にオススメです。</p>
                        {useCase === true ? (
                            <p style={{marginLeft:"20px", fontSize:12}}>この作品はSNSのアイコンやヘッダーへの使用はできません。</p>
                        ):(
                            <p style={{marginLeft:"20px", fontSize:12}}>この作品はSNSのアイコンやヘッダーへの使用が可能です。</p>
                        )}
                    </div>
                     {uid !== userId && uid !== createrId && signedIn === true && (
                        <div style={{textAlign:"right", maxWidth:"500px", margin:"0 0 0 auto"}}>
                            <Tooltip title="この作品を報告する">
                                <IconButton onClick={handleAlert}>
                                    <NewReleasesIcon style={{color:"red"}}/>
                                </IconButton>
                            </Tooltip>
                            <Dialog open={alertOpen} onClose={alertClose}>
                                <DialogTitle>この作品を報告する</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        報告理由を教えてください
                                    </DialogContentText>
                                    <TextField
                                    value={alert}
                                    onChange={inputAlert}
                                    label="報告理由"
                                    margin='dense'
                                    fullWidth={true}
                                    multiline={true}
                                    rows={4}
                                    variant="outlined"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => sendAlert(alert)} style={{backgroundColor:"red", color:"#fff", borderRadius:"12px"}}>報告する</Button>
                                    <Button onClick={alertClose}>閉じる</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )}
                </Grid>
                <Dialog open={copyok} onClose={copyClose}>
                    <DialogTitle>クリップボードにリンクをコピーしました</DialogTitle>
                    <DialogActions>
                        <Button style={{backgroundColor:"#808080", color:"#fff", borderRadius:"12px"}} onClick={copyClose}>閉じる</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={downloadOpen} onClose={downloadClose} fullWidth={true} maxWidth="md">
                    <div className="dialog_detail_box">
                        {width < height && (           
                            <>
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画面" className="dialog_detail_height" onContextMenu={(e) => e.preventDefault()}/>                            
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>                 
                        )}
                        {width > height && (
                            <>
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="dialog_detail_width" onContextMenu={(e) => e.preventDefault()}/>
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>
                        )}
                        {width === height && (          
                            <>
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="dialog_detail_square" onContextMenu={(e) => e.preventDefault()}/>
                                {/* <p className='detail_watermark8'>sample</p>
                                <p className='detail_watermark9'>sample</p>
                                <p className='detail_watermark10'>sample</p> */}
                            </>                  
                        )}
                    </div>
                    <p style={{margin:"auto", fontSize:"14px", padding:10}}>sampleマークは削除された状態でダウンロードされます。<br/>スマホでのご利用の場合は、ダウンロード→表示→長押しで保存してください。</p>     
                    {/* <p style={{padding:"0 10px"}}>スマホで閲覧中の場合はダウンロード→表示→長押しで保存してください </p> */}
                    <DialogActions>
                        <Button style={{backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px"}} startIcon={(<GetAppIcon/>)} onClick={() => finishedProductDownload(img)}>ダウンロードする</Button>
                        <Button style={{backgroundColor:"#808080", color:"#fff",  borderRadius:"12px"}} onClick={downloadClose}>閉じる</Button>
                    </DialogActions>
                </Dialog>                                
            </Grid>            
            <div className='module-spacer--medium'/>
            <Divider/>
            <div className='module-spacer--medium'/>
            <h3 style={{textAlign:"left", marginLeft:20}}>関連する作品</h3>
            <Grid style={{alignItems:"stretch", display:"flex", flexWrap:"wrap", justifyContent:"space-around"}} onContextMenu={(e) => e.preventDefault()} >
                {arts.length > 0 && (
                    arts.map(art => (
                        <div style={{margin:0}} key={art.id}>
                            <ArtsShowDetail
                                id={art.id}  userId={art.artId} createrÎd={art.createrId} uid={uid}
                            />
                        </div>
                    ))
                )}
            </Grid>
            <br/>
            <div className="module-spacer--medium"/>
            {/* <GrayButton
              label="作品一覧に戻る"
              onClick={() => dispatch(push('/art_request/all/' + requestId))}
            />            */}
            <div className="module-spacer--medium"/>
        </div>
     </>
    )
}

export default ArtDetail