import React,{useState, useEffect} from 'react'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import {db} from '../../firebase'
import noImage from '../../assets/noImage.png'
import {Avatar } from '@material-ui/core'
import newIcon from '../../assets/new.png'

const ArtsShowDetail = (props) => {
    const influencerId = props.userId    
    const id = props.id
    const uid = props.uid
    const dispatch = useDispatch()

    const [img, setImg] = useState()
    const [look, setLook] = useState()
    const [price, setPrice] = useState(0)

    // const [name, setName] = useState('')
    // const [icon, setIcon] = useState('')

    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setLook(data.look)
            setPrice(data.price)
            if(data.img){
                setImg(data.img[0].id)
            }
            // db.collection('users').doc(data.artId).get().then(snapshot => {
            //     const user = snapshot.data()
            //     setName(user.username)
            //     if(user.icons === ""){
            //         setIcon(noImage)
            //     }
            //     if(user.icons[0].path){
            //         setIcon(user.icons[0].path)
            //     }
            // })
        })
    },[id])

    // const [check, setCheck] = useState()    

    // useEffect(() => {
    //     const unsubscribe = db.collection('arts').doc(id).collection('favorites').doc("favorites").onSnapshot(doc => {
    //         const data = doc.data()
    //         if(data){
    //             setCheck(true)
    //         }else{
    //             setCheck(false)
    //         }
    //     })
    //     return () => unsubscribe()
    // },[id])

    const clickDetail = () => {
        db.collection('arts').doc(id).set({look:true}, {merge:true}).then(() => {
            dispatch(push('/art_detail/' + id))
            setLook(true)
        })
    }

    return(
        <div className="art_show_root">
            <div className="art_detail_button">
                {look === false && influencerId === uid && (
                    <>
                        <Avatar style={{width:30, height:30, backgroundColor:"transparent", position:"absolute"}}>
                            <img style={{width:30, height:30}} src={newIcon} alt="p"/>
                        </Avatar>
                    </>
                )}
                {influencerId === uid ? (
                    <>
                        <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="p" className='art_show_img' style={{objectFit:"cover",cursor:"pointer"}} onClick={() => clickDetail()}/>                        
                    </>
                ):(
                    <>
                        <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="p" className="art_show_img"  style={{objectFit:"cover", cursor:"pointer"}} onClick={() => dispatch(push('/art_detail/' + id))}/>
                        
                    </>
                )}
                {influencerId !== uid &&(
                    <div className="art_detail_price">
                        {price === 0 ? (
                            <p style={{margin:0, width:"30px"}}>無料</p>
                        ):(
                            <p style={{margin:0}}>¥{price}</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ArtsShowDetail