import React,{useState, useEffect} from 'react'
import {Avatar, Tooltip} from '@material-ui/core'
import {push} from 'connected-react-router'
import {useDispatch} from 'react-redux'
import {db} from '../../firebase'
import noImage from '../../assets/noImage.png'

const ArtsCard = (props) => {

    const id = props.id
    const dispatch = useDispatch()

    const [img, setImg] = useState()

    const [icons, setIcons] = useState()
    const [name, setName] = useState('')

    const [check, setCheck] = useState()

    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()

            db.collection('users').doc(data.artId).get().then(snapshot => {
                const userData = snapshot.data()
                setName(userData.username)
                if(userData.icons[0].path){
                    setIcons(userData.icons[0].path)
                }else{
                    setIcons(noImage)
                }
            })
            if(data.img){
                setImg(data.img[0].id)
            }
        })
        const unsubscribe = db.collection('arts').doc(id).collection('favorites').doc('favorites').onSnapshot(doc => {
            const data = doc.data()
            if(data){
                setCheck(true)
            }else{
                setCheck(false)
            }
        })
        return () => unsubscribe()
    },[id])

    return (
        <div className="art_show_root">
            <div className="art_detail_button" >
               <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} className="art_show_img" alt="p" onClick={() => dispatch(push('/detail/' + id))} style={{objectFit:"cover", cursor:"pointer"}}/>
               {/* {check === true &&(
                   <Tooltip title={`${name}さんにダウンロードされました`}>
                    <Avatar className="star_button">
                        <img src={icons} alt="P" style={{width:40, height:40, objectFit:"cover"}}/>
                    </Avatar>
                   </Tooltip>
               )}                */}
            </div>
        </div>
    )
}


export default ArtsCard