import React from 'react'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    button:{
        backgroundColor:"#26cd18",
        fontSize:16,
        height:48,
        marginTop: 16,
        marginBottom: 16,
        width:256,
        color:"white",
        borderRadius:"12px"
    }
})
const SecondaryButton = (props) => {
    const classes = useStyles()
    return(
        <Button className={classes.button} variant="contained" onClick={() => props.onClick()}>
            {props.label}
        </Button>
    )

}

export default SecondaryButton