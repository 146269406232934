import { CircularProgress } from '@material-ui/core'
import React, {useEffect} from 'react'
import { auth } from '../firebase'
import { useDispatch } from 'react-redux'
import {push} from 'connected-react-router'

const ArtDetailLoading = () => {
    const dispatch = useDispatch()
    const id = window.location.pathname.split('/art_detail_loading/')[1]
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){                
                return dispatch(push('/art_detail/' + id))                             
            }
        })
    })

    return (
        <div className='center'>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <CircularProgress/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
        </div>
    )
}

export default ArtDetailLoading