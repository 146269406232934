import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Drawer} from '@material-ui/core'
import logo from "../assets/logo.png"
import React, {useState, useEffect, useCallback} from 'react'
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import MenuIcon from '@material-ui/icons/Menu';
import {Button} from '@material-ui/core'
import { Helmet } from 'react-helmet-async'
import {db, auth} from '../firebase'
import noImage from '../assets/noImage.png'
import {IconButton, ListItemText, List, ListItem} from '@material-ui/core'
import { ClosableDrawer } from '../components/Header';

const Rules = () => {
    const [uid, setUid] = useState('')
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
            }
        })
    })

    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])

    const dispatch = useDispatch()

    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        setWidth(window.innerWidth)
    })
    

     
    const [open, setOpen] = useState(false)
 
    const handleDrawerToggle = useCallback((event) => {
      if (event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')){
              return;
          }
         setOpen(!open)
    }, [setOpen,open])

    return (
        <>
             <Helmet
            title="Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        {signedIn === true ? (
                            <>
                               <ClosableDrawer icons={icons} uid={uid} notifis={notifis} category={category} />
                            </>
                        ):(
                            <>
                                
                                <img src={logo} alt="logo" width="128px" style={{cursor:"pointer"}} onClick={() => dispatch(push('/'))}/>                                
                                <div className="header_iconButton" >
                                    {width > 500 ? (
                                        <div style={{display:"flex"}}>
                                        {/* <Button className="home_button" style={{fontSize:"13px", marginRight:"10px", backgroundColor:"#f0f0f0", borderRadius:"12px"}} ><Typography className="home_button" style={{fontWeight:"bold", marginRight:"10px"}}>コレクター登録</Typography></Button> */}
                                        <p style={{fontWeight:"bold", fontSize:"14px", marginLeft:50}} >コレクター登録</p>
                                        <p className="home_button" style={{marginLeft:50}} onClick={() => dispatch(push('/i_home'))}>推し事登録</p>
                                        {/* <Button  style={{fontSize:"13px", borderRadius:"12px", marginRight:"10px"}} onClick={() => dispatch(push('/i_home'))}><Typography className="home_button"style={{fontWeight:"bold"}}>イラストレーター登録</Typography></Button> */}
                                        <p className='home_button' style={{marginLeft:50}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</p>
                                        {/* <Button onClick={() => dispatch(push('/influencer/signin'))} style={{color:"white", backgroundColor:"#808080", borderRadius:"12px"}}>ログイン</Button> */}
                                    </div>
                                    ):(
                                        <>
                                          <IconButton onClick={(event) => handleDrawerToggle(event)}><MenuIcon/></IconButton>
                                          <Drawer open={open} onClose={handleDrawerToggle} variant="temporary" anchor="right" ModalProps={{keepMounted: true}}>
                                              <List>
                                              <ListItem button onClick={() => dispatch(push('/home'))}>
                                                    <ListItemText primary={"推し事登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                                  <ListItem button onClick={() => dispatch(push('/i_home'))}>
                                                    <ListItemText primary={"イラストレーター登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                              </List>
                                              <Button style={{color:"white", backgroundColor:"#808080", width:"80px", margin:"0 auto", borderRadius:"12px"}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</Button>
                                          </Drawer>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div style={{maxWidth:"800px", margin:"auto"}} >
                <h2 className="center">「Pallet」サービス利用規約</h2>
                <div className="module-spacer--medium"/>
                <p>2022年4月2日制定</p>
                <p><a href="https://pallet.fun/policy" target="_blank"  rel="noreferrer noopener">プライバシーポリシー</a><br/><a href="https://pallet.fun/trade" target="_blank"  rel="noreferrer noopener">特定商取引法に基づく表示</a></p>
                <p>
                    &nbsp;&nbsp;  本利用規約（以下「本規約」と言います。）には、株式会社PotLand（以下、「当社」といいます。）が開発・運営する推しからのお題にイラストで回答するサービス「Pallet」（以下、「本サービス」いいます。）の提供条件及び当社と登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
                </p>
                <p>
                    &nbsp;&nbsp;  本サービスのユーザーによる本規約への同意・承諾は、同ユーザーに本サービスをご利用いただくための前提条件であり、万一、同ユーザーが本規約の一部にでも違反した場合、以降の本サービスのご利用を予告なくお断りさせていただく場合があります。
                    <br/>また、本サービスのユーザーが未成年者（20歳未満）等の制限行為能力者である場合、必ず法定代理人（保護者・親権者等）の方の同意を得てから、本規約への同意・承諾を行ってください。
                </p>
                <p>
                    &nbsp;&nbsp;  本サービスは、ユーザーである動画配信者とイラストレーターがイラスト制作及び使用許諾契約を締結するもので、当社は自らこれらの取引を行うものではなく、取引の委託を受けるものではありません。
                </p>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第1条(適用)</h4>
                <ol>
                    <li>
                        本規約は、本サービスの提供条件及び本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
                    </li>
                    <li>
                        本サービスに関し、上記本サイトの記載をはじめ、関連するウェブサイトを通じて提示する諸規定・ルールも本規約の一部を構成します。なお、本規約の内容と関連する諸規定等の内容が異なる場合、本規約の規定を優先します。
                    </li>
                    <li>
                        本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
                    </li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第2条(定義)</h4>
                
                <ol>
                    <p>本規約において、各用語は以下の通り定義されます。</p>
                    <li>「登録ユーザー」</li>
                    <p>第3条（登録）に基づいて本サービスの利用者としての登録がなされた者をいいます。</p>
                    <li>コレクター</li>
                    <p>登録ユーザーのうち、登録の際に動画配信者としてイラストの制作に関連するお題またはイラストのダウンロードを目的として本サービスを利用する者をいいます。</p>
                    <li>本イラストレーター</li>
                    <p>登録ユーザーのうち、登録の際にイラストレーターとして動画配信者に対してイラスト制作に関連するお題を受け、イラストを提供することを目的として本サービスを利用する者をいいます。</p>
                    <li>本イラスト</li>
                    <p>本イラストレーターが制作して本サービスにアップロードしたのイラストをいいます。</p>
                    <li>ダウンロード</li>
                    <p>本サービス所定の手続に従い、本イラストについて本規約に定める範囲の使用許諾を受ける契約をコレクターが本イラストレーターと締結することをいいます。</p>
                    <li>投稿</li>
                    <p>本イラストについて本規約に定める範囲の使用許諾をする契約を本イラストレーターがコレクターと締結することをいいます。</p>
                    <li>購入</li>
                    <p>本イラストにつき、お題を投稿したコレクター以外の者が有償で使用許諾を受けることをいいます。</p>

                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第3条(登録)</h4>
                <ol>
                    <li>本サービスの利用希望者（以下、単に「利用希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報を当社が定める方法により提供することにより、本サービスの利用登録申請を行うことができます。この際、利用希望者は正確かつ正式な情報を提供し利用登録申請を行うものとします。</li>
                    <li>当社は、当社が定める基準に従い、前項の利用登録申請に基づき登録の可否を判断し、これが認められた場合に利用登録を行います。利用登録が完了した場合、当社が定める方法（電子メール等の電磁的方法によりますが、これに限られません。）により利用希望者に通知されます。</li>
                    <li>本サービスの利用登録は、利用登録完了の通知が利用希望者に到達した時点で完了したものとします。登録ユーザーは、この時点より、本規約に従って本サービスを利用できます。</li>
                    <li>当社は、登録希望者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否し、又は登録を取り消すことがあり、またその理由について一切開示義務を負いません。</li>
                    <ol>
                        <li>当社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</li>
                        <li>未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合</li>
                        <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合</li>
                        <li>過去当社との契約に違反した者又はその関係者であると当社が判断した場合</li>
                        <li>過去に本規約に定める登録抹消等に定める措置を受けたことがある場合</li>
                        <li>その他、登録を適当でないと当社が判断した場合</li>
                    </ol>
                    <li>登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。登録事項の変更がなかったために登録ユーザーに不利益が生じたとしても、当社は一切の責任を負いません。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第4条(インターネットの利用)</h4>
                <ol>
                    <li>本サービスのご利用にあたっては、当社及び当社が契約する運営サーバー等との接続のための、オンライン環境が必要になる場合があります。オンライン環境や接続に必要となる機器の設置、設定、通信環境の構築、費用の支出については、登録ユーザーの責任と負担において行っていただくものとします。</li>
                    <li>当社は、本規約に明示する場合の他、オンライン環境の不使用又は接続不能等、本サービスの不使用、又は使用不能を理由として、既に登録ユーザーが負担した費用の返還には応じません。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第5条(パスワード及びユーザーIDの管理)</h4>
                <ol>
                    <li>登録ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
                    <li>パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとし、当社は故意又は重過失がない限り一切の責任を負いません。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第6条(サービス利用料金及び支払い方法)</h4>
                <ol>
                    <li>本サービスの利用登録、利用開始にあたっては、第4条（インターネットの利用）に定める費用を除き、登録ユーザーには発生しません</li>
                    <li>前項にかかわらず、本イラストレーターは当社が定める方式によりイラストの販売をした際には本サービスが定める手数料を当社又は第三者が運営する決済サービス「Stripe Connect」（https://stripe.com/jp 以下、「Stripe」といいます。）に支払い、又はイラストを購入した場合は当該イラストの代金を支払うものとします。<br/>対価の支払時期及び支払方法についてはStripeが定めるところに従うものとし、登録ユーザーは別途Stripeが定める利用規約等の規約・ルールに同意する必要があります。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第7条(本イラストの募集と購入)</h4>
                <ol>
                    <li>コレクターは、本サービス所定の手続に従い、本イラストの制作を関連するお題を本イラストレーターに対して投稿することができます。</li>
                    <li>コレクターが投稿したイラスト制作に関連するお題に対して、投稿されたイラストを以下の手続きで購入することができます。</li>
                    <ol>
                        <li>所定の画面に、本イラストレーターからのお題に対して本イラストが表示されます。</li>
                        <li>お題を投稿したコレクターは、表示された本イラストをダウンロードすることができます。コレクターは、このイラストを本規約に定める諸条件に従って使用することができます。</li>
                        <li>本イラストのダウンロードに際し、コレクターは本イラストレーターに対して本イラストの修正、リテイク等を行うことはできません。</li>
                        <li>本イラストレーターから投稿のあった本イラストは別のユーザーが購入することができます。</li>
                    </ol>                
                </ol>                
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第8条(本イラストの投稿と販売)</h4>
                <ol>
                    <li>本イラストレーターは、本サービス所定の手続に従い、本イラストの制作に関連するお題に対して、制作したイラストを用いて投稿することができます。</li>
                    <li>前項の投稿を行う際、本イラストレーターは本イラストの別ユーザーからの購入対価の設定を行うことができます。</li>
                    <li>コレクターが、本イラストレーターが投稿した本イラストをダウンロードした場合、本サービス所定の手続により本イラストがコレクターに提供されます。</li>                    
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第9条(本イラストの第三者購入)</h4>
                <ol>
                    <li>本サービスの定める手続に従い、購入が可能であるとされたものについて本イラストを別の登録ユーザーが購入することができます。</li>
                    <li>前項に従い購入した本イラストは、制作した本イラストレーターとの間で当該イラストの使用許諾契約が締結されたものとします。購入した登録ユーザーは当該イラストを第13条3項の規定に従い使用することができます。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第10条(取引成立と支払い)</h4>
                <ol>
                    <li>お題を投稿したコレクターによってダウンロードされた時点で本イラストの使用許諾契約が成立します。また第三者は購入時に第6条2項及び本サービスの定めるところに従い本イラストの対価を支払うものとします。</li>
                    <li>本サービスの利用にあたり、購入後のキャンセルはできないものとします。 本イラストに瑕疵（データが破損していて表示できない場合等に限ります。）がある場合は、本イラストレーターが責任を負うものとし、本イラストレーターの責任及び費用により、修正、交換等の対応を行うものとします。</li>
                    <li>登録ユーザーが本サービスに入力した決済手段又は金融機関の情報が第三者に利用されたこと若しくは入力情報の内容が不正確であったこと又は当社が本条に基づく措置を行ったこと若しくは行わなかったことによって登録ユーザーに生じた損害に関して、当社の故意又は過失に起因する場合を除き、当社は責任を負わないものとします。</li>
                    <li>本イラストに関する使用許諾契約は当社が指定する第三者と本イラストレーターの間で締結されるものであり、対価の不払いなどの事態が生じた場合、当該契約を締結した各当事者の間で紛争を処理するものとし、当社に責任がある場合を除いて当社はその紛争に関し登録ユーザーに対して責任を負いません。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第11条(手数料)</h4>
                <ol>
                    本イラストレーターは本イラストを販売した場合、当社及びStripeに対して手数料を支払うものとします。手数料の料率及び支払方法については別途当社並びにStripeが定めるところに従うものとします
                </ol>                
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第12条(第三者権利の侵害)</h4>
                <ol>
                    本イラストレーターは、本イラストの制作にあたり、第三者の著作権その他の知的財産権のほか、構図の詳細なトレース等、第三者の権利を侵害し又はその疑いのある行為をしてはならないものとします。
                </ol>                    
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第13条(知的財産権)</h4>
                <ol>
                    <li>本イラストの著作権はすべて制作した本イラストレーターに留保されます。</li>
                    <li>本イラストがダウンロードされた場合、本イラストはダウンロードしたコレクターが自らYoutube等の動画配信プラットフォーム内の動画やページ内で使用期限なく使用することができるほか、twitterなどのソーシャルネットワーキングサービスにおいてこれらの動画の告知・宣伝等のために使用することができます。これらの使用について追加費用を支払う必要はありません。</li>
                    <li>本イラストを購入した者は本サービスが定めるところのみにダウンロードした本イラストを使用でき、第三者に使用を許諾したり、本イラストに関する各種権利を第三者に譲渡、移転、担保設定、販売、その他の処分をすることはできません。</li>
                    <li>第1項に定める権利を除き、本サービスに関する知的財産権はすべて当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</li>
                    <li>本イラストレーターは、本イラストをダウンロードしたコレクター、当社又は当社が指定する第三者に対して著作者人格権を行使しないものとします。</li>
                    <li>本サービスにおける本イラストの購入は当社が指定する第三者と本イラストレーターとの本イラスト使用許諾契約の締結であるため、本イラストが著作権その他の第三者の権利を侵害していないことについて、当社は一切の責任を負いかねます。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第14条(トラブルの際の情報提供)</h4>
                <ol>
                    <li>本サービスにおいて本イラストの使用を有償で許諾する契約は、購入した者と本イラストレーターとの間で行われるものです。そのため、本イラストレーターは、特定商取引法に従い、事業者として一定の表示を行う義務があります。そこで本サービスの利用において、制作物に第三者の権利侵害があった等のトラブルが生じた場合、購入した者はダウンロードした本イラストを制作した本イラストレーターの氏名、住所及び電話番号等の特定商取引法が表示を求める情報の提供を当社に請求することができます。</li>
                    <li>当社は前項の請求があった際、その趣旨が特定商取引法に基づく表示の開示請求でない場合を除き、請求を行った者に対して本イラストレーターの氏名、住所及び電話番号を開示します。本イラストレーターは、上記の理由によりこれらの情報が開示されることを同意するものとします。</li>
                    <li>前2項の規定に関わらず、コレクター又は本イラストの購入者が本イラストレーターに対して損害を与えたことが当社の合理的な判断により認められる場合、当社は相手方たる本イラストレーターの求めに応じてこれらの者の氏名、住所及び電話番号等の連絡先を開示することがあります。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第15条(禁止事項)</h4>
                <ol>
                    <li>登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又は該当すると当社が判断する行為をしてはなりません。</li>
                    <ol>
                        <li>本規約並びに本サービスの利用について定めた規約及びルールに違反する行為</li>
                        <li>法令に違反する行為又は犯罪行為に関連する行為</li>
                        <li>当社、登録ユーザー、本サービスの他の利用者又はその他の第三者に対する詐欺又は脅迫行為</li>
                        <li>公序良俗に反する行為</li>
                        <li>当社、登録ユーザー、本サービスの他の利用者又はその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為</li>
                        <li>本サービスを通じ、以下に該当し、又は該当すると当社が判断する情報を当社、コレクター、又は本サービスの他の利用者に送信すること</li>
                        <ol>
                            <li>過度に暴力的又は残虐な表現を含む情報</li>
                            <li>コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</li>
                            <li>当社、本サービスの他の利用者又はその他の第三者の名誉又は信用を毀損する表現を含む情報</li>
                            <li>過度にわいせつな表現を含む情報</li>
                            <li>差別を助長する表現を含む情報</li>
                            <li>自殺、自傷行為を助長する表現を含む情報</li>
                            <li>薬物の不適切な利用を助長する表現を含む情報</li>
                            <li>反社会的な表現を含む情報</li>
                            <li>チェーンメール等の第三者への情報の拡散を求める情報</li>
                            <li>他人に不快感を与える表現を含む情報</li>
                        </ol>
                        <li>本サービスのネットワーク又はシステム等に過度な負荷をかける行為</li>
                        <li>当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為</li>
                        <li>本サービスの運営を妨害するおそれのある行為</li>
                        <li>当社のネットワーク又はシステム等への不正アクセス</li>
                        <li>第三者に成りすます行為</li>
                        <li>本サービスの他の利用者のID又はパスワードを利用する行為</li>
                        <li>当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、又は営業行為</li>
                        <li>本サービスの他の利用者の情報の収集</li>
                        <li>当社、本サービスの他の利用者又はその他の第三者に不利益、損害、不快感を与える行為</li>
                        <li>当社ウェブサイト上その他の場所で当社が掲載する本サービス利用に関するルールに抵触する行為</li>
                        <li>面識のない異性との出会いを目的とした行為</li>
                        <li>前各号の行為を直接又は間接に惹起し、又は容易にする行為</li>
                        <li>前各号の行為を試みること</li>
                        <li>その他、当社が指定し又は不適切と判断する行為</li>
                    </ol>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第16条(本サービスの停止等)</h4>
                <ol>
                    当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部又は一部の提供を停止又は中断することができるものとします。
                    <li>本サービスに係るコンピューター・システムの点検又は保守作業を緊急に行う場合</li>
                    <li>コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</li>
                    <li>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
                    <li>その他、当社が停止又は中断を必要と判断した場合</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第17条(登録抹消等)</h4>
                <ol>
                    <li>当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、登録ユーザーのアカウントを削除し、当該登録ユーザーについて本サービスの利用を一時的に停止し、又は登録ユーザーとしての登録を抹消することができます。</li>
                    <ol>
                        <li>本規約のいずれかの条項に違反した場合</li>
                        <li>登録事項に虚偽の事実があることが判明した場合</li>
                        <li>支払停止もしくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</li>
                        <li>本サービスの利用に関連して利用される決済サービス会社が、決済の無効又は取消を判断した場合</li>
                        <li>登録ユーザーの故意又は過失により、第三者によるID等の不正利用が行われたとき。又はそのおそれがあると当社が判断した場合</li>
                        <li>6ヶ月以上本サービスの利用がない場合</li>
                        <li>当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</li>
                        <li>第3条第4項（登録）各号及び第15条（禁止事項）各号に該当する場合</li>
                        <li>その他、当社が本サービスの利用又は登録ユーザーとしての登録の継続を適当でないと判断した場合</li>
                    </ol>
                    <li>前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対してすべての債務の支払を行わなければなりません。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第18条(退会)</h4>
                <ol>
                    <li>登録ユーザーは、当社所定の手続の完了により、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。</li>
                    <li>仮に登録ユーザーが退会した場合であっても、本サービスの利用に基づいて締結した著作権の使用許諾契約は、個別にコレクターと本イラストレーターとが条件変更等を行う合意を行わない限り、退会後であっても存続するものとします。</li>
                    <li>退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対してすべての債務の支払を行わなければなりません。</li>
                    <li>退会後の利用者情報の取扱いについては、第22条（利用者情報の取扱い）の規定に従うものとします。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第19条(本サービスの内容の変更、終了)</h4>
                <ol>
                    <li>当社は、当社の都合により、本サービスの内容を変更することができます。</li>
                    <li>当社は、30日の予告期間をもってお客様に通知又は告知の上、本サービスの提供を終了することができるものとします。</li>
                    <li>第1項の通知又は告知は、当社が本サイト上に告知した時ただちに経過することをもって効力を生ずるものとします。</li>
                    <li>前二項の定めに関わらず、当社の責めによらない事由により本サービスの提供が困難となった場合は、本サービスの終了後速やかに本サイト上において本サービスの提供終了の理由を明らかにする事により、提供を終了することができるものとします。</li>
                    <li>当社は、本サービス提供の終了の際、本条の手続を経ることにより、法令に定める事項を除き、本サービスの終了にともなうお客様からのいかなる請求からも免責されるものとします。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第20条(保証の否認及び免責)</h4>
                <ol>
                    <li>当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令又は業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。</li>
                    <li>当社は、本サービスに関して登録ユーザーが被った損害につき、過去12ヶ月間に登録ユーザーが支払った又は支払を受けた対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。</li>
                    <li>本サービスに関連して登録ユーザーと他の登録ユーザー又は第三者との間において生じた取引、連絡、紛争等については、登録ユーザーが自己の責任によって解決するものとします。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第21条(秘密保持)</h4>
                <ol>
                    登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第22条(利用者情報の取り扱い)</h4>
                <ol>
                    <li>当社による登録ユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシーの定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。</li>
                    <li>当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第23条(本規約等の変更)</h4>
                <ol>
                    <li>当社は必要に応じて本規約を変更することができ、当該変更については、当社が本サービス内に掲載又はメール等の電磁的方法で通知した時点から起算して24時間後に効力を生じ、且つ、その後に初めて本サービスをご利用いただいた時点で、当該変更をご承諾いただいたものとします。ご理解のうえ、定期的に本サービスから通知をご確認ください。</li>
                    <li>前項の変更内容にご承諾いただけない場合は、直ちに本サービスのご利用をお止めください。この場合でも、名目のいかんを問わず、既に支出された費用等の返還せず、損害賠償請求等の請求にも応じかねます。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第24条(連絡・通知)</h4>
                <ol>
                    <li>本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。</li>
                    <li>当社が登録事項に含まれるメールアドレスその他の連絡先に連絡又は通知を行った場合、登録ユーザーは当該連絡又は通知を受領したものとみなします。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第25条(サービス利用契約上の地位の譲渡等)</h4>
                <ol>
                    <li>登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</li>
                    <li>当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第26条(分離可能性)</h4>
                <ol>
                    本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第27条(準拠法及び管轄裁判所)</h4>
                <ol>
                    <li>本規約及びサービス利用契約の準拠法は日本法とします。</li>
                    <li>本規約又はサービス利用契約に起因し、又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
                </ol>
                <p style={{textAlign:"right"}}>以上</p>
                <div className="module-spacer--medium"/>
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default Rules