import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    size:{
        margin:"auto",
        width:"60%",
        height:"auto"
    }
}))

const ArtEditImagePreview = (props) => {
    const classes = useStyles()

    return(
        <div className="u-text-center">
            <img alt="file" src={props.path} className={classes.size} key={props.index} onClick={() => props.onClick()}/>
        </div>
    )
}

export default ArtEditImagePreview