import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider} from '@material-ui/core'
import logo from "../../assets/logo.png"
import { MypageHeader } from '../../components/Header'
import React, {useCallback, useState} from 'react'
import { useDispatch } from 'react-redux'
import { GrayButton, PrimaryButton, TextInput } from '../../components/UIkit'
import {mypageResetPassword} from '../../reducks/users/operations'
import { Helmet } from 'react-helmet-async'
import {push} from 'connected-react-router'

const MypageInfluencerReset = () => {
    const id = window.location.pathname.split('/mypage_influencer_reset/password/')[1]
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    },[setEmail])

    const category = "influencer"

    return (
        <>
            <Helmet
                title="ログインフォーム/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                    <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/mypage_home/' + id))} style={{cursor:"pointer"}}/>
                        <div className="header_iconButton">
                            <MypageHeader id={id} key={id}/>
                        </div>                    
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className='module-spacer--medium'/>
            <div className='center' style={{maxWidth:360}}>
                <h2>パスワードをリセットする</h2>
                <div className='module-spacer--small'/>
                <div style={{ borderRadius:"4px", width:"100%", height:40, display:"inline-flex"}}>
                    <input value={email} onChange={inputEmail} type="email" style={{backgroundColor:"#f0f0f0",paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="メールアドレス"/>
                </div>  
                <br/>
                <div className='module-spacer--medium'/>
                <PrimaryButton
                    label="パスワードをリセットする"
                    onClick={() => dispatch(mypageResetPassword(email, category, id))}
                />
                <div className='module-spacer--medium'/>
                <GrayButton
                    label="ログインフォームへ"
                    onClick={() => dispatch(push('/mypage_detail_influencer/signin/' + id))}
                />
            </div>
            <div className='module-spacer--medoum'/>
            <div className='module-spacer--medium'/>
        </>
    )
}

export default MypageInfluencerReset