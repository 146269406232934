import { SecondaryButton } from "../components/UIkit";
import React, {useState, useEffect, useCallback} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {PrimaryButton, GrayButton} from '../components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet-async'
import { getUserId } from '../reducks/users/selectors'
import { db } from '../firebase'
import noImage from '../assets/noImage.png'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Button, Dialog, DialogTitle,} from '@material-ui/core'
import { ArtImageArea } from '../components/UIkit'
import { fixRequest } from '../reducks/requests/operations'
import { ClosableDrawer } from "../components/Header";
import header from '../assets/noimageheader.png'
const useStyles = makeStyles((theme) => ({
    title_around :{
        margin:"auto",
        maxWidth:"780px",
        backgroundColor:"#F2AA1D",
        borderRadius:"12px",
        border:"solid"
    },
    menuTitle: {
        fontWeight: 'bold',
        paddingTop: '32px',
        // paddingBottom: '2px',
        textAlign:"left",
        [theme.breakpoints.down('sm')]:{
            fontSize:"20px",
            marginLeft:"15px"
        },
        [theme.breakpoints.up('sm')]:{
            fontSize:"26px",
            marginLeft:"30px",
        }
    },
    required: {        
        color: 'red',
        fontSize: '14px',
        fontWeight: 'normal',
        padding: '4px',
        borderRadius: '4px',
        marginLeft:"5px"
    },
    widthSize:{
        marginRight:10,
        [theme.breakpoints.down('sm')]:{
            width:110
        },
        [theme.breakpoints.up('sm')]:{
        }
    },
    heightSize:{
        [theme.breakpoints.down('sm')]:{
            width:110
        },
        [theme.breakpoints.up('sm')]:{

        }
    },
    titlearound:{
        [theme.breakpoints.down('sm')]:{
            width:"330px",
            margin:"10px"
        },
        [theme.breakpoints.between('sm', "md")]:{
            margin:"auto -10px"
        },
        [theme.breakpoints.up('md')]:{
            margin:"auto -10px auto -10px"
        }
    }
}))

const RequestFix = () => {

    const classes = useStyles()

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)
    
    const id = window.location.pathname.split('/art_request/fix/')[1]

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')
    const [username, setUsername] = useState('')
    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()
            setCategory(data.category)
            setUsername(data.username)
            if(data.icons === ""){
                setIcon(noImage)
            }
            if(data.icons[0].path){
                setIcon(data.icons[0].path)
            }       
        })
    })

    const [title, setTitle] = useState('')    
    const [img, setImg] = useState([])
    const [stop, setStop] = useState(false)
    const [accountId, setAccountId] = useState('')
    const [imageStay, setImageStay] = useState(true)
    useEffect(() => {
        db.collection('requests').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setAccountId(data.accountId)
            setTitle(data.title)
            
            if(data.stop){
                setStop(data.stop)
            }else{
                setStop(false)
            }
            if(data.img[0].path){
                setImg(data.img)
            }else{
                setImageStay(false)
            }
        })
    },[id])

    const inputTitle = useCallback((e) => {
        setTitle(e.target.value)
    },[setTitle])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])

    const stopRequest = useCallback(() => {
        db.collection('requests').doc(id).set({stop:true}, {merge:true}).then(() => {
            dispatch(push('/art_request/all/' + id))
        })
    })

    const startRequest = useCallback(() => {
        db.collection('requests').doc(id).set({stop:false}, {merge:true}).then(() => {
            dispatch(push('/art_request/all/' + id))
        })
    })

    const [must, setMust] = useState("")
    const [errorOpen, setErrorOpen] = useState(false)

    const handleErrorOpen = () => {
        setMust('')
        setErrorOpen(false)
    }

    const [loading, setLoading] = useState(false)
    const [clickAction, setClickAction] = useState(false)
    const saveRequestClick = useCallback((id, title, img) => {        
        if(title === ""){
            setMust('mismatch')
            setErrorOpen(true)
            setClickAction(false)
            return false
        }
        setLoading(true)
        setClickAction(true)
        dispatch(fixRequest(id, title, img, accountId,username)).then(() => {
            setMust('')
            setErrorOpen(false)
        })
        
    })

    const [random, setRandom] = useState([])
    useEffect(() => {
        db.collection('randomText').where('random', "==", true).get().then((snapshots) => {
            const list  = []
            snapshots.forEach(snapshot => {
                const data = snapshot.data()
                list.push(data)
            })
            setRandom(list)
        })
    },[])    

    const randomTextInput = useCallback((random) => {
        const get = random[Math.floor(Math.random() * random.length)]        
        setTitle(get.text)
    })
    

    return (
        <>
            <Helmet
              title="リクエスト作成フォーム/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        <ClosableDrawer uid={uid} notifis={notifis} icons={icon} category={category} />
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div className='center' style={{maxWidth:"780px"}}>
                <h2>お題内容</h2>
                <p className="menuTitle">お題<span style={{fontWeight:"bold", fontSize:"16px", marginLeft:5}}>(30文字以内で)</span><span style={{color:"red", fontSize:"14px", fontWeight:"normal", marginLeft:"5px"}}>※必須</span></p>
                <div className={classes.title_around}>
                    <div style={{maxWidth:"760px",background:"none",  display:"inline-flex", padding:0, position:"relative", flexDirection:"column", verticalAlign:"top", width:"99%", margin:"5px 0"}}>                            
                        <textarea value={title} onChange={inputTitle} type="text" style={{margin:"auto",border:"solid", maxWidth:"760px", borderRadius:"12px", position:"relative",background:"none", backgroundColor:"#fff", font:"inherit", boxSizing:"content-box", resize:"none", fontSize:"24px", fontWeight:"bold"}} area-invalid={false} rows={5} className="request_title_field"></textarea>                            
                    </div>
                </div>                     
                {title.length > 30 &&(
                    <p style={{textAlign:"left"}} className="title_required"><span style={{fontWeight:"bold"}}>[注意]</span>規定文字数を超えています!</p>
                )}
                <div style={{textAlign:"right", maxWidth:"780px", margin:"auto"}}>
                    <Button style={{backgroundColor:"#F2AA1D", color:"#fff", marginTop:10, borderRadius:"12px"}} onClick={() => randomTextInput(random)}>
                        お題おまかせ
                    </Button>
                </div>
                <p className="menuTitle">イメージ画像<span style={{fontWeight:"normal", marginLeft:"5px", color:"#808080", fontSize:"14px"}}>(任意)</span></p>                
                {/* {img.length > 0 && (
                    
                )} */}
                <div>
                    <ArtImageArea image={img} setImage={setImg} title={title} username={username}/>
                </div>                
                <div className="module-spacer--medium"/>
                <PrimaryButton
                    disabled={clickAction}
                    label="更新する"
                    onClick={() => saveRequestClick(id, title, img)}
                />
                {loading === true && (
                    <p>再投稿中です.....</p>
                )}
                {must === "mismatch" && (
                    <Dialog open={errorOpen} onClose={handleErrorOpen}>
                        <DialogTitle>必須項目が未入力です</DialogTitle>
                    </Dialog>
                )}
                <div className='module-spacer--small'/>
                {stop === false ? (
                    <SecondaryButton
                      label="お題をストップする"
                      onClick={() => stopRequest()}
                    />
                ):(
                    <SecondaryButton
                      label="お題を再開する"
                      onClick={() => startRequest()}
                    />
                )}
                <div className="module-spacer--small"/>
                <GrayButton
                  label="戻る"
                  onClick={() => dispatch(push('/art_request/all/' + id))}
                />
            </div>
            <div className='module-spacer--medium'/>
            <div className="module-spacer--medium"/>
        </>
    )
}

export default RequestFix