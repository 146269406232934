import React from 'react'
import {MenuItem, Select, FormControl, InputLabel} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
const useStyles = makeStyles({
    root:{
        minWidth:"200px"
    },
    field:{
        backgroundColor:"#f0f0f0"
    }
})
const AddressSelectBox = (props) => {
    const classes = useStyles()
    return(
        <FormControl className={classes.root} variant="outlined" margin="dense">
            <InputLabel> 県名</InputLabel>
            <Select
              value={props.value}
              className={classes.field}
              onChange={(e) => props.onChange(e.target.value)}
              fullWidth={props.fullWidth}
              size="small"
              label="県名"
            >
                {props.menus.map((menu) => {
                    return <MenuItem value={menu.id} key={menu.id}>{menu.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default AddressSelectBox