import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { db } from '../firebase'
import { AppBar, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import ToolBar from '@material-ui/core/ToolBar'
import { getCategory, getUserId } from '../reducks/users/selectors'
import noImage from '../assets/noImage.png'
import pottin from '../assets/pottins_icon1.png'
import { TwitterButton } from '../components/UIkit'
import GetAppIcon from '@material-ui/icons/GetApp';
import { ClosableDrawer } from '../components/Header'

const DownloadComplate = () => {

    const id = window.location.pathname.split('/download_complate/')[1]

    const selector = useSelector((state) => state)
    const uid = getUserId(selector)
    const category = getCategory(selector)

    const [icon, seticon] = useState()

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                if(data){
                    if(data.icons === ""){
                        seticon(noImage)
                    }
                    if(data.icons[0].path){
                        seticon(data.icons[0].path)
                    }
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscibe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscibe()
    },[uid])

    const [title, setTitle] = useState('')
    const [img, setImg] = useState([])
    const [screen_name, setScreen_name] = useState('')
    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setTitle(data.title)
            if(data.img[0].path){
                setImg(data.img[0].path)
            }
            db.collection('users').doc(data.createrId).get().then(snapshot => {
                const userdata = snapshot.data()
                if(userdata.screen_name){
                    setScreen_name(userdata.screen_name)
                }
            })
        })
    },[id])

    const shareURL = "https://pallet.fun/art_detail/" + id
    
    const [pay, setPay] = useState(false)
    useEffect(() => {
        db.collection('users').doc(uid).collection('downloads').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            if(data){
                setPay(data.pay)
            }
        })
    },[uid, id])
    const [download, setDownload] = useState(false)
    const getURL = () => {
        if(pay === true){            
                const xhr = new XMLHttpRequest()
                xhr.open("GET", img, true)
                xhr.responseType = 'blob'
                xhr.onload = () => {
                    const blob = xhr.response
                    var objectURL = window.URL.createObjectURL(blob)
                    var link = document.createElement("a")
                    document.body.appendChild(link)
                    link.href = objectURL
                    link.download = title
                    link.click();
                    document.body.removeChild(link)
                }
                xhr.send()
                setDownload(true)
        }else{
            alert('不正なアクセスです。')
            return false
        }
    }
    const [shareOpen, setShareOpen] = useState(false)
    const shareClick = () => {
        setShareOpen(true)
    }
    const shareClose = () => {
        setShareOpen(false)
    }
    const [copyok, setCopyOk] = useState(false)

    const copyTextClipboard = () => {
        navigator.clipboard.writeText(shareURL).then(() => {
            setCopyOk(true)
        }).catch(() => {
            alert("クリップボードへのコピーが失敗しました。通信環境を確認の上再度お試しください。")
        })
    }
    return(
        <>
            <Helmet
                title="ダウンロードページ/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className='center' style={{maxWidth:800}}>
                <div className='module-spacer--medium'/>
                <h3>ご購入ありがとうございます！</h3>
                <img src={pottin} alt="hightouch"  style={{marginTop:"-20px", width:"300px", height:"300px"}}/>                
                <br/>
                <Button style={{backgroundColor:"#ff7c21", color:"white", width:"250px", height:"50px", borderRadius:"12px", marginTop:"-20px"}} startIcon={(<GetAppIcon/>)} onClick={() => getURL()}>ダウンロードする</Button>
                <div className='module-spacer--small'/>
                <p >スマホでのご利用の場合は、ダウンロード→表示→長押しで保存してください。</p>
                <div className="module-spacer--medium"/>
                {download === true && (
                    <>
                        <h3>Twitterでお礼を伝えよう</h3>                        
                        <TwitterButton
                            label="共有する"
                            onClick={shareClick}
                        />
                        <Dialog open={shareOpen} onClose={shareClose}>
                            <DialogTitle>SNSに共有しよう！</DialogTitle>
                            {copyok === true && (
                                <DialogContent>
                                    <Typography>
                                        クリップボードにリンクをコピーしました！
                                    </Typography>
                                </DialogContent>
                            )}
                            <DialogContent>
                                ※作品詳細ページのリンクを共有/コピーできます。
                            </DialogContent>
                            <DialogActions>
                            <a href={`http://twitter.com/share?url=${shareURL}&text=素敵な作品をありがとうございます｜『${title}』&hashtags=Pallet&screen_name=${screen_name}`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                                <Button style={{backgroundColor:"#1da1f2", color:"#fff", textTransform:"none", borderRadius:"12px"}}>Twitterへシェア</Button>
                            </a>
                            <Button style={{backgroundColor:"#808080", color:"#fff", borderRadius:"12px"}} onClick={() => copyTextClipboard()}>リンクコピー</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default DownloadComplate