import {db, FirebaseTimestamp} from '../../firebase'
import {push} from 'connected-react-router'
import reactImageSize from 'react-image-size'

const headers = {'Content-Type': 'application/json'}
const BASE_URL = "https://pallet.fun"

export const saveArt = (refid, title, desc, img, requestId, price, accountId, useCase) => {
    
    return async (dispatch, getState) => {

        const timestamp = FirebaseTimestamp.now()
        
        const ref = db.collection('arts').doc()
        const id = ref.id
        const {width, height} = await reactImageSize(img[0].path)
        const response = await fetch(BASE_URL + "/v1/createSampleImg", {
            method:"POST",
            headers:headers,
            body:JSON.stringify({
                imgId:img[0].id,
                width:width,
                height:height,
            })
        })
        const requestdetail = await response.json()        
        const uid = getState().users.uid
        // const accountId = getState().users.account_id
        const data = {
            created_at:timestamp,
            title:title,
            desc:desc,
            createrId:uid, //作った人
            artId: refid, //描かれた人
            img:img,
            id:id,
            imgWidth:width,
            imgHeight:height,
            requestId:requestId,                        
            price:parseInt(price, 10),
            accountId:accountId,                        
            look:false,
            useCase:useCase
        }
        return db.collection('arts').doc(id).set(data, {merge:true}).then(() => {
            // dispatch(push('/art_list/all/' + refid))
            dispatch(push('/art_submission_complate/' + id))
        })
    }
}