import { createStore as reduxCreateStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { connectRouter, routerMiddleware } from "connected-react-router";

import { UserReducer } from "../users/reducers";

export default function createStore(history) {

    const logger = createLogger({
        collapsed:true,
        diff:true
    })

    return reduxCreateStore(
        combineReducers({
            router:connectRouter(history),
            users:UserReducer
        }),
        applyMiddleware(
            routerMiddleware(history),
            thunk,
            logger

        )
    )
}