import ToolBar from '@material-ui/core/ToolBar'
import { AppBar } from '@material-ui/core'
import {getUserId} from '../reducks/users/selectors'
import React,{useState, useEffect,} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {db} from '../firebase'
import { push } from 'connected-react-router'
import {Divider, Grid} from '@material-ui/core'
import noImage from '../assets/noImage.png'
import { Helmet } from 'react-helmet-async'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {PaymentFormEdit} from '../components/Payments'
import {GrayButton } from '../components/UIkit'
import { ClosableDrawer } from '../components/Header'
const PaymentForm = () => {


    const id = window.location.pathname.split('/payment_form/')[1]

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')
    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data){
                    if(data.icons === ""){
                        setIcon(noImage)
                    }
                    if(data.icons[0].path){
                        setIcon(data.icons[0].path)
                    }
                }                
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])
    
    const [title, setTitle] = useState('')
    const [price, setPrice] = useState('')
    const [accountId, setAccountId] = useState('')
    const [img, setImg] = useState([])
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [userId, setUserId] = useState('')
    const [username, setUsername] = useState('')
    const [createrId, setCreaterId] = useState('')

    const [ownerAccountId, setOwnerAccountId] = useState('')
    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setTitle(data.title)
            setPrice(data.price)
            setWidth(data.imgWidth)
            setHeight(data.imgHeight)
            // インフルエンサー
            setUserId(data.artId)
            db.collection('users').doc(data.artId).get().then(snapshot => {
                const userdata = snapshot.data()
                setUsername(userdata.username)
            })
            //作った人
            setCreaterId(data.createrId)
            //作った人の口座Id
            setAccountId(data.accountId)
            if(data.img[0].path){
                setImg(data.img[0].id)
            }            
            db.collection('requests').doc(data.requestId).get().then(snapshot => {
                const requestData = snapshot.data()
                //お題主の口座Id
                setOwnerAccountId(requestData.accountId)
            })
        })
    },[id])    

    const stripePromise = loadStripe('pk_live_51KQjeCBuOUBLiWtsutHqSJhI0thZyrTpUzT9EJFpxGwu8GuMxwbSA4H6EZSVR1SjowrH3epOlotWOmzGealIwJha00rzYjbmLA')

    return (
        <>        
            <Helmet
            title="決済フォーム/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        <ClosableDrawer icons={icon} uid={uid} notifis={notifis} category={category} />
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div className='center' style={{maxWidth:"900px"}}>                
            <Grid container style={{margin:"auto"}}>
                <Grid item xs={12} sm={6} style={{maxWidth:"400px", margin:"0 auto auto auto "}}>
                    <div className="detail_box" >
                        {width < height && (
                            <>
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画面" className="detail_height" onContextMenu={(e) => e.preventDefault()}/>                                
                            </>
                        )}
                        {width > height && (
                            <>
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="detail_width" onContextMenu={(e) => e.preventDefault()}/>                                
                            </>
                        )}
                        {width === height && (
                            <>                 
                                <img src={`https://firebasestorage.googleapis.com/v0/b/anonymous-pot.appspot.com/o/smapleImg%2F${img}?alt=media`} alt="画像" className="detail_square" onContextMenu={(e) => e.preventDefault()}/>                                
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} md={6} style={{maxWidth:"400px", margin:"0 auto auto auto"}} className="detail_desc">
                    <p style={{textAlign:"left", fontWeight:"bold", fontSize:"26px"}} >{title}</p>
                    <div style={{display:"flex"}}>
                        <p style={{ textAlign:"left", color:"#E53C31", margin:"auto auto auto 0px"}} >¥<span style={{fontWeight:"bold", fontSize:"24px",}}>{price.toLocaleString()}</span></p>
                        <div className="balloon1-left">
                            <p style={{ textAlign:"left", fontSize:"12px"}}> （購入すると{username}さんに15%が入ります）</p>
                        </div>
                    </div>
                    <Elements stripe={stripePromise}>
                        <PaymentFormEdit influencerId={userId} createrId={createrId} price={price} accountId={accountId} artId={id} ownerAccountId={ownerAccountId}/>
                    </Elements>
                </Grid>
            </Grid>
            <div className='module-spacer--medium'/>
            <GrayButton
                label="戻る"
                onClick={() => dispatch(push('/art_detail/' + id))}
            />        
            </div>
            <div className="module-spacer--medium"/>
        </>
    )
}

export default PaymentForm