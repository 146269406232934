import React, {useState} from 'react'
import Swiper from "react-id-swiper"
import SwiperCore, {Navigation, Pagination} from 'swiper'
import "swiper/swiper.min.css"
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

SwiperCore.use([Navigation, Pagination])

const ImageSwiper = (props) => {

    const [params] = useState({
        // slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        loop: true,
    })

    const images = props.images   

    return (                        
        <Swiper {...params} >
            {images.map(image => (
                <div key={image.id}>                            
                        <img src={image.path} alt="画面"className="detail_box"  style={{objectFit:"contain"}}/>    
                </div>    
                
            ))}        
        </Swiper>            
    )
}

export default ImageSwiper