import {createSelector} from "reselect"

const usersSelector = (state) => state.users;

export const getUserId = createSelector(
    [usersSelector],
    state => state.uid
)

export const getUsername = createSelector(
    [usersSelector],
    state => state.username
)

export const getEmail = createSelector(
    [usersSelector],
    state => state.email
)

export const getIsSignedIn = createSelector(
    [usersSelector],
    state => state.isSignedIn
)
export const getCategory = createSelector(
    [usersSelector],
    state => state.category
)
export const getCustomerId = createSelector(
    [usersSelector],
    state => state.customer_id
)

export const getPaymentMethodId = createSelector(
    [usersSelector],
    state => state.payment_method_id
)

export const getStripeAccount = createSelector(
    [usersSelector],
    state => state.account_id
)
export const getAccountUrl = createSelector(
    [usersSelector],
    state => state.account_url
)
export const getLoginUrl = createSelector(
    [usersSelector],
    state => state.login_url
)