import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider} from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { TwitterButton } from '../../components/UIkit';
import { useDispatch } from 'react-redux';
import header from '../../assets/sample header.png'
import headphone from '../../assets/headphone.png'
import { push } from 'connected-react-router';

import { twitterSignUp } from '../../reducks/users/operations';

import {makeStyles} from '@material-ui/core/styles'
import {Button, Typography} from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

import {db, auth} from '../../firebase'
import noImage from '../../assets/noImage.png'
import {IconButton, Badge, Menu, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core'
import {signOut} from '../../reducks/users/operations'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import SendIcon from '@material-ui/icons/Send';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import GetAppIcon from '@material-ui/icons/GetApp';


  
const useStyles = makeStyles((theme) => ({
    header:{
        width:"100%",
        height:"auto"
    },
    img:{
        width:150,
        height:150,
        marginTop:50
    },
    imageList:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    header_root:{
        flexGrow:0,
        maxWidth:900,
        margin:"auto"
    },
    header_menuBar:{
        backgroundColor:"#fff",
        color:"444",
        width:"100%"
    },
    header_toolBar:{
        margin:"0 auto",
        width:"100%",
        padding:0
    },
    header_iconButton:{
        margin:"0 0 0 auto",
        display:"block"
    },
    header_iconImage:{
        width:40,
        height:40,
        borderRadius:"50%"
    },
}))
const InfluencerHome = () => {

    const [uid, setUid] = useState('') 
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
            }
        })
    })
    
    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }  

    const classes = useStyles()

    const dispatch = useDispatch()

    const influencer = "influencer"
    
    return (
        <>
            <Helmet
            title="PotLand"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        {/* <img src={logo} alt="logo" width="128px"/> */}
                        {signedIn === true ? (
                            <div className="header_iconButton">
                                    {category === "influencer" && (
                                    <Button style={{color:"white",backgroundColor:"#ff7c21", marginRight:"5px"}} onClick={() => dispatch(push('/art_request/edit/' + uid))}>
                                        <SendIcon style={{marginRight: "6px"}}/>リクエスト
                                    </Button>
                                )}
                                    <IconButton onClick={() => dispatch(push('/notifications'))} style={{paddingLeft:0}}>
                                        <Badge badgeContent={notifis.length} color="error">
                                            <NotificationsNoneIcon className="header_iconImage"/>
                                        </Badge>
                                    </IconButton>
                                {category === "influencer" ? (
                                    <>
                                    <IconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true" style={{padding:0}}>
                                        <img src={icons} alt="P" className="header_iconImage"/>
                                    </IconButton>
                                    <Menu 
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => dispatch(push('/art_list/all/' + uid))}>
                                        <ListItemIcon>
                                            <HomeIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="ホーム"/>
                                        </MenuItem>
                                        <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                        <ListItemIcon>
                                            <AccountBalanceIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="入金口座登録"/>
                                        </MenuItem>
                                        <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                        <ListItemIcon>
                                            <PaymentIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="支払いカード登録"/>
                                        </MenuItem>
                                        <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                        <ListItemIcon>
                                            <GetAppIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="ダウンロードした作品一覧"/>
                                    </MenuItem>
                                        <MenuItem onClick={() => dispatch(signOut())}>
                                        <ListItemIcon>
                                            <ExitToAppIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="サインアウト"/>
                                        </MenuItem>
                                    </Menu>
                                </>
                            ):(
                                <>
                                    <IconButton onClick={handleClick} style={{padding:0}}>
                                        <img src={icons} alt="P" className="header_iconImage"/>
                                    </IconButton>
                                    <Menu 
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => dispatch(push('/mypage/' + uid))}>
                                        <ListItemIcon>
                                            <HomeIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="ホーム"/>
                                        </MenuItem>
                                        <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                        <ListItemIcon>
                                            <GetAppIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="ダウンロードした作品一覧"/>
                                    </MenuItem>
                                        <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                        <ListItemIcon>
                                            <AccountBalanceIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="入金口座登録"/>
                                        </MenuItem>
                                        <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                        <ListItemIcon>
                                            <PaymentIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="支払いカード登録"/>
                                        </MenuItem>
                                        <MenuItem onClick={() => dispatch(signOut())}>
                                        <ListItemIcon>
                                            <ExitToAppIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="サインアウト"/>
                                        </MenuItem>
                                    </Menu>
                                </>
                                )}
                            </div>
                        ):(
                            <div className="header_iconButton" >
                                <Button style={{fontSize:"13px", }} onClick={() => dispatch(push('/'))}><Typography className='home_grid_title' style={{fontWeight:"bold"}} >イラストレーター登録</Typography></Button>
                                <Button style={{fontSize:"13px", marginRight:"10px", backgroundColor:"#f0f0f0"}} ><Typography className="home_grid_title" style={{fontWeight:"bold", marginRight:"10px"}}>コレクター登録</Typography></Button>
                                <Button style={{color:"white", backgroundColor:"#808080"}}>ログイン</Button>
                            {/* <SignedHeaderMenus />
                                <Button style={{color:"white",backgroundColor:"#1DA1F2", marginRight:"10px"}} onClick={() => handleOpen()}>
                                    <TwitterIcon style={{marginRight: "6px"}}/>ログイン
                                </Button>
                                <Dialog
                                open={open}
                                onClose={handleTwitterClose}
                                aria-labelledby="alert-dialog-title"
                                TransitionComponent={Transition}
                                keepMounted      
                                >
                                    <DialogTitle id="alert-dialog-title">どちらでログインしますか!?</DialogTitle>
                                    <DialogActions>
                                        <Button style={{backgroundColor:"#ffe5a0"}} onClick={() => dispatch(twitterSignUp(normal))}>イラストレーター</Button>
                                        <Button style={{backgroundColor:"#d3e7ff"}} onClick={() => dispatch(twitterSignUp(influencer))}>インフルエンサー</Button>
                                    </DialogActions>
                                </Dialog> */}
                            </div>
                        )}
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
        <div className='center' style={{maxWidth:"800px"}}>
            
            <img src={header} alt="header" className={classes.header}/>
            <h3 style={{ textAlign:"left"}}>Palletについて</h3>
            <p style={{textAlign:"left"}} className="home_main_desc">Palletは募集を投稿するとイラストが集まるサービスです。<br/>ラフ画や完成系のイラストを集めることができ、集まったイラストの中から自分が好きな作品を購入することができます。</p>
            <h3 style={{textAlign:"left"}}>Palletでできること</h3>
            <div style={{display:"flex", margin:"auto"}}>
                <div style={{margin:5}}>
                    <img src={headphone} alt="p" className="home_grid_img"/>
                    <h4 className='home_grid_title' style={{textAlign:"center"}}>好きが見つかる</h4>
                    <p className='home_main_desc'>集まった作品はLikeしないと投稿者の情報が開示されません。知名度に左右されることなく本当に好みの作品が見つかります。</p>
                </div>
                <div style={{margin:5}}>
                    <img src={headphone} alt="p" className="home_grid_img"/>
                    <h4 className='home_grid_title' style={{textAlign:"center"}}>選べる募集スタイル</h4>
                    <p className='home_main_desc'>集まった作品はLikeしないと投稿者の情報が開示されません。知名度に左右されることなく本当に好みの作品が見つかります。</p>
                </div>
                <div style={{margin:5}}>
                    <img src={headphone} alt="p" className="home_grid_img"/>
                    <h4 className='home_grid_title' style={{textAlign:"center"}}>募集で収益化</h4>
                    <p className='home_main_desc'>集まった作品はLikeしないと投稿者の情報が開示されません。知名度に左右されることなく本当に好みの作品が見つかります。</p>
                </div>
            </div>
            <h3 style={{textAlign:"left"}}>今までこんな困り事ありませんでしたか?</h3>
            <div style={{display:"flex"}}>
                <div style={{margin:5}}>
                    <img src={headphone} alt="p" className="home_grid_img"/>
                    <h4 className='home_grid_title' style={{textAlign:"center"}}>やりとりが苦手</h4>
                    <p className='home_main_desc'>集まった作品はLikeしないと投稿者の情報が開示されません。知名度に左右されることなく本当に好みの作品が見つかります。</p>
                </div>
                <div style={{margin:5}}>
                    <img src={headphone} alt="p" className="home_grid_img"/>
                    <h4 className='home_grid_title' style={{textAlign:"center"}}>伝え方がわからない</h4>
                    <p className='home_main_desc'>集まった作品はLikeしないと投稿者の情報が開示されません。知名度に左右されることなく本当に好みの作品が見つかります。</p>
                </div>
                <div style={{margin:5}}>
                    <img src={headphone} alt="p" className="home_grid_img"/>
                    <h4 className='home_grid_title' style={{textAlign:"center"}}>自分で作る時間がない</h4>
                    <p className='home_main_desc'>集まった作品はLikeしないと投稿者の情報が開示されません。知名度に左右されることなく本当に好みの作品が見つかります。</p>
                </div>
            </div>
            <div className='module-spacer--medium'/>

            <h3>Palletに登録して<br/>好みのイラストを集めにいこう</h3>

            <TwitterButton
              label="Twitterで始める"
              onClick={() => dispatch(twitterSignUp(influencer))}
            />
            <br/>
            <Button onClick={() => dispatch(push('/influencer/login'))}>
                    <Typography style={{fontSize:"12px", color:"#808080", textDecoration:"underline"}}>メールアドレスで登録する</Typography>
            </Button>
            {/* <Grid container>
                <Grid item xs={12} sm={6} style={{backgroundColor:"#ffe5a0"}}>
                <img src={illustLogo} alt="ilustLogo" className="illustLogo"/>
                <p style={{color:"#808080", fontSize:"13px", marginTop:"-6px"}}>イラスト投稿で実績を作ろう！</p>
                <div style={{display:"flex"}}>
                    <img src={headphone} className={classes.img} alt="head"/>
                    <div className='balloon4'>
                        <p style={{marginBottom:10}}>お題主から投稿されるお題のイラストを作成し実績をつけよう!</p>
                    </div>
                </div>
                <TwitterButton
                  label="twitterで始める"
                  onClick={() => dispatch(twitterSignUp(normal))}
                />
                
                <Button onClick={() => dispatch(push('/login'))}>
                    <Typography style={{fontSize:"12px", color:"#808080", textDecoration:"underline"}}>メールアドレスで登録する</Typography>
                </Button>
                <div className="module-spacer--small"/>
                </Grid>
                <Grid item xs={12} sm={6} style={{backgroundColor:"#d3e7ff"}}>
                <img src={influLogo} alt="ilustLogo" className="illustLogo"/>
                <p style={{color:"#808080", fontSize:"13px", marginTop:"-6px"}}>自分だけのイラストを見つけよう！</p>
                <div style={{display:"flex"}}>
                    <img src={megaphone} className={classes.img} alt="mega"/>
                    <div className='balloon4'>
                        <p style={{marginBottom:5}}>イラストレーターから投稿される自分に合った作品を使うことができる!</p>
                    </div>
                </div>
                <TwitterButton
                  label="twitterで始める"
                  onClick={() => dispatch(twitterSignUp(influencer))}
                />
                <Button onClick={() => dispatch(push('/influencer/login'))}>
                    <Typography style={{fontSize:"12px", color:"#808080", textDecoration:"underline"}}>メールアドレスで登録する</Typography>
                </Button>
                <div className="module-spacer--small"/>
                </Grid>
            </Grid>

            <div className="module-spacer--medium"/>
        
            <div className='module-spacer--medium'/> */}

        </div>
      </>
    )
}

export default InfluencerHome