import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet-async'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'
import noImage from '../assets/noImage.png'
import { getUserId } from '../reducks/users/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '../firebase'
import { push } from 'connected-react-router'
import { TwitterButton, GrayButton } from '../components/UIkit';
import notion from '../assets/pottin_shomen.png'
import { ClosableDrawer } from '../components/Header';

const ArtSubmissionComplate = () => {    
    const id = window.location.pathname.split('/art_submission_complate/')[1]

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')
    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()
            setCategory(data.category)
            if(data.icons[0].path){
                setIcon(data.icons[0].path)
            }else{
                setIcon(noImage)
            }
        })
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])

    const [title, setTitle] = useState('')
    const [requestId, setRequestId] = useState('')
    useEffect(() => {
        db.collection('arts').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setTitle(data.title)
            setRequestId(data.requestId)
        })
    },[id])
    const shareURL = "https://pallet.fun/art_detail/" + id

    const [shareOpen, setShareOpen] = useState(false)
    const shareClick = () => {
        setShareOpen(true)
    }
    const shareClose = () => {
        setShareOpen(false)
    }
    const [copyok, setCopyOk] = useState(false)

    const copyTextClipboard = () => {
        navigator.clipboard.writeText(shareURL).then(() => {
            setCopyOk(true)
        }).catch(() => {
            alert("クリップボードへのコピーが失敗しました。通信環境を確認の上再度お試しください。")
        })
    }
    return (
        <>
            <Helmet
            title="投稿完了/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div className="center">
                <h3>作品が投稿されました</h3>
                <p>投稿した作品を共有しよう</p>
                <img src={notion} style={{marginTop:"-20px", width:"350px", height:"350px"}} alt="img"/>
                <div className='module-spacer--meidum'/>
                    <TwitterButton
                      label="共有する"
                      onClick={shareClick}
                    />
                    <Dialog open={shareOpen} onClose={shareClose}>
                        <DialogTitle>SNSに共有しよう!</DialogTitle>
                        {copyok === true && (
                            <DialogContent>
                                <Typography>
                                    クリップボードにリンクをコピーしました!
                                </Typography>
                            </DialogContent>
                        )}
                        <DialogContent>
                            ※作品詳細ページのリンクを共有/コピーできます。
                        </DialogContent>
                        <DialogActions>
                        <a href={`http://twitter.com/share?url=${shareURL}&text=『${title}』を投稿しました&hashtags=Pallet`} target="_blank" rel="noreferrer" className='u-text__link-none'>
                            <Button style={{backgroundColor:"#1da1f2", color:"#fff", textTransform:"none", borderRadius:"12px"}}>Twitterへシェア</Button>
                        </a>
                        <Button style={{backgroundColor:"#808080", color:"#fff", borderRadius:"12px"}} onClick={() => copyTextClipboard()}>リンクコピー</Button>
                        </DialogActions>
                    </Dialog>                
                <div className='module-spacer--medium'/>
                <GrayButton
                  label="作品一覧画面へ"
                  onClick={() => dispatch(push('/art_request/all/' + requestId))}
                />
                <div className='module-spacer--medium'/>
            </div>
        </>
    )
}

export default ArtSubmissionComplate