import React from 'react'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
const useStyles = makeStyles({
    field:{
        backgroundColor:"#f0f0f0",
        marginBottom:"16px"
    }
})
const TextInput = (props) => {   
    const classes = useStyles() 
    return(
        <TextField        
            variant='outlined' 
            fullWidth={props.fullWidth} 
            label={props.label}
            margin="dense"
            multiline={props.multiline}
            required={props.required}
            rows={props.rows}
            value={props.value}
            type={props.type}
            onChange={props.onChange}        
            placeholder={props.placeholder}
            size={props.size}
            className={classes.field}
            
        />
    )
}

export default TextInput