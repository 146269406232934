import React ,{useState, useEffect, useCallback, useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentMethodId, getCustomerId, getUserId } from '../../reducks/users/selectors'

import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import GetAppIcon from '@material-ui/icons/GetApp'
import { registerCard, retrievePaymentMethod } from '../../reducks/stripes/operations'
import { PrimaryButton, TextDetail } from '../UIkit'
import { Button } from '@material-ui/core'

import {orderFinishedProduct, orderFinishedProductUser} from '../../reducks/stripes/operations'

const PaymentFormEdit = (props) => {
    const artId = props.artId
    const influencerId = props.influencerId
    const createrId = props.createrId
    const accountId = props.accountId
    const price = props.price
    const ownerAccountId = props.ownerAccountId

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const stripe = useStripe()
    const elements = useElements()
    const customerId = getCustomerId(selector)
    const paymentMethodId = getPaymentMethodId(selector)    

    const paymentform = "paymentform"
    const register = useCallback(() => {
        setClickAction(true)
        setLoading(true)
        dispatch(registerCard(stripe, elements, customerId, paymentform, artId))
    },[stripe, elements, customerId])

    const [card, setCard] = useState({})
    useEffect(() => {
        (async () => {
            if(paymentMethodId !== ""){
                const cardData = await retrievePaymentMethod(paymentMethodId)
                if(cardData){
                    setCard(cardData)
                }
            }
        })()
    },[paymentMethodId])
    const cardNumber = useMemo(() => {
        if(card.last4){
            return "**** **** **** " + card.last4
        }else {
            return "まだ登録されていません。"
        }
    })

    //onClickActionを一度のみしか発火させないようにする
    const [clickAction, setClickAction] = useState(false)
    const [loading, setLoading] = useState(false)

    //完成版のDL
    const rough = false
    //お題主の購入
    const order = useCallback(() => {
        setClickAction(true)
        dispatch(orderFinishedProduct(price, artId, createrId, influencerId, accountId, paymentMethodId, customerId, uid, rough))
    })
    //お題主以外の購入→お題主に10%が入る
    const userOrder = useCallback(() => {
        setLoading(true)
        setClickAction(true)
        dispatch(orderFinishedProductUser(price, artId, createrId, influencerId, accountId, paymentMethodId, customerId, uid, ownerAccountId))
    })

    return(
        <>
          {card.last4 ? (
              <>                
                <p style={{textAlign:"left", fontSize:"16"}}>現在登録されているカード情報</p>
                <div style={{maxWidth:400, margin:"0 auto"}}>
                    <TextDetail
                      label={card.brand} value={cardNumber}
                    />
                </div>
                <div className='module-spacer--medium'/>
                <div className="module-spacer--small"/>
                {/* {influencerId === uid ? (
                    <Button disabled={clickAction} onClick={ order} style={{backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px"}} startIcon={<GetAppIcon/>}>購入しダウンロードする</Button>
                ):( */}
                    <Button disabled={clickAction} onClick={userOrder} style={{backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px", width:300, height:48 }} startIcon={<GetAppIcon/>}>購入しダウンロードする</Button>
                    {loading === true && (
                        <p>購入処理中です.....</p>
                    )}
                {/* )} */}
                <div className='module-spacer--medium'/>
              </>
          ):(
              <>
                
                <p style={{textAlign:"left"}}>カード情報を登録してください</p>
                <div style={{maxWidth:350, margin:"0 auto"}}>
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </div>
                <div className='module-spacer--small'/>
                {/* <PrimaryButton
                  label="カード情報を登録する"
                  onClick={() => register()}
                /> */}
                <Button disabled={clickAction} onClick={register} style={{backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px", width:"256px", height:"48px", marginBottom:"12px"}}>カード情報を登録する</Button>
                {loading === true && (
                    <p>処理中です.....</p>
                )}
                <p style={{color:"#808080", fontSize:"13px"}}>※カード情報登録後、購入が可能になります</p>
             </>
          )}
            {/* <div style={{backgroundColor:"#f0f0f0", maxWidth:"500px", margin:"0 auto"}}> */}
                {/* <p style={{padding:10, textAlign:"left"}}>※クレジットカード情報は当社では保持せず、決済代行サービスStripeにて安全に管理されます。Stripeについては下記より確認できます。</p>
                <div className="center">
                 <p style={{ marginTop:"-20px", paddingBottom:"10px"}}>詳細は<a href="https://stripe.com/jp/privacy" target="_blank" rel="noreferrer" ><span>こちら</span></a></p>
                </div>
                <div className="module-spacer--medium"/> */}
            <div style={{textAlign:"left", backgroundColor:"#fef8f5", padding:"8px", border:"solid #E4E8ED"}}>
                <p >Palletはクレジットカード情報を安全に保管するため<br/>Stripeを使用しています。</p>
            </div>
        </>
    )

}

export default PaymentFormEdit