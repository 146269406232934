import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    row:{
        display:"flex",
        flexFlow:"row wrap",
        marginBottom:16
    },
    label:{
        marginLeft:0,
        marginRight:"auto"
    },
    value:{
        fontWeight:600,
        marginLeft:"auto",
        marginRight:0
    }
})

const TextDetail = (props) => {
    const classes = useStyles()
    return(
        <div style={{display:"flex", flexFlow:"row wrap"}}>
            <div style={{marginLeft:"0px"}}>{props.label}</div>
            <div style={{ margin:"auto auto auto 20px"}}>{props.value}</div>
        </div>
    )
}

export default TextDetail