import React from 'react'
import {Grid} from '@material-ui/core' 
import around from '../../assets/1.png'
import arts from '../../assets/3.png'
import set from '../../assets/5.png'
import money from '../../assets/4.png'
import {PrimaryButton} from '../UIkit'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import desctop from '../../assets/desctop.png'
import camera from '../../assets/camera.png'
import no1 from '../../assets/no1.png'
import no2 from '../../assets/no2.png'
import no3 from '../../assets/no3.png'
const IllustHomeItem = (props) => {
    const page = props.page
    const dispatch = useDispatch()
    return (
        <>
             {/* <div style={{backgroundColor:"#FFFFEF", marginTop:0}}>
                <h2 style={{textAlign:"left", marginTop:"0px", marginLeft:"20px", paddingTop:"30px"}}>Palletについて</h2>                
                    <Grid container style={{margin:"auto"}}>
                        <Grid item xs={12} md={6}>
                            <div className="home_grid_second_component">
                                <img src={megaphone} alt="p" className="home_grid_second_img"/>
                                <div style={{margin:"0 auto 0 0 "}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>1.お題を見つける</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>推しが投稿するお題を見つけましょう。<br/>推しのマイページとSNSからお題を確認することができます。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="home_grid_second_component">
                                <img src={headphone} alt="p" className="home_grid_second_img"/>
                                <div style={{margin:"0 auto 0 0"}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>2.お題に絵で回答する</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>お題にイラストで回答しましょう。<br/>イラストを回答したらSNSにシェアすることをお勧めします。</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>                    
                <Grid container style={{margin:"auto"}}>
                    <Grid item xs={12} md={6}>
                        <div className='home_grid_second_component'>
                            <img src={notion} alt="p" className='home_grid_second_img'/>
                            <div style={{margin:"0 auto 0 0 "}}>
                                <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>3.作品を販売する</p>
                                <p className='home_grid_second_desc' style={{textAlign:"left"}}>投稿した作品は第三者に購入してもらうことができます。購入されると売り上げの15%がお題を投稿したコレクターへ著作権利用料として入ります(サービス手数料10%)。</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="home_grid_second_component" style={{background:"none"}}></div>
                    </Grid>
                </Grid>
                <div className='module-spacer--medium'/>
            </div> */}
            <div style={{backgroundColor:"#FFFFEF", marginTop:0}}>
                <h3 style={{textAlign:"left", marginTop:"0px", marginLeft:"20px", paddingTop:"30px", fontSize:"22px"}}>絵師様、購入者様向け『推し事』登録でできること</h3>
                    <Grid container style={{margin:"auto", marginBottom:"20px"}}>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={around} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0 "}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>お題の確認</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>推しが投稿するお題を見つけましょう。<br/>推しのマイページとSNSからお題を確認することができます。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={arts} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0"}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>イラストの投稿</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>推しが出すお題に対して、イラストを投稿することができます。<br/>イラストを投稿したら、SNSにシェアすることができます。</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container style={{margin:"auto"}}>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={set} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0"}}>
                                <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>作品の販売</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>投稿した作品は第三者に購入してもらうことができます。購入されると売り上げの15%がお題を投稿したコレクターへ著作権利用料として入ります(サービス手数料10%)。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={money} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0"}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>イラストの購入</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>推しのお題に投稿された第三者の作品を購入することができます。</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>    
                    <div className='module-spacer--small'/>
                    <div className="home_change">
                        <p style={{width:"50%"}}></p>
                        <p className="home_change_desc">コレクター登録は<span onClick={() => dispatch(push(page))} style={{fontWeight:"bold", cursor:"pointer", textDecoration:"underline"}}>こちら</span></p>
                    </div>          

                <div className='module-spacer--medium'/>
            </div>
            <div style={{backgroundColor:"#fafafa", marginTop:0}}>
            <h3 style={{textAlign:"left", marginTop:"0px", marginLeft:"20px", paddingTop:"30px", fontSize:"22px"}}>イラストを購入したら</h3>
                <Grid container style={{margin:"auto"}}>
                    <Grid item xs={12} sm={6}>
                        <div className="home_grid_five_component">
                            <img src={desctop} alt="p" className='home_grid_img'/>
                            <p className='home_grid_second_title' style={{fontWeight:"bold"}}>壁紙への使用</p>
                            <p className='home_grid_second_desc' >ご自身のスマートフォン、PC、タブレットの壁紙などで使用することができます。</p>                                
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="home_grid_five_component">
                            <img src={camera} alt="p" className='home_grid_img'/>
                            <p className='home_grid_second_title' style={{fontWeight:"bold"}}>SNSのアイコンやヘッダーに使用</p>
                            <p className='home_grid_second_desc'>イラスト制作者さまがSNSへの使用を許可した場合にのみ使用することができます。<br/>イラスト制作者さまが許可をしている場合でも、念の為推しの二次創作ガイドラインを確認してから使用することをお勧めします。</p>                                
                        </div>
                    </Grid>
                </Grid> 
                <div className='module-spacer--medium'/> 
            </div>
            <div style={{backgroundColor:"#FFFFEF"}}>
                <h3 style={{textAlign:"left", marginTop:"0px", marginLeft:"20px", paddingTop:"30px", fontSize:"22px", marginBottom:"5px"}}>作品を投稿する際の留意点</h3>
                <p style={{textAlign:"left", marginTop:"5px", marginLeft:"20px", marginBottom:"0px", fontSize:"14px"}}>下記に該当すると運営が判断した場合、運営が許可なく削除することがあります。<br/>皆さまのご協力をお願いいたします。</p>
                <br/>
                <Grid container style={{margin:"auto"}}>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <p style={{fontWeight:"bold", textDecoration:"underline", fontSize:"18px"}}>01</p>
                            <img src={no1} style={{width:60, height:60}}/>
                            <p style={{fontSize:"14px"}} >過度な性的・グロテスクな<br/>コンテンツ</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <p style={{fontWeight:"bold", textDecoration:"underline", fontSize:"18px"}}>02</p>
                            <img src={no2} style={{width:60, height:60}}/>
                            <p style={{fontSize:"14px"}}>宗教的・差別的な表現や暴言等</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <p style={{fontWeight:"bold", textDecoration:"underline", fontSize:"18px"}}>03</p>
                            <img src={no3} style={{width:60, height:60}}/>
                            <p style={{fontSize:"14px"}}>お題に沿わない内容</p>
                        </div>
                    </Grid>
                </Grid>
                <div className='module-spacer--medium'/>
            </div>
        </>
    )
}
export default IllustHomeItem