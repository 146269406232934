import React from 'react'
import {MenuItem, Select, FormControl, InputLabel} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    formControl:{
        minWidth:"200px"
    }
})

const SelectBox = (props) => {
    const classes = useStyles()
    return(
        <FormControl className={classes.formControl}>
            <InputLabel> 選択してください</InputLabel>
            <Select
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
              fullWidth={props.fullWidth}
              size="small"
            >
                {props.menus.map((menu) => {
                    return <MenuItem value={menu.id} key={menu.id}>{menu.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default SelectBox