import * as Actions from './actions'
import initialState from '../store/initialState'

export const UserReducer = (state = initialState.users, action) => {
    switch (action.type){
        case Actions.SIGN_UP:
            return{
                ...state,
                ...action.payload
            }
        case Actions.SIGN_IN:
            return{
                ...state,
                ...action.payload
            }
        case Actions.ART_SIGN_UP:
            return{
                ...state,
                ...action.payload
            }
        case Actions.ART_SIGN_IN:
            return{
                ...state,
                ...action.payload
            }
        case Actions.SIGN_OUT:
            return{
                ...action.payload
            }
        case Actions.UPDATE_USER_STATE:
            return {
                ...state,
                ...action.payload
            }
        case Actions.UPDATE_USER_ACCOUNT:
            return{
                ...state,
                ...action.payload
            }
        case Actions.UPDATE_USER_LOGINURL:
            return {
                ...state,
                ...action.payload    
            }
        default:
            return state
    }
}