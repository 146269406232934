import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles({
    button:{
        backgroundColor:"#1DA1F2",
        color:"white",
        fontSize:16,
        height:48,
        marginButtom:16,
        marginTop:16,
        width:256,
        borderRadius:"12px"
    }
})

const TwitterButton = (props) => {
    
    const classes = useStyles()

    return(
        <Button className={classes.button} onClick={() => props.onClick()} variant="contained" style={{textTransform:"none"}}>
            <TwitterIcon style={{marginRight: "20px"}}/>{props.label}
        </Button>
    )
}

export default TwitterButton

