import React,{useState, useCallback} from 'react'
import {IconButton, Avatar, Dialog, DialogTitle, DialogActions, Button} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {storage} from '../../firebase/index'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { ArtEditImagePreview } from '.'

const useStyles = makeStyles((theme) => ({
    icon: {
        border:"solid 1px",
        borderColor:"#f0f0f0",
        borderRadius:"8px",
        backgroundColor:"#fff",
        margin:"auto",
        [theme.breakpoints.down('sm')]:{
            width:300,
            height:100
        },
        [theme.breakpoints.between('sm', 'md')]:{
            width:400,
            height:150
        },
        [theme.breakpoints.up('md')]:{
            width:780,
            height:260
        }
    },
    icons: {
        border:"solid ",
        borderColor:"#f0f0f0",
        borderRadius:"8px",
        backgroundColor:"#fff",
        margin:"auto",        
        [theme.breakpoints.down('sm')]:{
            width:300,
            height:100
        },
        [theme.breakpoints.between('sm', 'md')]:{
            width:400,
            height:150
        },
        [theme.breakpoints.up('md')]:{
            width:780,
            height:260
        }
    },
    size: {
        margin:"auto",
        color:"#808080",
        [theme.breakpoints.down('sm')]:{
            height: 60,
            width: 60,
        },
        [theme.breakpoints.between('sm',"md")]:{
            width:80,
            height:80,
            marginTop:10
        },
        [theme.breakpoints.up('md')]:{
            width:100,
            height:100,
            marginTop:"50px"
        }
    },
    addPhoto:{
        position: "absolute",
        zIndex: 2,
        display:"flex"
    },
    addPhoto1:{
        [theme.breakpoints.down('sm')]:{
            position: "absolute",
            zIndex: 2,
            display:"flex",
            top:280,
            left:230,
            width:30,
            height: 30
            
        },
        [theme.breakpoints.up('sm')]:{
            position: "absolute",
            zIndex: 2,
            display:"flex",
            top:320,
            left:670,
            width:30,
            height:30
        }
    },
    detail:{
        fontSize:"10px",
        color:"#808080"
    }
}))

const SampleImageArea = (props) => {
    const classes = useStyles()
    const setImage = props.setImg
    const images = props.img
    

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }


    const uploadImage = useCallback((event) => {
        const file = event.target.files;

        let blob = new Blob(file, { type: "image/jpeg" });

        const S = "abcdefghijklmnopqrstuvwyzABCDEFGHIJLMNOPQRSTUVWXYZ1234567890"
        const N = 16
        const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n) => S[n % S.length]).join('')

        const uploadRef = storage.ref('images').child(fileName)
        const uploadTask = uploadRef.put(blob)

        uploadTask.then(() => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                const newImage = { id: fileName, path: downloadURL }
                setImage((prevState => [...prevState, newImage]))
            })
        })
    }, [setImage])

    const deleteImage = useCallback(async (id) => {
        const newImage = images.filter(images => images.id !== id)
        setImage(newImage)
        return storage.ref('images').child(id).delete().then(() => {
            handleClose()
        })
    },[images, setImage])

    return(
        <div style={{maxWidth:780}}>
            {images.length === 0 ? (
                <div style={{cursor:"pointer"}} className={classes.icon}>
                    <IconButton className={classes.icons}>
                        <label>
                            <Avatar className={classes.icons} style={{cursor:"pointer" ,display:"block"}}>
                                <SystemUpdateAltIcon className={classes.size}/>
                                <input
                                type="file"
                                id="image"
                                className="u-display-none"
                                onChange={(e) => uploadImage(e)}
                                />
                                <p style={{fontSize:"13px", color:"#808080"}}>アップロードするファイルを選ぶ</p>
                            </Avatar>
                        </label>
                    </IconButton>
               </div>
            ):(
                images.map((image, index) => 
                  <div style={{cursor:"pointer"}} key={index}>
                      <ArtEditImagePreview id={image.id} path={image.path} onClick={(e) => handleOpen(e)} />
                      <p className={classes.detail}>※画像タップで変更できます</p>
                      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
                            <DialogTitle id="alert-dialog-title">{"画像を変更しますか？"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => deleteImage(image.id)}>はい</Button>
                                <Button onClick={handleClose}>いいえ</Button>
                            </DialogActions>
                        </Dialog>
                  </div>
                )
            )}
        </div>
    )
}

export default SampleImageArea