import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import createStore from './reducks/store/store'
import * as History from 'history'
import { ConnectedRouter } from 'connected-react-router'
import {theme} from './assets/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import {HelmetProvider} from 'react-helmet-async'
import * as serviceWorker from './serviceWorker';

import App from './App'

const history = History.createBrowserHistory()
export const store = createStore(history)

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <ConnectedRouter history={history}>                    
                <ThemeProvider theme={theme}>                                            {/* <CssBaseline/> */}
                        <App/>
                </ThemeProvider>                    
            </ConnectedRouter>
        </Provider>
    </HelmetProvider>
    ,document.getElementById('root')
)

serviceWorker.register();