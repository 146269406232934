import React from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js/pure'
import {PaymentEdit} from '../components/Payments'

const stripePromise = loadStripe('pk_live_51KQjeCBuOUBLiWtsutHqSJhI0thZyrTpUzT9EJFpxGwu8GuMxwbSA4H6EZSVR1SjowrH3epOlotWOmzGealIwJha00rzYjbmLA')

const CheckoutWrapper = () => {
    return(
        <Elements stripe={stripePromise}>
            <PaymentEdit/>
        </Elements>
    )
}

export default CheckoutWrapper