import React, {useState} from 'react'
import { Button } from '@material-ui/core'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import TwitterIcon from '@material-ui/icons/Twitter';
import {Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { twitterSignUp } from '../../reducks/users/operations'
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignedHeaderMenus = () => {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const normal = "normal"

    const influencer = "influencer"

    return(
        <div style={{paddingRight:"10px"}}>
            <Button style={{color:"white",backgroundColor:"#1DA1F2", marginRight:"10px", borderRadius:"12px"}} onClick={() => handleOpen()}>
                <TwitterIcon style={{marginRight: "6px"}}/>ログイン
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              TransitionComponent={Transition}
              keepMounted      
            >
                <DialogTitle id="alert-dialog-title">どちらでログインしますか?</DialogTitle>
                <DialogActions>
                    <Button style={{backgroundColor:"#d3e7ff", borderRadius:"12px"}} onClick={() => dispatch(twitterSignUp(influencer))}>コレクター</Button>
                    <Button style={{backgroundColor:"#ffe5a0", borderRadius:"12px"}} onClick={() => dispatch(twitterSignUp(normal))}>推し事</Button>
                </DialogActions>
            </Dialog>
            <Button style={{backgroundColor:"#ff7c21", color:"white", borderRadius:"12px"}} onClick={() => dispatch(push('/'))}>
                会員登録
            </Button>
        </div>
    )
}

export default SignedHeaderMenus