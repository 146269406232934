import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider} from '@material-ui/core'
import logo from "../../assets/logo.png"
import { HeaderMenu } from '../../components/Header'

import React, {useCallback, useState} from 'react'
import { useDispatch } from 'react-redux'
import { PrimaryButton, TwitterButton } from '../../components/UIkit'
import {artSignIn, artTwitterSignUp} from '../../reducks/users/operations'
import {IconButton, Button} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { Helmet } from 'react-helmet-async'
import { push } from 'connected-react-router'
import notion from '../../assets/pottin_notion.png'


const ArtInfluencerSignin = () => {    
    const dispatch = useDispatch()

    const id = window.location.pathname.split('/art_influencer/signin/')[1]

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    },[setEmail])

    const inputPassword = useCallback((e) => {
        setPassword(e.target.value)
    },[setPassword])

    const [value, setValue] = useState(false)

    const handleClick= () => {
        setValue(!value)
    }

    const influencer = "influencer"
    const [field, setField] = useState(false)
    const openField = () => {
        setField(!field)
    }
    return(
        <>
        <Helmet
          title="ログインフォーム/Pallet"
        />
        <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                    <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/art_home/' + id))} style={{cursor:"pointer"}}/>
                    <div className="header_iconButton">
                        <HeaderMenu id={id} key={id}/>
                    </div>                    
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
        <div className='c-section-container'>
            <div className='module-spacer--small'/>
            <h2 className='center'>ログイン</h2>
            <div className='module-spacer--small'/>
            <div className="center" style={{display:"flex", maxWidth:"500px"}}>
                
                <Button style={{width:"50%", fontWeight:"bold", backgroundColor:"#ff7c21", color:"#fff", borderRadius:"12px"}}>コレクター</Button>
                
                <Button onClick={() => dispatch(push('/art_signin/' + id))} style={{width:"50%", fontWeight:"bold", borderRadius:"12px"}}>推し事</Button>
            </div>
            <div className="module-spacer--small"/>
            <div className='center'>
                <img src={notion} style={{width:"120px", height:"120px"}}/>
            </div>
            
            <div className='module-spacer--medium'/>
            <div className='center'>
                <TwitterButton
                label="Twitterでログイン"
                onClick={() => dispatch(artTwitterSignUp(influencer, id))}
                />
                <div className="module-spacer--small"/>
                {/* <p onClick={() => openField()} style={{color:"#808080", textDecoration:"underline", cursor:"pointer"}}>メールアドレスでログイン</p> */}
            </div>
            {field === true && (
                <>
                    <div style={{borderRadius:"4px", width:"100%", height:40, display:"inline-flex"}}>
                        <input value={email} onChange={inputEmail} type="email" style={{backgroundColor:"#f0f0f0", paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="メールアドレス"/>
                    </div>                    
                    <div className='module-spacer--extra-small'/>
                    <div style={{display:"flex"}}>
                        <div style={{ borderRadius:"4px", width:"90%", height:40, display:"inline-flex"}}>
                            <input type={value ? "text" : "password"} value={password} onChange={inputPassword} style={{backgroundColor:"#f0f0f0",paddingTop:"10.5px", border:"solid #808080 1px", borderRadius:"4px", paddingBottom:"10.5px", paddingLeft:"14px",paddingRight:"14px" ,font:"inherit", width:"100%", boxSizing:"content-box", background:"none", display:"block"}} ariaInvalid={false} placeholder="パスワード"/>
                        </div>
                        <IconButton onClick={handleClick} style={{margin:"auto", padding:"0"}}>
                            {value ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </div>
                    <div className='module-spacer--medium'/>
                    <div className='center'>
                        <PrimaryButton
                        label="メールアドレスでログイン"
                        onClick={() => dispatch(artSignIn(email, password, id))}
                        />
                    </div>
                    <div className='module-spacer--small'/>
                    <p className="center">パスワードを忘れた方は<span style={{textDecoration:"underline", fontWeight:"bold", color:"bule", cursor:"pointer"}} onClick={() => dispatch(push('/art_influencer_reset/password/' + id))}>こちら</span></p>
                    <div className='module-spacer--small'/>
                </>
            )}
             <div className='module-spacer--medium'/>

        </div>
      </>
    )
}

export default ArtInfluencerSignin