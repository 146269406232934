import React,{useState} from 'react'
import {useDispatch} from 'react-redux'
import { push } from 'connected-react-router'
import {Button, IconButton, Badge, Menu, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core'
import {signOut} from '../../reducks/users/operations'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import SendIcon from '@material-ui/icons/Send';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import GetAppIcon from '@material-ui/icons/GetApp';
import logo from "../../assets/logo.png"

const ClosableDrawer = (props) => {
    const uid = props.uid
    const category = props.category
    const icons = props.icons
    const notifis = props.notifis

    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            {category === "influencer" ? (
                <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/art_list/all/' + uid))} style={{cursor:"pointer"}}/>
            ):(                            
                <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/mypage/' + uid))} style={{cursor:"pointer"}}/>
            )}
            <div className="header_iconButton">
                {category === "influencer" && (
                    <Button style={{color:"white",backgroundColor:"#ff7c21", marginRight:"5px", borderRadius:"12px"}} onClick={() => dispatch(push('/art_request/edit/' + uid))}>
                        <SendIcon style={{marginRight: "6px"}}/>お題を投稿
                    </Button>
                )}
                <IconButton onClick={() => dispatch(push('/notifications'))} style={{paddingLeft:0}}>
                    <Badge badgeContent={notifis.length} color="error">
                        <NotificationsNoneIcon className="header_iconImage"/>
                    </Badge>
                </IconButton>
                {category === "influencer" ? (
                    <>
                        <IconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true" style={{padding:0}}>
                            <img src={icons} alt="P" className="header_iconImage"/>
                        </IconButton>
                        <Menu 
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => dispatch(push('/art_list/all/' + uid))}>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ホーム"/>
                            </MenuItem>
                            <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                            <ListItemIcon>
                                <AccountBalanceIcon/>
                            </ListItemIcon>
                            <ListItemText primary="入金口座の管理"/>
                            </MenuItem>
                            <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                            <ListItemIcon>
                                <PaymentIcon/>
                            </ListItemIcon>
                            <ListItemText primary="支払いカードの管理"/>
                            </MenuItem>
                            <MenuItem onClick={() => dispatch(push('/art_like/collecter/' + uid))}>
                            <ListItemIcon>
                                <GetAppIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ダウンロードした作品一覧"/>
                        </MenuItem>
                            <MenuItem onClick={() => dispatch(signOut())}>
                            <ListItemIcon>
                                <ExitToAppIcon/>
                            </ListItemIcon>
                            <ListItemText primary="サインアウト"/>
                            </MenuItem>
                        </Menu>
                    </>
                ):(
                    <>
                        <IconButton onClick={handleClick} style={{padding:0}}>
                            <img src={icons} alt="P" className="header_iconImage"/>
                        </IconButton>
                        <Menu 
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => dispatch(push('/mypage/' + uid))}>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ホーム"/>
                            </MenuItem>
                            <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                            <ListItemIcon>
                                <GetAppIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ダウンロードした作品一覧"/>
                        </MenuItem>
                            <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                            <ListItemIcon>
                                <AccountBalanceIcon/>
                            </ListItemIcon>
                            <ListItemText primary="入金口座の管理"/>
                            </MenuItem>
                            <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                            <ListItemIcon>
                                <PaymentIcon/>
                            </ListItemIcon>
                            <ListItemText primary="支払いカードの管理"/>
                            </MenuItem>
                            <MenuItem onClick={() => dispatch(signOut())}>
                            <ListItemIcon>
                                <ExitToAppIcon/>
                            </ListItemIcon>
                            <ListItemText primary="サインアウト"/>
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </div>
        </>
    )
}

export default ClosableDrawer