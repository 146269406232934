import React, {useState, useEffect, useCallback} from 'react'
import { Helmet } from 'react-helmet-async'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, IconButton, Divider, Badge, Button, Dialog, DialogActions, DialogTitle, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import {signOut} from '../reducks/users/operations'
import logo from '../assets/logo.png'
import noImage from '../assets/noImage.png'
import megaphone from '../assets/megaphone.png'
import headphone from '../assets/headphone.png'
import { getUserId } from '../reducks/users/selectors'
import { useDispatch, useSelector } from 'react-redux'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import DeleteIcon from '@material-ui/icons/Delete';
import { db } from '../firebase'
import { push } from 'connected-react-router'
import GetAppIcon from '@material-ui/icons/GetApp';


const NotificationWelcome = () => {
    const id = window.location.pathname.split('/notification/welcome/')[1]

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const [icons, setIcons] = useState()
    const [loginWay, setLoginWay] = useState('')
    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()
            setLoginWay(data.LoginWay)
            if(data.icons === ""){
                setIcons(noImage)
            }
            if(data.icons[0].path){
                setIcons(data.icons[0].path)
            }
        })
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])

    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const deleteData = useCallback(() => {
        db.collection('users').doc(uid).collection('notifications').doc(id).delete().then(() => {
            setOpen(false)
            dispatch(push('/notifications'))
        })
    },[])

    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Helmet
            title="通知/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>                            
                        <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/mypage/' + uid))} style={{cursor:"pointer"}}/>
                                        
                        <div className="header_iconButton">
                            <IconButton onClick={() => dispatch(push('/notifications'))} >
                                <Badge badgeContent={notifis.length} color="error">
                                <NotificationsNoneIcon className="header_iconImage"/>
                                </Badge>
                            </IconButton>
                            
                           <IconButton onClick={handleMenu} style={{padding:0}}>
                               <img src={icons} alt="P" className="header_iconImage"/>
                           </IconButton>
                           <Menu 
                               id="simple-menu"
                               anchorEl={anchorEl}
                               keepMounted
                               open={Boolean(anchorEl)}
                               onClose={handleMenuClose}
                           >
                           <MenuItem onClick={() => dispatch(push("/mypage/" + uid))}>
                               <ListItemIcon>
                                   <HomeIcon/>
                               </ListItemIcon>
                               <ListItemText primary="ホーム"/>
                           </MenuItem>
                           <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                    <ListItemIcon>
                                        <GetAppIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="ダウンロードした作品一覧"/>
                                </MenuItem>
                           <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                               <ListItemIcon>
                                   <AccountBalanceIcon/>
                               </ListItemIcon>
                               <ListItemText primary="入金口座の管理"/>
                           </MenuItem>
                           <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                               <ListItemIcon>
                                   <PaymentIcon/>
                               </ListItemIcon>
                               <ListItemText primary="支払いカードの管理"/>
                           </MenuItem>
                           <MenuItem onClick={() => dispatch(signOut())}>
                               <ListItemIcon>
                                   <ExitToAppIcon/>
                               </ListItemIcon>
                               <ListItemText primary="サインアウト"/>
                           </MenuItem>
                       </Menu>              
                        </div>
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div className='center' style={{maxWidth:800}}>
                <div style={{textAlign:"right", marginBottom:"-20px"}}>
                    <IconButton style={{margin:"0 0 0 auto"}} onClick={() => handleClick()}>
                        <DeleteIcon/>
                    </IconButton>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
                        <DialogTitle id="alert-dialog-title">この通知を削除しますか？</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => deleteData()}>はい</Button>
                            <Button onClick={() => handleClose()}>いいえ</Button>
                        </DialogActions>
                        
                    </Dialog>
                </div>
                {loginWay === "twitter" ? (
                    <h2>ログインありがとうございます。</h2>
                ):(
                    <h2>ご登録ありがとうございます。</h2>
                )}
                <div style={{display:"flex", maxWidth:600, margin:"auto"}}>
                    <img src={megaphone} className='notifi_influencer_megaphone' alt="mega"/>
                    <img src={headphone} className='notifi_influencer_headphone' alt="head"/>
                </div>
                <img src={logo} className="notifi_influencer_img" alt="logo"/>
                <p style={{marginTop:"-20px", fontSize:"12px", color:"#808080"}}>推しからのお題に絵で回答すると販売できるサービス</p>
                <div className='module-spacer--small'/>
                <h3 style={{textAlign:"left", marginLeft:"2rem"}}>サービス概要</h3>
                <div style={{maxWidth:620, margin:"auto"}}>
                    <p style={{textAlign:"left"}}>推しが出すお題に対して、イラストを投稿することができます。<br/>投稿したイラストは販売することができ、第三者からの購入が発生した場合売上の15%が著作権利用料として推しに入ります。<br/><span style={{fontSize:"12px"}}>（サービス手数料は10%です。）</span></p>
                    {/* <img src={Icons} alt="icon" className='notifi_influencer_icon'/>                     */}
                </div>
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default NotificationWelcome