import React,{useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, IconButton} from '@material-ui/core'
import logo from '../../assets/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { getCategory, getIsSignedIn, getUserId} from '../../reducks/users/selectors'
import { SignedHeaderMenus } from '.'
import { db } from '../../firebase'
import noImage from '../../assets/noImage.png'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

const useStyles = makeStyles((theme) => ({
    header_root:{
        flexGrow:0,
        maxWidth:900,
        margin:"auto"
    },
    header_menuBar:{
        backgroundColor:"#fff",
        color:"444",
        width:"100%"
    },
    header_toolBar:{
        margin:"0 auto",
        width:"100%",
        padding:0
    },
    header_iconButton:{
        margin:"0 0 0 auto",
        display:"block"
    },
    header_iconImage:{
        width:40,
        height:40,
        borderRadius:"50%"
    },
}))

const Header = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const signedIn = getIsSignedIn(selector)
    const category = getCategory(selector)
    const uid = getUserId(selector)

    const [icons, setIcons] = useState()

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }else if(data.icons){                    
                    setIcons(data.icons)
                }else{
                    setIcons(noImage)
                }                
            })
        }
    },[uid])

    return(
        <div className={classes.header_root}>
            <AppBar position="relative" className={classes.header_menuBar} style={{boxShadow:"none"}}>
                <ToolBar className={classes.header_toolBar}>
                    <img src={logo} alt="logo" width="128px"/>
                    {signedIn === true ? (
                        <div className={classes.header_iconButton}>
                            <IconButton >
                                <NotificationsNoneIcon className={classes.header_iconImage}/>
                            </IconButton>
                            {category === "influencer" ? (
                                <IconButton onClick={() => dispatch(push('/art_list/all/' + uid))} className={classes.header_avatar}>
                                    <img src={icons} alt="P" className={classes.header_iconImage}/>
                                </IconButton>
                            ):(
                            <IconButton
                                onClick={() => dispatch(push("/mypage/" + uid))} 
                                className={classes.avatar}
                            >
                                <img src={icons} alt="P" className={classes.header_iconImage}/>
                            </IconButton>
                            )}
                        </div>
                    ):(
                        <div className={classes.header_iconButton}>
                           <SignedHeaderMenus/>
                        </div>
                    )}
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
    )
}

export default Header

