import { CircularProgress } from '@material-ui/core'
import React, {useEffect} from 'react'
import { auth } from '../firebase'
import { useDispatch } from 'react-redux'
import {push} from 'connected-react-router'
import { db } from '../firebase'

const HomeLoading = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        auth.onAuthStateChanged(user => {            
            if(user){
                db.collection('users').doc(user.uid).get().then(snapshot => {
                    const data = snapshot.data()                    
                    if(data.category === "influencer"){
                        return dispatch(push('/art_list/all/' + user.uid))
                    }else{
                        return dispatch(push('/mypage/' + user.uid))
                    }
                })            
            }
        })
    })
    

    return (
        <div className='center'>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <CircularProgress/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
        </div>
    )
}

export default HomeLoading