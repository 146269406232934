import React, {useState, useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import { TextInput, GrayButton } from '../../components/UIkit'
import {signIn} from '../../reducks/users/operations'
const ManagementScreen = () => {

    const dispatch = useDispatch()

    const [id, setName] = useState('')
    const [password, setPassword] = useState('')
    const inputName = useCallback((e) => {
        setName(e.target.value)
    },[setName])
    const inputPassword = useCallback((e) => {
        setPassword(e.target.value)
    },[setPassword])

    return (
        <div className="center">
            <div className='module-spacer--small'/>
            <h3>管理画面</h3>
            <TextInput
              value={id}
              onChange={inputName}
              label="name"
            />
            <div className="module-spacer--small"/>
            <TextInput
              value={password}
              onChange={inputPassword}
              label="password"
            />
            <div className='module-spacer--small'/>
            <GrayButton
              label="GO"
              onClick={() => dispatch(signIn(id, password))}
            />
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
        </div>            
    )
}

export default ManagementScreen