import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Drawer} from '@material-ui/core'
import logo from "../assets/logo.png"
import React, {useState, useEffect, useCallback} from 'react'
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import MenuIcon from '@material-ui/icons/Menu';
import {Button, Typography} from '@material-ui/core'
import { Helmet } from 'react-helmet-async'
import {db, auth} from '../firebase'
import noImage from '../assets/noImage.png'
import {IconButton, ListItemText, List, ListItem} from '@material-ui/core'
import { ClosableDrawer } from '../components/Header';

const Policy = () => {
    const [uid, setUid] = useState('')
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
            }
        })
    })

    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])

    const dispatch = useDispatch()

    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        setWidth(window.innerWidth)
    })
    

     
    const [open, setOpen] = useState(false)
 
    const handleDrawerToggle = useCallback((event) => {
      if (event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')){
              return;
          }
         setOpen(!open)
    }, [setOpen,open])

    return (
        <>
             <Helmet
            title="Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        {signedIn === true ? (
                            <>
                                <ClosableDrawer icons={icons} uid={uid} notifis={notifis} category={category} />
                            </>
                        ):(
                            <>
                                
                                <img src={logo} alt="logo" width="128px" style={{cursor:"pointer"}} onClick={() => dispatch(push('/'))}/>                                
                                <div className="header_iconButton" >
                                    {width > 500 ? (
                                        <div style={{display:"flex"}}>
                                            {/* <Button className="home_button" style={{fontSize:"13px", marginRight:"10px", backgroundColor:"#f0f0f0", borderRadius:"12px"}} ><Typography className="home_button" style={{fontWeight:"bold", marginRight:"10px"}}>コレクター登録</Typography></Button> */}
                                            <p style={{fontWeight:"bold", fontSize:"14px", marginLeft:50}} >コレクター登録</p>
                                            <p className="home_button" style={{marginLeft:50}} onClick={() => dispatch(push('/i_home'))}>推し事登録</p>
                                            {/* <Button  style={{fontSize:"13px", borderRadius:"12px", marginRight:"10px"}} onClick={() => dispatch(push('/i_home'))}><Typography className="home_button"style={{fontWeight:"bold"}}>イラストレーター登録</Typography></Button> */}
                                            <p className='home_button' style={{marginLeft:50}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</p>
                                            {/* <Button onClick={() => dispatch(push('/influencer/signin'))} style={{color:"white", backgroundColor:"#808080", borderRadius:"12px"}}>ログイン</Button> */}
                                        </div>
                                    ):(
                                        <>
                                          <IconButton onClick={(event) => handleDrawerToggle(event)}><MenuIcon/></IconButton>
                                          <Drawer open={open} onClose={handleDrawerToggle} variant="temporary" anchor="right" ModalProps={{keepMounted: true}}>
                                              <List>
                                                  <ListItem button onClick={() => dispatch(push('/home'))}>
                                                    <ListItemText primary={"コレクター登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                                  <ListItem button onClick={() => dispatch(push('/i_home'))}>
                                                    <ListItemText primary={"イラストレーター登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                              </List>
                                              <Button style={{color:"white", backgroundColor:"#808080", width:"80px", margin:"0 auto"}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</Button>
                                          </Drawer>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div style={{maxWidth:"800px", margin:"0 auto"}}>
                <h2 className="center">プライバシーポリシー</h2>
                <div className="module-spacer--medium"/>
                <p>制定 2022年3月10日</p>
                <p>
                    株式会社PotLand（以下「当社」といいます。）は、当社の提供するサービス（以下「本サービス」といいます。）における、お客様についての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
                </p>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第1条(収集する利用者情報及び収集方法)</h4>
                <ol>
                    <li>本ポリシーにおいて、「利用者情報」とは、お客様の識別に係る情報、通信サービス上の行動履歴、その他お客様またはお客様の端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。</li>
                    <li>本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。</li>
                    <ol>
                        <li>お客様からご提供いただく情報<br/>本サービスを利用するために、または本サービスの利用を通じてお客様からご提供いただく情報は以下のとおりです。</li>
                        <ol>
                            <li>氏名、生年月日等プロフィールに関する情報</li>
                            <li>メールアドレス、電話番号等連絡先に関する情報</li>
                            <li>クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報</li>
                            <li>入力フォームその他当社が定める方法を通じてお客様が入力または送信する情報</li>
                        </ol>
                        <li>お客様が本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報<br/>お客様が、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。</li>
                        <ol>
                            <li>当該外部サービスでお客様が利用するID</li>
                            <li>その他当該外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報</li>
                        </ol>
                        <li>お客様が本サービスを利用するにあたって、当社が収集する情報<br/>当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。</li>
                        <ol>
                            <li>リファラ</li>
                            <li>IPアドレス</li>
                            <li>サーバーアクセスログに関する情報</li>
                            <li>Cookie、ADID、IDFAその他の識別子</li>
                        </ol>
                        <li>当社がお客様の個別同意に基づいて収集する情報<br/>当社は、お客様が本プライバシーポリシーに定める方法または利用規約への同意により個別に同意した場合、当社は以下の情報を利用中の端末から収集します。</li>
                        <ol><li>位置情報</li></ol>
                    </ol>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第2条(利用目的)</h4>
                <ol>
                    <li>本サービスのサービス提供にかかわる利用者情報（取得した仮名加工情報も含みます）の具体的な利用目的は以下のとおりです。</li>
                    <ol>
                        <li>本サービスに関する登録の受付、本人確認、お客様認証、お客様設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため</li>
                        <li>お客様のトラフィック測定及び行動測定のため</li>
                        <li>取得した閲覧履歴や購買履歴等の情報を分析して、趣味・思考に応じた新商品・サービスの広告のため</li>
                        <li>広告の配信、表示及び効果測定のため</li>
                        <li>本サービスに関するご案内、お問い合わせ等への対応のため</li>
                        <li>本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</li>
                        <li>本サービスに関する規約等の変更などを通知するため</li>
                    </ol>
                    <li>当社はCookie等により収集された閲覧履歴及びその分析結果を取得したときは、当社においては個人データとして、マーケティングの目的で利用します。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第3条(通知・公表または同意取得の方法、利用中止要請の方法)</h4>
                <ol>
                    <li>以下の利用者情報については、その収集が行われる前にお客様の同意を得るものとします。</li>
                    <ol><li>位置情報</li></ol>
                    <li>お客様は、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第4条(Cookieの利用)</h4>
                <ol>
                    <li>本サービスでは、提携先が、お客様の端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。</li>
                    <li>当社はGoogle Analyticsから提供されるCookieを使用し、マーケティングのためにアクセス情報、お客様情報（興味、性別、年齢層）を取得することがあります。なお、お客様個人を特定する方法で収集・保存することはありません。この機能の拒否のためにはGoogle アナリティクスオプトアウトアドオンをウェブブラウザにダウンロードしてインストールしてください。</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第5条(第三者提供)</h4>
                <ol>
                    当社は、利用者情報のうち、個人情報については、あらかじめお客様の同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
                    <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
                    <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                    <li>本プライバシーポリシーの定めに従って、第三者へ個人情報が提供される場合</li>
                    <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
                    <li>その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合</li>
                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第6条(個人情報の訂正及び利用停止等)</h4>
                <ol>
                    <li>当社は、お客様から、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、お客様ご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をお客様に通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。</li>
                    <li>当社は、お客様から、お客様の個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、お客様ご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をお客様に通知します。</li>
                    <li>個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、前二項の規定は適用されません。</li>

                </ol>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;第7条(お問い合わせ窓口)</h4>
                <ol>
                    個人情報に関するお客様からのお問い合わせは、お客様がご利用になったサービス等に関連する各事業のご相談窓口にて対応させていただきます。当社が講じている個人情報の安全管理措置の詳細についても、下記の連絡先にお問い合わせください。<br/><br/>                
                    〒215-0021<br/>
                    神奈川県川崎市麻生区上麻生7丁目18-24-A201<br/>
                    株式会社PotLand<br/>
                    個人情報取扱責任者:蒲生智哉

                </ol>
                <div className="module-spacer--medium"/>
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default Policy