import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Checkbox, FormControlLabel, } from '@material-ui/core'
import {getUserId } from '../reducks/users/selectors'
import React, {useEffect, useState, useCallback} from 'react'
import { TextField, Dialog, DialogTitle, Button, FormControl, OutlinedInput, InputAdornment, DialogActions, DialogContent, Typography} from '@material-ui/core'
import {Divider} from '@material-ui/core'
import { db} from '../firebase'
import {PrimaryButton, GrayButton, SampleImageArea} from '../components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import noImage from '../assets/noImage.png'
import { saveArt } from '../reducks/arts/operations'
import { Helmet } from 'react-helmet-async'
import {retrieveAccount} from '../reducks/stripes/operations'
import noHeader from '../assets/noimageheader.png'
import { ClosableDrawer } from '../components/Header'

const ArtEdit = () => {    

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)    

    const id = window.location.pathname.split('/art/edit/')[1]

    const [requestUid, setRequestUid] = useState('')
    const [requestTitle, setRequestTitle] = useState('')     
    const [titleImg, setTitleImg] = useState(noHeader)
    const [name, setName] = useState('')
    useEffect(() => {
        db.collection('requests').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setRequestUid(data.requestUid)
            setRequestTitle(data.title)   

            db.collection('users').doc(data.requestUid).get().then(snapshot => {
                const user = snapshot.data()
                setName(user.username)
            })

            if(data.img[0].path){
                setTitleImg(data.img[0].path)
            }
        })
    },[id])

    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')
    const [accountId, setAccountId] = useState('')
    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                setAccountId(data.account_id)
                if(data){
                    if(data.icons === ""){  
                        setIcons(noImage)
                    }
                    if(data.icons[0].path){
                        setIcons(data.icons[0].path)
                    }
                }   
            })
        }
    },[uid])    

    
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [img, setImg] = useState([])
    const [price, setPrice] = useState(0)

    const inputTitle = useCallback((e) => {
        setTitle(e.target.value)
    },[setTitle])

    const inputDesc = useCallback((e) => {
        setDesc(e.target.value)
    },[setDesc])

    const inputPrice = useCallback((e) => {
        setPrice(e.target.value)
    },[setPrice])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])

    const [must, setMust] = useState("")
    const [open, setOpen] = useState(false)


    const [loading, setLoading] = useState(false)
    const [clickAction, setClickAction] = useState(false)
    const hanldleCheck = useCallback((requestUid, title, desc, img, id, price, useCase) => {        
        if(title === "" || desc === ""){
            setMust("mismatch")
            setOpen(true)
            return false
        }
        if(freeCheck === true && payCheck === true){
            setMust('two')
            setOpen(true)
            return false
        }
        if(freeCheck === false && payCheck === false){
            setMust('zero')
            setOpen(true)
            return false
        }
        if(price < 500 && price > 0){
            setMust('little')
            setOpen(true)
            return false
        }
        if(img === ""){
            setMust("noImage")
            setOpen(true)
            return false
        }        
        setLoading(true)
        setClickAction(true)
        dispatch(saveArt(requestUid, title, desc, img, id, price , accountId, useCase))
    })

    const handleClose = () => {
        setMust('')
        setOpen(false)
    }

    const [submit, setSubmit] = useState(false)
    useEffect(() => {
        (async () => {
            if(accountId !== ""){
                const accountData = await retrieveAccount(accountId)
                if(accountData){
                    setSubmit(accountData.details_submitted)
                }
            }
        })()
    },[accountId])

    const [stripeOpen, setStripeOpen] = useState(false)

    const handleStripe = () => {
        setStripeOpen(true)
    }
    const handleStripeClose = () => {
        setStripeOpen(false)
    }

    //checkbox
    const [freeCheck, setFreeCheck] = useState(false)
    const handleFreeCheck = () => {
        setFreeCheck(!freeCheck)
    }
    
    const [payCheck, setpayCheck] = useState(false)
    const handlePayCheck = () => {
        setpayCheck(!payCheck)
    }

    const [useCase, setUseCase] = useState(true) 
    const handleUseCase = () => {
        setUseCase(!useCase)
    }

    return(
        <>
        <Helmet
          title="投稿フォーム/Pallet"
        />
        <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                    <ClosableDrawer uid={uid} icons={icons} notifis={notifis} category={category} />
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
        <div className="module-spacer--medium"/>
        <div className='center' maxWidth="780px">
            <div style={{position:"relative"}} className="art_header">
                <img src={titleImg} alt="p" className='art_list_header'/>
                <p style={{position:"absolute", color:"#fff"}} className="art_list_title">{requestTitle}</p>
                <p style={{position:"absolute", color:"#fff"}} className="art_list_username">{name}</p>
            </div>
        </div>
        <div className='module-spacer--small'/>                          
        <div className="center" style={{maxWidth:"780px"}}>
            <p className='menu_title'>タイトル<span style={{fontWeight:"normal", fontSize:"10px", marginLeft:"10px"}}>(30文字以内)</span><span className='title_required'>※必須</span></p>
            <div style={{textAlign:"left", marginLeft:"10px", marginTop:"-10px"}}>
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  value={title}
                  onChange={inputTitle}
                  type="text"
                  rows={1}
                  muitiline={false}
                />
                {title.length > 30 && (
                    <p style={{textAlign:"left"}} className="title_required"><span style={{fontWeight:"bold"}}>[注意]</span>規定文字数を超えています!</p>
                )}
                <p style={{color:"#808080", textAlign:"left", marginleft:"10px", fontSize:"10px"}}>例) 旅する〇〇ちゃん</p>
            </div>
            <p className="menu_title">説明<span style={{fontWeight:"normal", fontSize:"10px", marginLeft:"10px"}}>(250文字以内)</span><span className="title_required">※必須</span></p>
            <div style={{textAlign:"left", marginLeft:"10px", marginTop:"-10px"}}>
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  value={desc}
                  onChange={inputDesc}
                  type="text"
                  rows={4}
                  multiline={true}
                />      
                {desc.length > 250 && (
                    <p style={{textAlign:"left"}} className="title_required"><span style={{fontWeight:"bold"}}>[注意]</span>規定文字数を超えています!</p>
                )}          
                <p style={{color:"#808080", textAlign:"left", marginleft:"10px", fontSize:"10px"}}>例)良いお題だったので、楽しく描けました！ 〇〇ちゃんのファンの方々にも気にってもらえたら嬉しいです！</p>
            </div>                       
            <p className="menu_title">画像<span className="title_required">※必須</span></p>
            
            <div style={{maxWidth:780}} className="center">
                <SampleImageArea img={img} setImg={setImg}/>
            </div>
            <p className="menu_title">
                料金
                <span style={{fontWeight:"normal", fontSize:"10px", marginLeft:"10px"}}>※第三者(お題を投稿したコレクター以外)が購入した時に発生する料金</span>
                <span className="title_required">※必須</span>
            </p>            
            <p style={{textAlign:"left", fontSize:"12px",backgroundColor:"#FEF8F5", borderRadius:"12px", color:"#808080", paddingLeft:"20px", paddingTop:"10px", paddingBottom:"10px", border:"solid 1px #fafafa"}}><span style={{fontWeight:"bold"}}>販売手数料について</span><br/>コレクター15%、サービス手数料10%</p>
            <div style={{display:"flex"}}>
                <FormControl>
                    <FormControlLabel
                      control={<Checkbox check={freeCheck} onChange={handleFreeCheck} name="free"/>}
                      label="無料"
                    /> 
                </FormControl>
                <div>
                    <FormControl>
                        <FormControlLabel
                        control={<Checkbox check={payCheck} onChange={handlePayCheck} name="pay"/>}
                        label="有料"
                        /> 
                    </FormControl>
                    {payCheck === true && (
                        <div style={{textAlign:"left", marginLeft:"10px", marginTop:"-10px"}}>
                            <FormControl margin="dense" fullWidth={false} variant='oulined' style={{width:120}}>
                                <OutlinedInput
                                    variant="outlined"                       
                                    value={price}
                                    type="number"
                                    onChange={inputPrice}
                                    size="small"
                                    startAdornment={
                                        <InputAdornment position="start">¥</InputAdornment>
                                    }                            
                                />
                            </FormControl>
                            {/* {price < 50 &&(
                                <p style={{textAlign:"left", color:"red", fontWeigth:"bold"}}>※最低販売価格は50円です。50円以上でお願いします。</p>
                            )} */}
                        </div>
                    )}
                </div>   
            </div>
            <p style={{fontSize:"12px", color:"#808080", textAlign:"left"}}>※「有料」の場合、最低販売価格は<span style={{textDecoration:"underline"}}>500円</span>です</p>             
            <div className='module-spacer--small'/>
            <p className="menu_title">使用用途<span style={{fontWeight:"normal", fontSize:"10px", marginLeft:"10px"}}>コレクター以外の方が作品を購入した際の使用用途について</span><span className="title_required">※必須</span></p>
            <div style={{textAlign:"left", marginLeft:"10px", marginTop:"-10px"}}>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={useCase} onChange={handleUseCase} name="free"/>}
                        label="壁紙など(SNSでの使用を許可しない)"
                    /> 
                </FormControl>
                <br/>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={!useCase} onChange={handleUseCase} name="free"/>}
                        label="SNSのアイコンやヘッダーなどでの使用を許可する"
                    /> 
                </FormControl>
            </div>
            <div className='module-spacer--medium'/>
            {submit === true ? (
                <>
                    <PrimaryButton
                        disabled={clickAction}
                        label="投稿する"
                        onClick={() => hanldleCheck(requestUid, title, desc, img, id, price, useCase)}
                    />
                    {loading === true && (
                        <p>投稿中です.....</p>
                    )}
                    {must === "mismatch" && (
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>必須項目が未入力です</DialogTitle>
                        </Dialog>
                    )}
                    {must === "noImage" &&(
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>画像を選択してください</DialogTitle>
                        </Dialog>
                    )}
                    {must === "two" && (
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>料金は「無料」か「有料」どちらかのみ選択してください</DialogTitle>
                        </Dialog>
                    )}
                    {must === "little" && (
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>最低料金価格は500円です。</DialogTitle>
                        </Dialog>
                    )}
                    {must === "zero" && (
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>料金をどちらか選択してください</DialogTitle>
                        </Dialog>
                    )}
                </>
            ):(
                <>
                    {price === 0 ? (
                        <>
                            <PrimaryButton
                                disabled={clickAction}
                                label="投稿する"
                                onClick={() => hanldleCheck(requestUid, title, desc, img, id, price)}
                            />
                            {must === "mismatch" && (
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>必須項目が未入力です</DialogTitle>
                                </Dialog>
                            )}
                            {must === "noImage" &&(
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>画像を選択してください</DialogTitle>
                                </Dialog>
                            )}
                            {must === "two" && (
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>料金は「無料」か「有料」どちらかのみ選択してください</DialogTitle>
                                </Dialog>
                            )}
                            {must === "zero" && (
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>料金をどちらか選択してください</DialogTitle>
                                </Dialog>
                            )}                            
                        </>
                    ):(
                        <>
                            <PrimaryButton
                                label="投稿する"
                                onClick={() => handleStripe()}
                            />
                            <Dialog open={stripeOpen} onClose={handleStripeClose} aria-labelledby="alert-dialog-title">
                                <DialogTitle id="alert-dialog-title">受け取りの口座が登録されていません。</DialogTitle>
                                <DialogContent>
                                    <Typography>
                                        口座設定後、投稿が可能になります。
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => dispatch(push('/payment_bank/' + uid))}>口座登録をする</Button>
                                </DialogActions>
                            </Dialog>
                        
                        </>
                    )}
                </>
            )}
            <br/>
            <div style={{backgroundColor:"#fafafa", maxWidth:"600px", margin:"0 auto", borderRadius:"8px", border:"solid 1px #e4e8ed"}}>
                <p style={{textAlign:"left", marginLeft:"10px", fontSize:"14px"}}>投稿された作品が<a href="https://pallet.fun/rules" target="_blank">利用規約15条-6(禁止事項)</a>に該当すると判断した場合、無許可で作品を削除する場合がございます。</p>
            </div>
            <div className='module-spacer--small'/>
            <GrayButton
              label="戻る"
              onClick={() => dispatch(push('/art_request/all/' + id))}
            />
            <div className='module-spacer--medium'/>
        </div>
    </>
    )
}

export default ArtEdit