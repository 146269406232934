export const SIGN_UP = "SIGN_UP";
export const signUpAction = (userState) => {
    return{
        type:"SIGN_UP",
        payload:{
            isSignedIn:true,
            uid:userState.uid,
            username:userState.username,
            email:userState.email,
            category:userState.category,
            icons:userState.icons,
            customer_id:userState.customer_id,
            payment_method_id:userState.payment_method_id,
            account_id: userState.account_id,
        }
    }
}

export const SIGN_IN = "SIGN_IN";
export const signInAction = (userState) => {
    return {
        type:"SIGN_IN",
        payload:{
            isSignedIn:true,
            uid:userState.uid,
            username:userState.username,
            email:userState.email,
            category:userState.category,
            icons:userState.icons,
            desc:userState.desc,
            customer_id:userState.customer_id,
            payment_method_id:userState.payment_method_id,
            account_id: userState.account_id,
        }
    }
}

export const SIGN_OUT = "SIGN_OUT";
export const signOutAction = (userState) => {
    return{
        type:"SIGN_OUT",
        payload:{
            isSignedIn: false,
            uid:"",
            username:"",
            email:"",
            icons:"",
            desc:"",
            category:"",
            // customer_id:userState.customer_id,
            // payment_method_id:userState.payment_method_id,
            // account_id: userState.account_id,
        }
    }
}

export const SAVE_PROFILE = "SAVE_PROFILE";
export const saveProfileAction = (userState) => {
    return{
        type:"SAVE_PROFILE",
        payload:{
            desc:userState.desc,
            icons:userState.icons,
            username:userState.username,
            email:userState.email,
            url:userState.url,
            twitterUrl:userState.twitterUrl
        }
    }
}

export const ART_SIGN_UP = "ART_SIGN_UP";
export const artSignUpAction = (userState) => {
    return{
        type:"ART_SIGN_UP",
        payload:{
            isSignedIn:true,
            uid:userState.uid,
            username:userState.username,
            email:userState.email,
            category:userState.category,
            icons:userState.icons,
            customer_id:userState.customer_id,
            payment_method_id:userState.payment_method_id,
            account_id: userState.account_id,
        }
    }
}


export const ART_SIGN_IN = "ART_SIGN_IN";
export const artSignInAction = (userState) => {
    return {
        type:"ART_SIGN_IN",
        payload:{
            isSignedIn:true,
            uid:userState.uid,
            username:userState.username,
            email:userState.email,
            category:userState.category,
            icons:userState.icons,
            desc:userState.desc,
            customer_id:userState.customer_id,
            payment_method_id:userState.payment_method_id,
            account_id: userState.account_id,
        }
    }
}

export const UPDATE_USER_STATE = "UPDATE_USER_STATE"
export const updateUserStateAction= (userState) => {
    return {
        type: "UPDATE_USER_STATE",
        payload: userState
    }
}

export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT"
export const updateUserAccountAction = (userState) => {
    return {
        type: "UPDATE_USER_ACCOUNT",
        payload: userState
    }
}

export const UPDATE_USER_LOGINURL = "UPDATE_USER_LOGINURL"
export const updateUserLoginUrl = (userState) => {
    return {
        type:"UPDATE_USER_LOGINURL",
        payload: userState
    }
}