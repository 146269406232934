import React from 'react'
import { Button } from '@material-ui/core'

const PrimaryButton = (props) => {
    

    return(
        <Button className="primary_button" onClick={() => props.onClick()} variant="contained" style={{textTransform: "none"}} disabled={props.disabled}>
            {props.label}
        </Button>
    )
}

export default PrimaryButton

