import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider} from '@material-ui/core'
import logo from "../assets/logo.png"
import { ArtRequestHeaderMenu } from '../components/Header'

import React, {useState, useCallback} from 'react'
import { useDispatch } from 'react-redux'
import {push} from "connected-react-router"
import { PrimaryButton, TextInput } from '../components/UIkit'
import {makeStyles} from '@material-ui/core/styles'
import { IconButton, FormControl, InputAdornment, InputLabel, OutlinedInput, Button, Typography, Dialog, DialogTitle } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconArea } from '../components/UIkit'
import { artRequestSignUp } from '../reducks/users/operations'
import { Helmet } from 'react-helmet-async'


const useStyles = makeStyles({
    text:{
        backgroundColor:"#f0f0f0",
        marginBottom:16
    },
    header_root:{
        flexGrow:0,
        maxWidth:900,
        margin:"auto"
    },
    header_menuBar:{
        backgroundColor:"#fff",
        color:"444",
        width:"100%"
    },
    header_toolBar:{
        margin:"0 auto",
        width:"100%",
        padding:0
    },
    header_iconButton:{
        margin:"0 0 0 auto",
        display:"block"
    },
    header_iconImage:{
        width:40,
        height:40,
        borderRadius:"50%"
    },
})

const ArtRequestLogin = () => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const id =window.location.pathname.split('/art_request_login/')[1]

    const [username, setUsername] = useState(''),
          [email, setEmail] = useState(''),
          [password, setPassword] = useState(''),
          [confirmPassword, setConfirmPassword] = useState(''),
          [icon, setIcon] = useState('');
    
    const category = "normal"

    const [value, setValue] = useState(false)
    const handleClickShowPass = () => {
        setValue(!value)
    }

    const [confirmValue, setConfirmValue] = useState(false)
    const handleClickConfirmPass = () => {
        setConfirmValue(!confirmValue)
    }

    const inputUsername = useCallback((e) => {
        setUsername(e.target.value)
    },[setUsername])

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    },[setEmail])

    const inputPassword = useCallback((e) => {
        setPassword(e.target.value)
    },[setPassword])

    const inputConfirmPassword = useCallback((e) => {
        setConfirmPassword(e.target.value)
    },[setConfirmPassword])

    const [must, setMust] = useState('')
    const [open, setOpen] = useState(false)

    const saveData = (username, email, password, confirmPassword, icon, category, id) => {
        if(username === "" || email === "" || password ===""){
            setMust('mismatch')
            setOpen(true)
            return false
        }
        if(password !== confirmPassword){
            setMust('passwordMismatch')
            setOpen(true)
            return false
        }

        dispatch(artRequestSignUp(username, email, password, confirmPassword, icon, category, id))
    }

    const handleClose = () => {
        setMust('')
        setOpen(false)
    }

    return(
        <>
        <Helmet
         title="ログインフォーム/Pallet"
        />
        <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/art_request_home/' + id))} style={{cursor:"pointer"}}/>
                    <div className="header_iconButton">
                        <ArtRequestHeaderMenu id={id} key={id}/>
                    </div>                    
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
        <div className='c-section-container'>        
            <div className='module-spacer--medium'/>

            
            <h2 className='center'>新規登録</h2>
            <div className='module-spacer--small'/>
            <IconArea icons={icon} setIcons={setIcon} className="center"/>
            <div className='module-spacer--medium'/>
            <div className='module-spacer--medium'/>
            <TextInput
              fullWidth={true} multiline={false} required={true} label="ユーザー名"
              rows={1} vlaue={username} type="text" onChange={inputUsername} size={"medium"}
            />
            <TextInput
              fullWidth={true} multiline={false} required={true} label="メールアドレス"
              rows={1} vlaue={email} type="email" onChange={inputEmail}
            />
            <FormControl fullWidth={true} size="medium" required={true} margin="dense" variant='outlined' label="パスワード" className={classes.text}>
                <InputLabel variant='outlined' htmlFor='password'>パスワード</InputLabel>
                <OutlinedInput
                    variant="outlined"
                    label={"パスワード"}
                    endAdornment={
                        <InputAdornment position="end" aria-label="toggle password visibility">
                            <IconButton onClick={handleClickShowPass}>
                                {value ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={inputPassword}
                    value={password}
                    type={value ? "text" : "password"}
                />
            </FormControl>
            <FormControl fullWidth={true} size="medium" required={true} margin="dense" variant='outlined' label="パスワード" className={classes.text}>
                <InputLabel variant='outlined' htmlFor='password'>確認用パスワード</InputLabel>
                <OutlinedInput
                    variant="outlined"
                    label={"確認用パスワード"}
                    endAdornment={
                        <InputAdornment position="end" aria-label="toggle password visibility">
                            <IconButton onClick={handleClickConfirmPass}>
                                {confirmValue ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={inputConfirmPassword}
                    value={confirmPassword}
                    type={confirmValue ? "text" : "password"}
                />
            </FormControl>
            <div className='module-spacer--medium'/>
            <div className='center'>
                <PrimaryButton 
                label={"アカウント登録する"}
                onClick={() => saveData(username, email, password, confirmPassword, icon, category, id)}
                />
                {must === "mismatch" && (
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>必須項目が未記入です</DialogTitle>
                    </Dialog>
                )}
                {must === "passwordMismatch" && (
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>パスワードが一致しません</DialogTitle>
                    </Dialog>
                )}
            </div>
            <div className='module-spacer--medium'/>
            <div className='center'>
                <div className='module-spacer--small'/>
                <p style={{fontWeight:"bold"}}>すでにアカウントをお持ちの方は<Button style={{margin:0, padding:0, outline:"none"}} onClick={() => dispatch(push('/art_request_signin/' + id))}><Typography style={{color:"#0000ff", fontWeight:"bold"}}>こちら</Typography></Button></p>
            </div>
            <div className='module-spacer--medium'/>
    </div>
    </>
    )
}

export default ArtRequestLogin