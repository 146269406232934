import React,{useState, useEffect} from 'react'
import { push } from 'connected-react-router'
import {useDispatch, useSelector} from 'react-redux'
import {getUserId} from '../../reducks/users/selectors'
import {db, FirebaseTimestamp} from '../../firebase'
import noImage from '../../assets/noImage.png'
import {Avatar} from '@material-ui/core' 

const InfluencerListCard = (props) => {
    const dispatch = useDispatch()
    const id = props.id

    const [name, setName] = useState('')
    const [icons, setIcons] = useState('')
    useEffect(() => {
        db.collection('users').doc(id).get().then(snapshot => {
            const data = snapshot.data()
            setName(data.username)
            if(data.icons === ""){
                setIcons(noImage)
            }
            if(data.icons[0].path){
                setIcons(data.icons[0].path)
            }
        })
    },[id])

    return (
        <>
            <div style={{maxWidth:170, cursor:"pointer"}} className='art_show_root' onClick={() => dispatch(push('/art_list/all/' + id))}>
                <Avatar style={{width:150, height:150}}>
                    <img src={icons} style={{width:150, height:150}}/>
                </Avatar>
            </div>
            <p style={{textAlign:"center", maxWidth:"150px", cursor:"pointer", margin:"0 auto"}} onClick={() => dispatch(push('/art_list/all/' + id))}>{name}</p>
        </>
    )
}

export default InfluencerListCard