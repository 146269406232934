import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet-async'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Grid } from '@material-ui/core'
import noImage from '../assets/noImage.png'
import { getUserId } from '../reducks/users/selectors'
import {useSelector, useDispatch } from 'react-redux'
import {push} from 'connected-react-router'
import { db } from '../firebase'
import {UserDownloadDetail } from '../components/Arts';
import { ClosableDrawer } from '../components/Header';

const FavoriteList = () => {
    
    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')
    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()
            setCategory(data.category)
            if(data.icons === ""){
                setIcon(noImage)
            }
            if(data.icons[0].path){
                setIcon(data.icons[0].path)
            }
        })
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])

    //downloadした作品一覧を見せる(influencer)
    const [favorites, setFavorites] = useState([])
    useEffect(() => {
        const unsubscribe = db.collection('favorites').where('uid', "==", uid).orderBy('created_at',"desc").onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach((doc) => {
                const data = doc.data()
                list.push(data)
            })
            setFavorites(list)
        })
        return () => unsubscribe()
    },[uid])

    //downloadした作品一覧を見せる(normal)    
    const [downloads, setDownloads] = useState([])
    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('favorites').where('owner', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach((doc) => {
                const data = doc.data()                
                list.push(data)
            })
            setDownloads(list)
        })
        return () => unsubscribe()
    },[uid])

    return (
        <>
            <Helmet
             title="ダウンロードした作品一覧/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div className='center' style={{maxWidth:800, margin:"auto"}}>
                <h3>ダウンロードした作品一覧</h3>
                {category === "influencer" && (
                    <>
                        <div style={{display:"flex", textAlign:"center"}}>                      
                            <p className='select_favorite_collecter_page' style={{color:"#808080"}} onClick={() => dispatch(push('/art_like/collecter/' + uid))}>自分のお題への作品</p>                    
                            <p className="select_favorite_request_page" >他のコレクターの作品</p>
                        </div>
                        <Divider/>
                        <div className='module-spacer--small'/>
                    </>
                )}
                <div className="center">
                <Grid style={{alignItems:"stretch", display:"flex", flexWrap:"wrap", justifyContent:"space-around"}} onContextMenu={(e) => e.preventDefault()} >
                    {/* {category === "influencer" ? (
                        <>
                            {favorites.length > 0 ?(
                                favorites.map(favorite => (
                                    <div style={{margin:0}} key={favorite.id}>
                                        <ArtsShowDetail
                                            id={favorite.artId}  userId={uid} createrÎd={favorite.createrId}
                                        />
                                    </div>
                                ))
                            ):(
                                <div className="center">
                                    <p>まだ作品をダウンロードしていません</p>
                                    <p>お題を投稿して作品を集めよう!</p>
                                    <div className='module-spacer--medium'/>
                                    <PrimaryButton
                                    label="お題を投稿する"
                                    onClick={() => dispatch(push('/art_request/edit/' + uid))}
                                    />
                                    <div className="module-spacer--medium"/>
                                    <div className="module-spacer--medium"/>
                                    <div className="module-spacer--medium"/>
                                </div>
                            )}
                        </>
                    ):( */}                        
                    {downloads.length > 0 ? (
                        downloads.map(download => (
                            <div style={{margin:0}} key={download.id}>
                                <UserDownloadDetail id={download.artId}/>
                            </div>
                        ))
                    ):(
                        <div className="center">
                            <div className="module-spacer--medium"/>
                            <p>まだ作品をダウンロードしていません。</p>
                            <div className="module-spacer--medium"/>
                            <div className="module-spacer--medium"/>
                            <div className="module-spacer--medium"/>
                            <div className="module-spacer--medium"/>
                        </div>
                    )}
                        
                        
                    {/* )} */}
                </Grid>
            </div>
            <div className="module-spacer--medium"/>
            <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default FavoriteList