import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Dialog, DialogTitle} from '@material-ui/core'
import {getUserId, getCategory} from '../reducks/users/selectors'
import React,{useState, useEffect, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconArea, GrayButton, PrimaryButton} from '../components/UIkit'
import {db} from '../firebase'
import {TextField } from '@material-ui/core'
import { push } from 'connected-react-router'
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkIcon from '@material-ui/icons/Link'
import {List, ListItem, ListItemAvatar, ListItemText, Typography, Divider} from '@material-ui/core'
import {saveProfile} from '../reducks/users/operations'
import noImage from '../assets/noImage.png'
import { Helmet } from 'react-helmet-async'
import { ClosableDrawer } from '../components/Header'

const ProfileEdit = () => {

    const id = window.location.pathname.split('/profile_edit/')[1]

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)
    const category = getCategory(selector)

    const [username, setUsername] = useState('')
    const [icons, setIcons] = useState("")
    const [email, setEmail] = useState('')
    const [desc, setDesc] = useState('')
    const [url, setUrl] = useState('')

    const [loginWay, setLoginWay] = useState('')
    const [twitterUrl, setTwitterUrl] = useState('')

    const inputUsername = useCallback((e) => {
        setUsername(e.target.value)
    },[setUsername])

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    },[setEmail])

    const inputDesc = useCallback((e) => {
        setDesc(e.target.value)
    },[setDesc])

    const inputUrl = useCallback((e) => {
        setUrl(e.target.value)
    },[setUrl])

    const inputTwitterUrl = useCallback((e) => {
        setTwitterUrl(e.target.value)
    },[setTwitterUrl])

    useEffect(() => {
        const unsubscibe = db.collection('users').doc(id).onSnapshot((doc) => {
            const data = doc.data()
            if(data){
                setUsername(data.username)
                setDesc(data.desc)
                setEmail(data.email)
                setUrl(data.url)
                setLoginWay(data.loginWay)
                setTwitterUrl(data.screen_name)

                if(data.icons[0].id){
                    setIcons(data.icons)
                }
            }
        })
        return () => unsubscibe()
    },[id])

    const text = "例) \n0から独学でイラストを始めました。| 現在4ヶ月目 | ファンアートをよく描きます。| 最近は〇〇ちゃん推し "

    const [icon, setIcon] = useState()

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                if(data){
                    if(data.icons === ""){
                        setIcon(noImage)
                    }
                    if(data.icons[0].path){
                        setIcon(data.icons[0].path)
                    }
                }                
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])

    const [must, setMust] = useState('')
    const [open, setOpen] = useState(false)

    const saveData = (id, icons, username, desc, url, twitterUrl, email, category, loginWay) => {
        if(username === ""){
            setMust("noName")
            setOpen(true)
            return false
        }
        if(loginWay === "email" && email === ""){
            setMust('noEmail')
            setOpen(true)
            return false
        }

        dispatch(saveProfile(id, icons, username, desc, url, twitterUrl, email, category, loginWay))
    }

    const handleClose = () => {
        setMust('')
        setOpen(false)
    }

    return (
        <>
        <Helmet
          title="プロフィール編集/Pallet"
        />
        <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                    <ClosableDrawer icons={icon} uid={uid} category={category} notifis={notifis} />               
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
        </div>
        <div className='center' style={{maxWidth:"800px"}}>
            <div className='module-spacer--small'/>
            <IconArea icons={icons} setIcons={setIcons}/>
            <div className='module-spacer--small'/>
            <div className='module-spacer--medium'/>
            <div className="center">
                <List>
                    <Divider/>
                    <ListItem divider={true}> 
                        <ListItemText primary={<Typography style={{fontWeight:"bold"}}>名前</Typography>}/>
                        <ListItemText>
                        <TextField
                            fullWidth={true}
                            rows={1}
                            type="text"
                            onChange={inputUsername}
                            value={username}
                        />
                        </ListItemText>
                    </ListItem>
                    
                    {loginWay === "email" && (
                        <ListItem divider={true}>
                            <ListItemText primary={<Typography style={{fontWeight:"bold"}}>メールアドレス</Typography>}/>
                            <ListItemText>
                            <TextField
                                fullWidth={true}
                                rows={1}
                                type="email"
                                onChange={inputEmail}
                                value={email}
                                // InputProps={{disableUnderline:true}}
                            />
                            </ListItemText>
                        </ListItem>
                    )}
                    <ListItem divider={true}>
                        <ListItemText primary={<Typography style={{fontWeight:"bold"}}>自己紹介</Typography>}/>
                        <ListItemText>
                        <TextField
                            fullWidth={true}
                            rows={6}
                            multiline={true}
                            type="text"
                            onChange={inputDesc}
                            value={desc}
                            placeholder={text}
                        />
                        </ListItemText>
                    </ListItem>
                    <ListItem divider={true}>
                        <ListItemAvatar>
                            <LinkIcon/>
                        </ListItemAvatar>
                        <ListItemText>
                            <TextField
                              fullWidth={true}
                              rows={1}
                              type="text"
                              onChange={inputUrl}
                              value={url}
                              placeholder="Website(httpsから記入)"
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem divider={true}>
                        <ListItemAvatar>
                            <TwitterIcon style={{color:"#1DA1F2"}}/>
                        </ListItemAvatar>
                        <ListItemText>
                        <TextField
                            fullWidth={true}
                            rows={1}
                            type="text"
                            onChange={inputTwitterUrl}
                            value={twitterUrl}
                            placeholder='Twitterのユーザー名(@以降)'
                            // InputProps={{disableUnderline:true}}
                            // variant="outlined"
                        />
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
            <div className='module-spacer--small'/>
            <PrimaryButton
                label="変更する"
                onClick={() => saveData(id, icons, username, desc, url, twitterUrl, email, category, loginWay)}
            />
            {must === "noName" && (
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>名前の欄が空白です。</DialogTitle>
                </Dialog>
            )}
            {must === "noEmail" && (
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle> メールアドレスの欄が空白です。</DialogTitle>
                </Dialog>
            )}
            <div className="module-spacer--small"/>
            {category === "influencer" ? (
                <GrayButton
                label="戻る"
                onClick={() => dispatch(push('/art_list/all/' + uid))}
                />
            ):(
                <GrayButton
                label="戻る"
                onClick={() => dispatch(push('/mypage/' + uid))}
                />
            )}            
            
            <div className="module-spacer--medium"/>
        </div>
      </>
    )
}

export default ProfileEdit