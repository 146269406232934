import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet-async'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, IconButton, Divider, Badge, Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import {signOut} from '../reducks/users/operations'
import logo from '../assets/logo.png'
import noImage from '../assets/noImage.png'
import { getUserId } from '../reducks/users/selectors'
import { useDispatch, useSelector } from 'react-redux'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import SendIcon from '@material-ui/icons/Send';
import { db } from '../firebase'
import { push } from 'connected-react-router'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GetAppIcon from '@material-ui/icons/GetApp';


const RequestForm = () => {

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    //headerのアイコン
    const [icon, setIcon] = useState()
    const [category, setCategory] = useState('')
    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapsnot => {
            const data = snapsnot.data()
            setCategory(data.category)
            if(data.icons === ""){
                setIcon(noImage)
            }
            if(data.icons[0].path){
                setIcon(data.icons[0].path)
            }
        })
    },[uid])

    //通知
    const [notifis, setNotifis] = useState([])
    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () =>  unsubscribe()
    })
    //ヘッダーのメニュー
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Helmet
                title="決済フォーム/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                    {category === "influencer" ? (
                        <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/art_list/all/' + uid))} style={{cursor:"pointer"}}/>
                    ):(                            
                        <img src={logo} alt="logo" width="128px" onClick={() => dispatch(push('/mypage/' + uid))} style={{cursor:"pointer"}}/>
                    )}                 
                        <div className="header_iconButton">
                            <Button style={{color:"white",backgroundColor:"#ff7c21", marginRight:"5px", borderRadius:"12px"}} onClick={() => dispatch(push('/art_request/edit/' + uid))}>
                                <SendIcon style={{marginRight: "6px"}}/>お題を投稿
                            </Button>   
                            <IconButton onClick={() => dispatch(push('/notifications'))} style={{paddingLeft:0}}>
                                <Badge badgeContent={notifis.length} color="error">
                                <NotificationsNoneIcon className="header_iconImage"/>
                                </Badge>
                            </IconButton>
                          {category === "influencer" ? (
                                <>
                                <IconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true" style={{padding:0}}>
                                    <img src={icon} alt="P" className="header_iconImage"/>
                                </IconButton>
                                <Menu 
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                >
                                    <MenuItem onClick={() => dispatch(push('/art_list/all/' + uid))}>
                                    <ListItemIcon>
                                        <HomeIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="ホーム"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                    <ListItemIcon>
                                        <AccountBalanceIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="入金口座の管理"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                    <ListItemIcon>
                                        <PaymentIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="支払いカードの管理"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                    <ListItemIcon>
                                        <GetAppIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="ダウンロードした作品一覧"/>
                                </MenuItem>
                                    <MenuItem onClick={() => dispatch(signOut())}>
                                    <ListItemIcon>
                                        <ExitToAppIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="サインアウト"/>
                                    </MenuItem>
                                </Menu>
                                </>
                                ):(
                                <>
                                <IconButton onClick={handleClick} style={{padding:0}}>
                                    <img src={icon} alt="P" className="header_iconImage"/>
                                </IconButton>
                                <Menu 
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => dispatch(push('/mypage/' + uid))}>
                                    <ListItemIcon>
                                        <HomeIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="ホーム"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => dispatch(push('/art_like/all/' + uid))}>
                                    <ListItemIcon>
                                        <GetAppIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="ダウンロードした作品一覧"/>
                                </MenuItem>
                                    <MenuItem onClick={() => dispatch(push('/payment_bank/' + uid))}>
                                    <ListItemIcon>
                                        <AccountBalanceIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="入金口座の管理"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => dispatch(push('/payment_edit/' + uid))}>
                                    <ListItemIcon>
                                        <PaymentIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="支払いカードの管理"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => dispatch(signOut())}>
                                    <ListItemIcon>
                                        <ExitToAppIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="サインアウト"/>
                                    </MenuItem>
                                </Menu>
                                </>
                          )}
                        </div>         
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
        </>
    )
}

export default RequestForm