import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider, Drawer} from '@material-ui/core'
import logo from "../assets/logo.png"
import React, {useState, useEffect, useCallback} from 'react'
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import MenuIcon from '@material-ui/icons/Menu';
import {Button, Typography} from '@material-ui/core'
import { Helmet } from 'react-helmet-async'
import {db, auth} from '../firebase'
import noImage from '../assets/noImage.png'
import {IconButton, ListItemText, List, ListItem} from '@material-ui/core'

import { ClosableDrawer } from '../components/Header';

const Trade = () => {
    const [uid, setUid] = useState('')
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
            }
        })
    })

    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])

    const dispatch = useDispatch()

    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        setWidth(window.innerWidth)
    })
    

     
    const [open, setOpen] = useState(false)
 
    const handleDrawerToggle = useCallback((event) => {
      if (event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')){
              return;
          }
         setOpen(!open)
    }, [setOpen,open])

    return (
        <>
             <Helmet
            title="Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        {signedIn === true ? (
                            <>
                               <ClosableDrawer icons={icons} uid={uid} category={category} notifis={notifis} />
                            </>
                        ):(
                            <>
                                
                                <img src={logo} alt="logo" width="128px" style={{cursor:"pointer"}} onClick={() => dispatch(push('/'))}/>                                
                                <div className="header_iconButton" >
                                    {width > 500 ? (                                               
                                        <div style={{display:"flex"}}>
                                            {/* <Button className="home_button" style={{fontSize:"13px", marginRight:"10px", backgroundColor:"#f0f0f0", borderRadius:"12px"}} ><Typography className="home_button" style={{fontWeight:"bold", marginRight:"10px"}}>コレクター登録</Typography></Button> */}
                                            <p style={{fontWeight:"bold", fontSize:"14px", marginLeft:50}} >コレクター登録</p>
                                            <p className="home_button" style={{marginLeft:50}} onClick={() => dispatch(push('/i_home'))}>推し事登録</p>
                                            {/* <Button  style={{fontSize:"13px", borderRadius:"12px", marginRight:"10px"}} onClick={() => dispatch(push('/i_home'))}><Typography className="home_button"style={{fontWeight:"bold"}}>イラストレーター登録</Typography></Button> */}
                                            <p className='home_button' style={{marginLeft:50}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</p>
                                            {/* <Button onClick={() => dispatch(push('/influencer/signin'))} style={{color:"white", backgroundColor:"#808080", borderRadius:"12px"}}>ログイン</Button> */}
                                        </div>
                                    ):(
                                        <>
                                          <IconButton onClick={(event) => handleDrawerToggle(event)}><MenuIcon/></IconButton>
                                          <Drawer open={open} onClose={handleDrawerToggle} variant="temporary" anchor="right" ModalProps={{keepMounted: true}}>
                                              <List>
                                              <ListItem button onClick={() => dispatch(push('/home'))}>
                                                    <ListItemText primary={"コレクター登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                                  <ListItem button onClick={() => dispatch(push('/i_home'))}>
                                                    <ListItemText primary={"イラストレーター登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                              </List>
                                              <Button style={{color:"white", backgroundColor:"#808080", width:"80px", margin:"0 auto", borderRadius:"12px"}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</Button>
                                          </Drawer>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className="module-spacer--medium"/>
            <div style={{maxWidth:"800px", margin:"0 auto"}}>
                <h2 className="center">特定商取引法に基づく表記</h2>
                <div className="module-spacer--medium"/>
                <ol>
                    <li style={{fontWeight:"bold"}}>事業者の名称:</li>
                    <p>株式会社PotLand</p>
                    <li style={{fontWeight:"bold"}}>代表者または通信販売に関する業務の責任者の氏名:</li>
                    <p>蒲生智哉</p>
                    <li style={{fontWeight:"bold"}}>住所:</li>
                    <p>神奈川県川崎市麻生区上麻生7丁目18-24-A201</p>
                    <li style={{fontWeight:"bold"}}>メールアドレス</li>
                    <p>contact@potland.co.jp</p>
                    <li style={{fontWeight:"bold"}}>商品の販売価格・サービスの対価:</li>
                    <p>各商品・サービスのご購入ページにて表示する価格</p>
                    <li style={{fontWeight:"bold"}}>対価以外に必要となる費用:</li>
                    <p>本サービスに関するサイトの閲覧、コンテンツのダウンロード及びアプリケーション動作時における通信に必要な通信料</p>
                    <li style={{fontWeight:"bold"}}>支払い方法:</li>
                    <p>決済サービス「Stripe」が定める規約・約款等に基づく。</p>
                    <li style={{fontWeight:"bold"}}>代金の支払い時期:</li>
                    <p>ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。</p>
                    <li style={{fontWeight:"bold"}}>商品引渡しまたはサービス提供の時期:</li>
                    <p>イラストがアップロードされたときより直ちに。</p>
                    <li style={{fontWeight:"bold"}}>返品・キャンセルに関する特約:</li>
                    <p>本サイトで販売する商品・サービスについては、購入手続完了後の返品またはキャンセルをお受けいたしません。なお、商品・サービスに瑕疵がある場合は、利用規約の定めに従って対応します。
                        <br/>商品がソフトウェアの場合、動作環境及び必要スペックはご購入ページで表示しています。
                        <br/>特別な販売条件または提供条件がある商品またはサービスについては、各商品またはサービスの購入ページにおいて条件を表示します。</p>
                </ol>
                <div className="module-spacer--medium"/>
                <div className="module-spacer--medium"/>
            </div>
        </>
    )
}

export default Trade