import React, {useState, useEffect, useCallback} from 'react'
import { Helmet } from 'react-helmet-async'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, IconButton, Divider, Grid, ListItemText, Tooltip, List, ListItem, Button, Drawer} from '@material-ui/core'
import logo from '../assets/logo.png'
import noImage from '../assets/noImage.png'
import { useDispatch } from 'react-redux'
import { db, auth } from '../firebase'
import { push } from 'connected-react-router'
import {InfluencerListCard} from '../components/InfluencerList/'
import {ClosableDrawer} from "../components/Header"
import MenuIcon from '@material-ui/icons/Menu';
import { RequestAllList } from '../components/Requests'
const AdornmentInfluencerList = () => {
    const dispatch = useDispatch()
    const [uid, setUid] = useState('')
    const [signedIn, setSignedIn] = useState(false)
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setSignedIn(true)
                setUid(user.uid)
            }
        })
    })    

    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')
    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').orderBy('created_at', "desc").onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])

    const [influencerLists, setInfluencerLists] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').where('category', "==", "influencer").orderBy('created_at', "desc").onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(doc => {
                const data = doc.data()
                list.push(data)
            })
            setInfluencerLists(list)
        })
        return () => unsubscribe()
    },[])

    const [requestsLists, setRequestLists] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('requests').orderBy('created_at', "desc").onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(doc => {
                const data = doc.data()
                list.push(data)
            })
            setRequestLists(list)
        })
        return () => unsubscribe()
    },[])

    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        setWidth(window.innerWidth)
    })

     
    const [open, setOpen] = useState(false)
 
    const handleDrawerToggle = useCallback((event) => {
      if (event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')){
              return;
          }
         setOpen(!open)
    }, [setOpen,open])

    return (
        <>
            <Helmet
            title="一覧/Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        {signedIn === true ? (
                            <>
                                <ClosableDrawer uid={uid} category={category} icons={icons} notifis={notifis} />
                            </>
                        ):(
                            <>
                                
                                <img src={logo} alt="logo" width="128px" style={{cursor:"pointer"}} onClick={() => dispatch(push('/'))}/>
                                <div className="header_iconButton" >
                                    {width > 500 ? (
                                        <div style={{display:"flex"}}>
                                            <Tooltip title="お題の投稿、イラストの投稿・購入ができます。">
                                                <p style={{fontWeight:"bold", fontSize:"14px", marginLeft:50}} >コレクター登録</p>
                                            </Tooltip>
                                            <Tooltip title="イラストの投稿・購入ができます。">
                                                <p className="home_button" style={{marginLeft:50}} onClick={() => dispatch(push('/i_home'))}>推し事登録</p>                               
                                            </Tooltip>
                                                <p className='home_button' style={{marginLeft:50}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</p>
                                        </div>
                                    ):(
                                        <>
                                          <IconButton onClick={(event) => handleDrawerToggle(event)}><MenuIcon/></IconButton>
                                          <Drawer open={open} onClose={handleDrawerToggle} variant="temporary" anchor="right" ModalProps={{keepMounted: true}}>
                                              <List>
                                                  <ListItem button style={{backgroundColor:"#f0f0f0"}}>
                                                    <ListItemText primary={"コレクター登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                                  <ListItem button onClick={() => dispatch(push('/i_home'))}>
                                                    <ListItemText primary={"推し事登録"}/>
                                                  </ListItem>
                                                  <div className='module-spacer--small'/>
                                              </List>
                                              <Button style={{color:"white", backgroundColor:"#808080", width:"80px", margin:"0 auto", borderRadius:"12px"}} onClick={() => dispatch(push('/influencer/signin'))}>ログイン</Button>
                                          </Drawer>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </ToolBar>
                </AppBar>
                <Divider variant="middle"/>
            </div>
            <div className='center' style={{maxWidth:"800px"}}>
                <h3 style={{textAlign:"left", marginLeft:"20px"}}>登録コレクター一覧</h3>
                <Grid style={{alignItems:"stretch", display:"flex", flexWrap:"wrap", justifyContent:"space-around"}}>
                    {influencerLists.length && (
                        influencerLists.map(influencerList => (
                            <div style={{margin:0}} key={influencerList.id}>
                                <InfluencerListCard id={influencerList.uid}/>
                            </div>

                        ))
                    )}
                </Grid>
            </div>
            <div className='module-spacer--small'/>
            <div className='center' style={{backgroundColor:"#fafafa"}}>
                <div className="module-spacer--small"/>
                
                <h3 style={{textAlign:"center"}}>お題一覧</h3>
                
                <Grid style={{alignItems:"stretch", display:"flex", flexWrap:"wrap", justifyContent:"space-around"}}>
                    {requestsLists.length && (
                        requestsLists.map(requestsLists => (
                            <div style={{margin:0}} key={requestsLists}>
                                <RequestAllList id={requestsLists.id}/>
                            </div>
                        ))
                    )}
                </Grid>
                <div className='module-spacer--medium'/>
            </div>
            <div className='module-spacer--small'/>
        </>
    )
}

export default AdornmentInfluencerList