import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider} from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { TwitterButton } from '../components/UIkit';
import TwitterIcon from '@material-ui/icons/Twitter'
import { useDispatch } from 'react-redux';
import header from '../assets/header.png'
import { push } from 'connected-react-router';
import logo from '../assets/logo.png'
import { twitterSignUp } from '../reducks/users/operations';
import {Button, Typography} from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

import {db, auth} from '../firebase'
import noImage from '../assets/noImage.png'
import {IconButton,ListItemText} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import {List, ListItem, Drawer} from '@material-ui/core'
import { useCallback } from 'react';
import { IllustHomeItem } from '../components/Home';
import { ClosableDrawer } from '../components/Header';
import mobileheader from '../assets/mobileheader.png'
import {Tooltip} from '@material-ui/core'
const IllustHome = () => {

    const [uid, setUid] = useState('') 
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user){
                setUid(user.uid)
                setSignedIn(true)
            }
        })
    })
    
    const [icons, setIcons] = useState()
    const [category, setCategory] = useState('')

    useEffect(() => {
        if(uid){
            db.collection('users').doc(uid).get().then(snapshot => {
                const data = snapshot.data()
                setCategory(data.category)
                if(data.icons === ""){
                    setIcons(noImage)
                }
                if(data.icons[0].path){
                    setIcons(data.icons[0].path)
                }
            })
        }
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        if(uid){
            const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
                const list = []
                snapshots.forEach(snapshot => {
                    list.push(snapshot.data())
                })
                setNotifis(list)
            })
            return () => unsubscribe()
        }
    },[uid])


    const dispatch = useDispatch()

    const normal = "normal"
    
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        setWidth(window.innerWidth)
    })
    
     
    const [open, setOpen] = useState(false)
 
    const handleDrawerToggle = useCallback((event) => {
      if (event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')){
              return;
          }
         setOpen(!open)
    }, [setOpen,open])

    return (
        <>
            <Helmet
            title="Pallet"
            />
            <div className="header_root">
                <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                    <ToolBar className="header_toolBar" style={{padding:0}}>
                        {/* <img src={logo} alt="logo" width="128px"/> */}
                        {signedIn === true ? (
                            <>
                                <ClosableDrawer uid={uid} category={category} icons={icons} notifis={notifis} />                            
                            </>
                        ):(
                            <>
                               <img src={logo} alt="logo" width="128px" style={{cursor:"pointer"}} onClick={() => dispatch(push('/i_home'))}/>
                                <div className="header_iconButton" >
                                    {width > 500 ? (
                                        <div style={{display:"flex"}}>
                                            <Tooltip title="お題の投稿、イラストの投稿・購入ができます。">
                                                <p className='home_button' style={{marginLeft:50}} onClick={() => dispatch(push('/'))}>コレクター登録</p>
                                            </Tooltip>
                                            <Tooltip title="イラストの投稿・購入ができます。">
                                                <p style={{fontWeight:"bold", fontSize:"14px", marginLeft:50}}>推し事登録</p>
                                            </Tooltip>
                                            <p className='home_button' style={{marginLeft:50}} onClick={() => dispatch(push('/signin'))}>ログイン</p>                                            
                                        </div>
                                    ):(
                                        <>
                                        <IconButton onClick={(event) => handleDrawerToggle(event)}><MenuIcon/></IconButton>
                                        <Drawer open={open} onClose={handleDrawerToggle} variant="temporary" anchor="right" ModalProps={{keepMounted: true}}>
                                            <List>
                                                <ListItem button onClick={() => dispatch(push('/'))}>
                                                  <ListItemText primary={"コレクター登録"}/>
                                                </ListItem>
                                                <div className='module-spacer--small'/>
                                                <ListItem button style={{backgroundColor:"#f0f0f0"}}>
                                                  <ListItemText primary={"推し事登録"}/>
                                                </ListItem>
                                                <div className='module-spacer--small'/>
                                            </List>
                                            <Button style={{color:"white", backgroundColor:"#808080", width:"80px", margin:"0 auto", borderRadius:"12px"}} onClick={() => dispatch(push('/signin'))}>ログイン</Button>
                                        </Drawer>
                                      </>
                                    )}
                                </div>
                            </>
                        )}
                    </ToolBar>
                </AppBar>
                <Divider />
            </div>
        <div className='center' style={{maxWidth:"1000px"}}>
            {width > 500 ? (
                <div style={{position:"relative"}}>
                    <img src={header} alt="header" style={{width:"100%", height:"auto"}}/>
                        <Button onClick={() => dispatch(twitterSignUp(normal))} className="home_header" style={{textTransform:"none", position:"absolute", backgroundColor:"#1da1f2", color:"#fff", padding:0, borderRadius:"12px"}}><TwitterIcon className="bird_icon_size"/><span className="home_header_button">Twitterで始める</span></Button>
                        <p className="home_header_email" style={{position:"absolute", padding:0}}>
                            使い方については<a href="https://narrow-acoustic-d74.notion.site/Pallet-0da90dff55804d919a242531e9952b7e" target="_blank">こちら</a>
                        </p>  
                </div>
            ):(
                <div style={{position:"relative"}}>
                    <img src={mobileheader} alt="header" style={{width:"100%", height:"auto"}}/>
                        <Button onClick={() => dispatch(twitterSignUp(normal))} className="home_header" style={{textTransform:"none", position:"absolute", backgroundColor:"#1da1f2", color:"#fff", padding:0, borderRadius:"12px"}}><TwitterIcon className="bird_icon_size"/><span className="home_header_button">Twitterで始める</span></Button>
                        <p className="home_header_email" style={{position:"absolute", cursor:"pointer", padding:0}}>
                            使い方については
                            <a target="_blank" href="https://narrow-acoustic-d74.notion.site/Pallet-0da90dff55804d919a242531e9952b7e">
                                こちら
                            </a>
                        </p>  
                </div>
            )}   
            <IllustHomeItem page="/"/>
            <div style={{backgroundColor:"#fafafa"}}>
                <div className='module-spacer--medium'/>

                <h2>Palletに登録して<br/>お題に回答しよう!</h2>

                <TwitterButton
                label="Twitterで始める"
                onClick={() => dispatch(twitterSignUp(normal))}
                />
                <br/>
                {/* <Button onClick={() => dispatch(push('/login'))}>
                    <Typography style={{fontSize:"12px", color:"#808080", textDecoration:"underline"}}>メールアドレスで登録する</Typography>
                </Button> */}
                <p style={{fontSize:"12px", color:"#808080"}}>
                    登録することで、<a href="/rules" target="_blank" rel="noreferrer noopener">利用規約</a>と<a href="/policy" target="_blank" rel="noreferrer noopener">プライバシーポリシー</a>に<br/>同意したものとみなされます。
                </p>
                <p style={{fontSize:"12px", marginTop:20}}>使い方については<a href="https://narrow-acoustic-d74.notion.site/Pallet-0da90dff55804d919a242531e9952b7e" target="_blank">こちら</a></p>           
                <div className="module-spacer--small"/>
            </div>
          </div>        
      </>
    )
}

export default IllustHome