import React from 'react'
import {Button} from '@material-ui/core'
import around from '../../assets/1.png'
import arts from '../../assets/2.png'
import set from '../../assets/3.png'
import money from '../../assets/4.png'
import {Grid} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {push} from 'connected-react-router'
const HomeItem = (props) => {
    const page = props.page
    const dispatch = useDispatch()
    return (
        <>
            <div style={{backgroundColor:"#FFFFEF", marginTop:0}}>
                <h3 style={{textAlign:"left", marginTop:"0px", marginLeft:"20px", paddingTop:"30px", fontSize:"22px"}}>Vtuber様向け『コレクター』登録でできること</h3>
                    <Grid container style={{margin:"auto", marginBottom:"20px"}}>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={around} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0 "}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>お題の投稿</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>お題を投稿して作品を集めましょう。<br/>投稿したらSNSにもシェアできます。<br/>お題の内容は自由に決めることができます。<br/>(お題投稿者は作品を自由にダウンロードできます。)</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={arts} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0"}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>著作権利用料の受け取り</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>お題に集まった作品は、誰でも購入できます。<br/>購入が発生すると、お題投稿者に著作権利用料として販売価格の15%が入ります。<br/>(サービス手数料10%、残りの75%が作品投稿者に入ります。)</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container style={{margin:"auto"}}>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={set} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0"}}>
                                <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>イラストの投稿</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>他のコレクターのお題に対してイラストを投稿することができます。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="home_grid_third_component">
                                <img src={money} alt="p" className="home_grid_third_img"/>
                                <div style={{margin:"0 auto 0 0"}}>
                                    <p className='home_grid_second_title' style={{fontWeight:"bold", textAlign:"left"}}>イラストの購入</p>
                                    <p className='home_grid_second_desc' style={{textAlign:"left"}}>他のコレクターのお題に投稿された作品を購入することができます。</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div className='module-spacer--small'/>
                    <div className='home_change'>
                        <p style={{width:"50%"}}></p>
                        <p className="home_change_desc">推し事登録は<span onClick={() => dispatch(push(page))} style={{fontWeight:"bold", cursor:"pointer", textDecoration:"underline"}}>こちら</span></p>
                    </div>             
                <div className='module-spacer--medium'/>
            </div>
            <div style={{backgroundColor:"#fafafa", marginTop:0}}>
                <h3 style={{textAlign:"left", marginTop:"0px", marginLeft:"20px", paddingTop:"30px", fontSize:"22px"}}>お題を投稿する際の留意点</h3>
                <Grid container style={{margin:"auto"}}>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <p style={{fontWeight:"bold", textDecoration:"underline", fontSize:"18px", marginBottom:"5px"}}>01</p>                            
                            <p style={{fontSize:"16px", fontWeight:"bold"}}>ご自身以外が<br/>関連するお題</p>
                            <p style={{fontSize:"14px"}}>他の版権が関わる投稿は削除<br/>することがあります。<br/>例) ゲーム配信に関連したお題</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <p style={{fontWeight:"bold", textDecoration:"underline", fontSize:"18px", marginBottom:"5px"}}>02</p>                            
                            <p style={{fontSize:"16px", fontWeight:"bold"}}>過度な性的・<br/>グロテスクなお題</p>
                            <p style={{fontSize:"14px"}}>放送禁止用語等、またはそれに<br/>近しい表現を用いたお題は、<br/>削除することがあります。</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <p style={{fontWeight:"bold", textDecoration:"underline", fontSize:"18px", marginBottom:"5px"}}>03</p>                            
                            <p style={{fontSize:"16px", fontWeight:"bold"}}>宗教的・差別的な<br/>内容のお題</p>
                            <p style={{fontSize:"14px"}}>トラブル回避のため、上記に<br/>当てはまる投稿は削除するこ<br/>とがあります。</p>
                        </div>
                    </Grid>
                </Grid>
                <div className='module-spacer--medium'/>
            </div>
        </>
    )
}

export default HomeItem