import React, {useState, useEffect} from 'react'
import ToolBar from '@material-ui/core/ToolBar'
import { AppBar, Divider} from '@material-ui/core'
import {getUserId } from '../reducks/users/selectors'
import noImage from '../assets/noImage.png'
import {useSelector } from 'react-redux'
import { db } from '../firebase'
import { Notificard } from '../components/Notifications'
import { Helmet } from 'react-helmet-async'
import { ClosableDrawer } from '../components/Header';

const Notifications = () => {
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)

    const [icons, setIcons] = useState()
    const [category, setCategory] = useState("")

    useEffect(() => {
        db.collection('users').doc(uid).get().then(snapshot => {
            const data = snapshot.data()
            setCategory(data.category)
            if(data.icons === ""){
                setIcons(noImage)
            }
            if(data.icons[0].path){
                setIcons(data.icons[0].path)
            }
        })
    },[uid])

    const [notifis, setNotifis] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').where('look', "==", false).onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
            })
            setNotifis(list)
        })
        return () => unsubscribe()
    },[uid])
    
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        const unsubscribe = db.collection('users').doc(uid).collection('notifications').orderBy('created_at', "desc").onSnapshot((snapshots) => {
            const list = []
            snapshots.forEach(snapshot => {
                list.push(snapshot.data())
                
            })
            setNotifications(list)
        })
        return () => unsubscribe()
    },[uid])
    
    return (
        <>
        <Helmet
          title="通知/Pallet"
        />
          <div className="header_root">
            <AppBar position="relative" className="header_menuBar" style={{boxShadow:"none", backgroundColor:"#fff"}}>
                <ToolBar className="header_toolBar" style={{padding:0}}>
                    <ClosableDrawer icons={icons} category={category} uid={uid} notifis={notifis} />  
                </ToolBar>
            </AppBar>
            <Divider variant="middle"/>
            <div className="module-spacer--medium"/>
            <div className='center'>
             <h2>通知</h2>
               {notifications.length > 0 ? (
                 notifications.map(notifi => (
                     <Notificard key={notifi.id} id={notifi.id} uid={uid} time={notifi.created_at} artId={notifi.artId} look={notifi.look}/>
                 ))
              ):(
                <div className="center">※まだ通知はありません</div>                         
              )}
            </div>
        </div>
        <div className="module-spacer--medium"/>
        </>
    )
}

export default Notifications