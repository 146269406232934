import React from 'react'
import { Avatar } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    size:{
        height:100,
        width:100,
        margin:"auto"
    }
})

const IconPreview = (props) => {
    const classes = useStyles()

    return(
        <div className="u-text-center">
            <Avatar alt="アイコン画像" src={props.path} className={classes.size} key={props.index} onClick={() => props.onClick()}/>
        </div>
    )
}

export default IconPreview