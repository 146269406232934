import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    button:{
        backgroundColor:"#808080",
        color:"white",
        fontSize:16,
        height:48,
        marginButtom:16,
        marginTop:16,
        width:256,
        borderRadius:"12px"
    }
})

const GrayButton = (props) => {
    const classes = useStyles()

    return(
        <Button className={classes.button} onClick={() => props.onClick()} variant='contained' style={{textTransform:"none"}}>
            {props.label}
        </Button>
    )
}

export default GrayButton