import React from 'react'
import { OutlinedInput, InputAdornment } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
const useStyles = makeStyles({
  field:{
    backgroundColor:"#f0f0f0",
    marginBottom:"16px"
  }
})
const AdornmentTextInput = (props) => {
    const classes = useStyles()
  return (
    <OutlinedInput
      // className={classes.text}
      className={classes.field}
      fullWidth={props.fullWidth}
      value={props.value}
      type={props.type}
      onChange={props.onChange}
      maxRows={props.maxRows}
      minRows={props.minRows}
      margin="dense"
      size={props.size}
      endAdornment={
        <InputAdornment position="end">{props.adornment}</InputAdornment>
      }
      inputProps={{
        'aria-label': props.adornment
      }}
    />
  )
}

export default AdornmentTextInput